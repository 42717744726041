import React, { FunctionComponent, Fragment } from 'react';
import styled from 'styled-components';

type TableAttributesProps = {
	label: string;
	name: string;
	value: string | null;
	onChange: (value: string | null) => void;
	showForm: boolean;
	disabled?: boolean;
};

const Attribute = styled.div`
	display: flex;
	align-items: center;
	padding-left: 20px;
`;

const Label = styled.span`
	flex-basis: 241px;
	flex-shrink: 0;
`;

const MinMaxWrapper = styled.div`
	width: 90px;
`;

const Select = styled.select`
	width: 95px;
	height: 30px;
	margin-right: 8px;
	padding-left: 30px;
`;

const ClearButton = styled.button`
	padding: 0px 3px 0px 3px !important;
	font-size: 20px !important;
`;

const Button = styled.button`
	border: none;
`;

const TableAttribute: FunctionComponent<TableAttributesProps> = (props) => {
	const { label, value, onChange, showForm, disabled } = props;

	const setValue = (newValue: string) => {
		onChange(newValue);
	};

	const handleClear = () => {
		onChange(null);
	};

	const form = (
		<Fragment>
			<ClearButton
				className="btn btn-link clear-button"
				onClick={() => {
					handleClear();
				}}
				disabled={!value?.length}
			>
				<i className="fas fa-times-circle"></i>
			</ClearButton>
			<Select
				value={value || ''}
				onChange={(e) => setValue(e.target.value)}
				disabled={disabled}
			>
				<option value=""></option>
				<option value="6,4">6,4</option>
				<option value="8,5">8,5</option>
				<option value="8,6">8,6</option>
				<option value="10,7">10,7</option>
				<option value="10,8">10,8</option>
				<option value="12,9">12,9</option>
				<option value="12,10">12,10</option>
				<option value="14,11">14,11</option>
				<option value="14,12">14,12</option>
				<option value="16,13">16,13</option>
				<option value="16,14">16,14</option>
				<option value="18,15">18,15</option>
				<option value="18,16">18,16</option>
				<option value="20,16">20,16</option>
				<option value="20,17">20,17</option>
				<option value="20,18">20,18</option>
			</Select>
			<MinMaxWrapper
				className={`btn-group min-max-group disabled`}
				role="group"
				aria-label="Table attribute"
			>
				<Button disabled={!value} onClick={() => {}} className={`btn`}>
					min
				</Button>
				<Button disabled={!value} onClick={() => {}} className={`btn`}>
					auto
				</Button>
				<Button disabled={!value} onClick={() => {}} className={`btn`}>
					max
				</Button>
			</MinMaxWrapper>
		</Fragment>
	);

	return (
		<Attribute className="table-attribute">
			<Label className="table-attribute-label">
				{label}
				<i
					style={{ display: 'none' }}
					className="pl-2 hidden icon fas fa-magic"
				/>
			</Label>
			{showForm && form}
		</Attribute>
	);
};

export default TableAttribute;
