import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

type RowProps = {
	label: string;
	uid?: string;
	onClick: (value: string) => void;
	selected: boolean;
	existing?: boolean;
	unselectModel?: (modelUid: string) => void;
};

const ClickableRowElement = styled.div`
	position: sticky;
	top: 0px;
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Label = styled.span``;

const CloseIcon = styled.i`
	font-size: 13px;
	margin-left: 5px;
	margin-right: 5px;
	cursor: pointer;
`;

const TableFirstRow: FunctionComponent<RowProps> = (props) => {
	const labelElement = (
		<Label className="table-first-row-label">
			{props.existing && 'w/'}
			{props.label}
		</Label>
	);

	const handleClick = (e: React.MouseEvent) => {
		if (props.onClick && props.uid) {
			props.onClick(props.uid);
		}
	};

	const handleCloseClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.stopPropagation();
		if (props.uid) {
			if (!props.selected && props.unselectModel)
				props.unselectModel(props.uid);
			else props.onClick(props.uid);
		}
	};

	return (
		<ClickableRowElement onClick={handleClick} className="table-first-row">
			{props.label && labelElement}
			<CloseIcon className="fas fa-times-circle" onClick={handleCloseClick} />
		</ClickableRowElement>
	);
};

export default TableFirstRow;
