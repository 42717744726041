import { createSlice } from '@reduxjs/toolkit';
import { fetchData } from '../../api';
import { resIsValid } from '../../api/data';
import { setLastRequest } from './view';

// Types
import {
	DataSliceState,
	DataAPIResponse,
	Cluster,
	AppDispatch,
	RootState,
	FormattedCluster,
	GlobalSliceState,
} from '../../types';

// Selectors & Actions
import { filtersSelector } from './filters';
import { globalSelector } from '../global';
import { viewSelector, toggleInSelection } from './view';

// Hooks and utils
import {
	cmToIn,
	inToCm,
	kgToLbs,
	lbsToKg,
	MBtoMiB,
	MiBtoMB,
	TBtoTiB,
	TiBtoTB,
} from '../../utils/conversions';

export const initialState: DataSliceState = {
	loading: false,
	errors: false,
	clusters: [],
};

const dataSlice = createSlice({
	name: 'data',
	initialState,
	reducers: {
		getData: (state) => {
			state.loading = true;
		},
		getDataSuccess: (state, { payload }: { payload: Cluster[] }) => {
			state.clusters = payload;
			state.loading = false;
			state.errors = false;
		},
		getDataFailure: (state) => {
			state.loading = false;
			state.errors = true;
		},
	},
});

export const { getData, getDataSuccess, getDataFailure } = dataSlice.actions;
export const dataSelector = (state: RootState) => state.calculator.data;
export const formattedDataSelector = (state: RootState) => {
	const res: FormattedCluster[] = [];

	state?.calculator?.data?.clusters?.forEach((element) => {
		res.push(formatCluster(state.global, element));
	});

	return res;
};
export default dataSlice.reducer;

export function fetchClusters() {
	return async (dispatch: AppDispatch, getState: () => RootState) => {
		const filters = filtersSelector(getState());
		const global = globalSelector(getState());
		const view = viewSelector(getState());
		const newAttributes = Object.assign({}, filters.attributes);

		if (global.dataUnits === 'base2') {
			if (newAttributes.usableCapacity.value)
				newAttributes.usableCapacity = Object.assign(
					{},
					newAttributes.usableCapacity,
					{ value: TiBtoTB(newAttributes.usableCapacity.value) },
				);
			if (newAttributes.capacity.value)
				newAttributes.capacity = Object.assign({}, newAttributes.capacity, {
					value: TiBtoTB(newAttributes.capacity.value),
				});
			if (newAttributes.capacityScalesTo.value)
				newAttributes.capacityScalesTo = Object.assign(
					{},
					newAttributes.capacityScalesTo,
					{ value: TiBtoTB(newAttributes.capacityScalesTo.value) },
				);
			if (newAttributes.burstWrite.value)
				newAttributes.burstWrite = Object.assign({}, newAttributes.burstWrite, {
					value: MiBtoMB(newAttributes.burstWrite.value),
				});
			if (newAttributes.cachedRead.value)
				newAttributes.cachedRead = Object.assign({}, newAttributes.cachedRead, {
					value: MiBtoMB(newAttributes.cachedRead.value),
				});
			if (newAttributes.sustainedWrite.value)
				newAttributes.sustainedWrite = Object.assign(
					{},
					newAttributes.sustainedWrite,
					{ value: MiBtoMB(newAttributes.sustainedWrite.value) },
				);
			if (newAttributes.singleStreamWrite.value)
				newAttributes.singleStreamWrite = Object.assign(
					{},
					newAttributes.singleStreamWrite,
					{ value: MiBtoMB(newAttributes.singleStreamWrite.value) },
				);
			if (newAttributes.uncachedRead.value)
				newAttributes.uncachedRead = Object.assign(
					{},
					newAttributes.uncachedRead,
					{ value: MiBtoMB(newAttributes.uncachedRead.value) },
				);
			if (newAttributes.singleStreamCachedRead.value)
				newAttributes.singleStreamCachedRead = Object.assign(
					{},
					newAttributes.singleStreamCachedRead,
					{ value: MiBtoMB(newAttributes.singleStreamCachedRead.value) },
				);
			if (newAttributes.singleStreamUncachedRead.value)
				newAttributes.singleStreamUncachedRead = Object.assign(
					{},
					newAttributes.singleStreamUncachedRead,
					{ value: MiBtoMB(newAttributes.singleStreamUncachedRead.value) },
				);
			if (newAttributes.writeVolume.value)
				newAttributes.writeVolume = Object.assign(
					{},
					newAttributes.writeVolume,
					{ value: TiBtoTB(newAttributes.writeVolume.value) },
				);
		}
		if (global.measurements === 'metric') {
			if (newAttributes.weight.value)
				newAttributes.weight = Object.assign({}, newAttributes.weight, {
					value: kgToLbs(newAttributes.weight.value),
				});
			if (newAttributes.height.value)
				newAttributes.height = Object.assign({}, newAttributes.height, {
					value: cmToIn(newAttributes.height.value),
				});
			if (newAttributes.width.value)
				newAttributes.width = Object.assign({}, newAttributes.width, {
					value: cmToIn(newAttributes.width.value),
				});
			if (newAttributes.depth.value)
				newAttributes.depth = Object.assign({}, newAttributes.depth, {
					value: cmToIn(newAttributes.depth.value),
				});
		}

		dispatch(getData());

		try {
			const data: DataAPIResponse = await fetchData(newAttributes);
			if (resIsValid(data)) {
				dispatch(setLastRequest(Date.now()));
				dispatch(getDataSuccess(data.clusters));

				if (
					view.selection.length &&
					!filters.models.find((item) => view.selection[0] === item.modelUid)
				)
					dispatch(toggleInSelection(view.selection[0]));

				const selectionOptionCluster: Cluster | undefined = data.clusters.find(
					(item) =>
						!!filters.models.find((it) => it.modelUid === item.modelUid),
				);
				if (
					!view.selection.length &&
					data.clusters.length &&
					selectionOptionCluster
				) {
					dispatch(toggleInSelection(selectionOptionCluster.modelUid));
				}
			} else {
				throw new Error('Data Fetch API Response not valid');
			}
		} catch (error) {
			dispatch(getDataFailure());
		}
	};
}

export function formatCluster(state: GlobalSliceState, cluster: Cluster) {
	const dataUnits = state.dataUnits;
	const measurements = state.measurements;
	const formattedCluster: FormattedCluster = {
		modelUid: cluster.modelUid,
		modelName: cluster.modelName,
		modelNameFull: cluster.modelNameFull,
		modelNameShort: cluster.modelNameShort,
		usableCapacity: {
			unit: dataUnits === 'base10' ? 'TB' : 'TiB',
			value:
				dataUnits === 'base10'
					? cluster.usableCapacity
					: TBtoTiB(cluster.usableCapacity),
		},
		encoding: {
			unit: '',
			value: [cluster.stripeWidth, cluster.dataElementsPerStripe],
		},
		capacity: {
			unit: dataUnits === 'base10' ? 'TB' : 'TiB',
			value:
				dataUnits === 'base10' ? cluster.capacity : TBtoTiB(cluster.capacity),
		},
		capacityScalesTo: {
			unit: dataUnits === 'base10' ? 'TB' : 'TiB',
			value:
				dataUnits === 'base10'
					? cluster.capacityScalesTo
					: TBtoTiB(cluster.capacityScalesTo),
		},
		efficiency: {
			unit: '%',
			value: cluster.efficiency * 100,
		},
		nodeCount: {
			unit: 'nodes',
			value: cluster.nodeCount,
		},
		nodeCountScalesTo: {
			unit: 'nodes',
			value: cluster.nodeCountScalesTo,
		},
		performanceClass: {
			unit: '',
			value: cluster.performanceClass,
		},
		burstWrite: {
			unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
			value:
				dataUnits === 'base10'
					? cluster.burstWrite
					: MBtoMiB(cluster.burstWrite),
		},
		cachedRead: {
			unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
			value:
				dataUnits === 'base10'
					? cluster.cachedRead
					: MBtoMiB(cluster.cachedRead),
		},
		iops: {
			unit: '',
			value: cluster.iops,
		},
		sustainedWrite: {
			unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
			value:
				dataUnits === 'base10'
					? cluster.sustainedWrite
					: MBtoMiB(cluster.sustainedWrite),
		},
		singleStreamWrite: {
			unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
			value:
				dataUnits === 'base10'
					? cluster.singleStreamWrite
					: MBtoMiB(cluster.singleStreamWrite),
		},
		uncachedRead: {
			unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
			value:
				dataUnits === 'base10'
					? cluster.uncachedRead
					: MBtoMiB(cluster.uncachedRead),
		},
		singleStreamCachedRead: {
			unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
			value:
				dataUnits === 'base10'
					? cluster.singleStreamCachedRead
					: MBtoMiB(cluster.singleStreamCachedRead),
		},
		singleStreamUncachedRead: {
			unit: dataUnits === 'base10' ? 'MB/s' : 'MiB/s',
			value:
				dataUnits === 'base10'
					? cluster.singleStreamUncachedRead
					: MBtoMiB(cluster.singleStreamUncachedRead),
		},
		driveOutageTolerance: {
			unit: '',
			value: cluster.driveOutageTolerance,
		},
		nodeOutageTolerance: {
			unit: '',
			value: cluster.nodeOutageTolerance,
		},
		frontEndPorts: {
			unit: 'ports',
			value: cluster.frontEndPorts,
		},
		backEndPorts: {
			unit: 'ports',
			value: cluster.backEndPorts,
		},
		frontEndNetworking: {
			unit: 'GbE',
			value: cluster.frontEndNetworking,
		},
		backEndNetworking: {
			unit: 'GbE',
			value: cluster.backEndNetworking,
		},
		writeVolume: {
			unit: dataUnits === 'base10' ? 'TB/day' : 'TiB/day',
			value:
				dataUnits === 'base10'
					? cluster.writeVolume
					: TBtoTiB(cluster.writeVolume),
		},
		clusterOverwriteCadence: {
			unit: 'days',
			value: cluster.clusterOverwriteCadence,
		},
		rackSpaceRequired: {
			unit: 'U',
			value: cluster.rackSpaceRequired,
		},
		height: {
			unit: measurements === 'standard' ? 'in' : 'cm',
			value:
				measurements === 'standard'
					? cluster.height
					: inToCm(cluster.height).toFixed(1),
		},
		width: {
			unit: measurements === 'standard' ? 'in' : 'cm',
			value:
				measurements === 'standard'
					? cluster.width
					: inToCm(cluster.width).toFixed(1),
		},
		depth: {
			unit: measurements === 'standard' ? 'in' : 'cm',
			value:
				measurements === 'standard'
					? cluster.depth
					: inToCm(cluster.depth).toFixed(1),
		},
		weight: {
			unit: measurements === 'standard' ? 'lbs' : 'kg',
			value:
				measurements === 'standard'
					? cluster.weight
					: lbsToKg(cluster.weight).toFixed(1),
		},
		typicalWatts: {
			unit: 'W',
			value: cluster.typicalWatts,
		},
		typicalAmps110V: {
			unit: 'A',
			value: cluster.typicalAmps110V,
		},
		typicalAmps240V: {
			unit: 'A',
			value: cluster.typicalAmps240V,
		},
		typicalThermalBTU: {
			unit: 'BTU/hr',
			value: cluster.typicalThermalBTU,
		},
		stripeWidth: {
			unit: '',
			value: cluster.stripeWidth,
		},
		dataElementsPerStripe: {
			unit: '',
			value: cluster.dataElementsPerStripe,
		},
	};

	return formattedCluster;
}
