import React, { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';
import TableWarning from './TableWarning';
import { formatNumber } from '../utils/conversions';

type TableCellProps = {
	data: number | string;
	unitLabel?: string;
	decimals?: number;
	existing?: boolean;
	warning?: string;
};

const Cell = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Value = styled.span``;

const Unit = styled.span``;

const TableCell: FunctionComponent<TableCellProps> = (props) => {
	const { data, unitLabel, decimals, existing, warning } = props;

	const memoCell = useMemo(() => {
		return (
			<Cell className={`table-cell ${existing ? 'existing-cell' : ''}`}>
				<div>
					<Value>
						{typeof data === 'number'
							? formatNumber(data, decimals || 0)
							: data}
					</Value>
					&nbsp;
					<Unit>{unitLabel}</Unit>
					{warning && <TableWarning text={warning} variant={'secondary'} />}
				</div>
			</Cell>
		);
	}, [data, unitLabel, decimals, existing, warning]);

	return memoCell;
};

export default TableCell;
