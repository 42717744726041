import React, { useState } from 'react';
import styled from 'styled-components';
import GlobalSettingsModal from '../components/GlobalSettingsModal';

import { useAuth0 } from '../react-auth0-spa';

// Logo
import logo from '../assets/qumulo_logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { viewSelector, hideChartPage } from '../slices/calculator/view';

const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const MainBlockWrapper = styled.div`
	margin: 0px 20px;
	display: flex;
	align-items: center;
	flex-grow: 1;
	min-height: 60px;
`;

const Title = styled.h5`
	margin: 0px;
	font-size: 24px;
	font-weight: 500;
`;

const InnerWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;

	${Title}:first-child {
		display: flex;
		width: 100%;
		justify-content: center;
	}

	div:last-child {
		white-space: nowrap;
	}
`;

const Logo = styled.img`
	margin-right: 20px;
`;

const GlobalSettingButton = styled.button`
	margin-left: 10px;
`;

const PageHeader = (props: { hideLogButton: boolean }) => {
	const dispatch = useDispatch();
	const { logout } = useAuth0();
	const { isInChartPage } = useSelector(viewSelector);
	const [showGlobalSettingsModal, setShowGlobalSettingsModal] = useState(false);

	return (
		<HeaderWrapper id="page-header" className="page-header">
			<GlobalSettingsModal
				show={showGlobalSettingsModal}
				handleClose={() => setShowGlobalSettingsModal(false)}
			/>
			<MainBlockWrapper>
				{isInChartPage ? (
					<button
						onClick={() => dispatch(hideChartPage())}
						className="calculator-link btn btn-link"
					>
						<i className="fas fa-chevron-left" />
						<div>Back</div>
					</button>
				) : (
					<Logo src={logo} alt="Qumulo Logo" />
				)}
				<InnerWrapper>
					<Title>
						{isInChartPage
							? 'Storage Solution Review'
							: 'On-Prem Solution Sizer'}
					</Title>
					<div>
						{!props.hideLogButton && (
							<>
								<button
									className="btn btn-link"
									onClick={() =>
										logout({
											returnTo: window.location.origin,
											client_id: process.env.REACT_APP_AUTH0_CLIENTID,
										})
									}
									style={{ textAlign: 'right', paddingRight: '0px' }}
								>
									Log out
								</button>
								<GlobalSettingButton
									className="btn btn-link"
									onClick={() => setShowGlobalSettingsModal(true)}
								>
									<i className="fas fa-ellipsis-v"></i>
								</GlobalSettingButton>
							</>
						)}
					</div>
				</InnerWrapper>
			</MainBlockWrapper>
		</HeaderWrapper>
	);
};

export default PageHeader;
