import { createSlice } from '@reduxjs/toolkit';

// Types
import { CalculatorViewSliceState, RootState } from '../../types';

export const initialState: CalculatorViewSliceState = {
	mode: 'new',
	tableMode: 'all',
	showCategory: {
		capacity: true,
		nodes: true,
		cost: false,
		performance: true,
		networking: true,
		endurance: true,
		dataProtection: true,
		environmentals: true,
	},
	isInAnimation: false,
	selection: [],
	showFilters: false,
	showAttributes: true,
	lastRequest: null,
	showExistingModal: false,
	isInChartPage: false,
};

const viewSlice = createSlice({
	name: 'view',
	initialState,
	reducers: {
		setMode: (state, { payload }: { payload: string }) => {
			state.mode = payload;
			state.selection = [];
			return state;
		},
		setLastRequest: (state, { payload }: { payload: number }) => {
			state.lastRequest = payload;
			return state;
		},
		setTableMode: (state, { payload }: { payload: string }) => {
			state.tableMode = payload;
			return state;
		},
		toggleCategory: (state, { payload }: { payload: string }) => {
			state.showCategory[payload] = !state.showCategory[payload];
			return state;
		},
		setCategoryFromURL: (state, { payload }: { payload: number[] }) => {
			let i = 0;
			for (let [key] of Object.entries(state.showCategory)) {
				state.showCategory[key] = payload[i] ? false : true;
				i++;
			}
		},
		startAnimation: (state) => {
			state.isInAnimation = true;
			return state;
		},
		endAnimation: (state) => {
			state.isInAnimation = false;
			return state;
		},
		toggleInSelection: (state, { payload }: { payload: string }) => {
			if (state.selection.includes(payload))
				state.selection.splice(state.selection.indexOf(payload), 1);
			else state.selection = [payload];
			return state;
		},
		toggleFilters: (state) => {
			state.showFilters = !state.showFilters;
		},
		toggleAttributes: (state) => {
			state.showAttributes = !state.showAttributes;
		},
		resetView: () => {
			return initialState;
		},
		setShowExistingModal: (state, { payload }: { payload: boolean }) => {
			state.showExistingModal = payload;
			return state;
		},
		showChartPage: (state) => {
			state.isInChartPage = true;
			return state;
		},
		hideChartPage: (state) => {
			state.isInChartPage = false;
			return state;
		},
	},
});

export const {
	setMode,
	setTableMode,
	toggleCategory,
	startAnimation,
	endAnimation,
	toggleInSelection,
	toggleFilters,
	toggleAttributes,
	setCategoryFromURL,
	resetView,
	setLastRequest,
	setShowExistingModal,
	showChartPage,
	hideChartPage,
} = viewSlice.actions;
export const viewSelector = (state: RootState): CalculatorViewSliceState =>
	state.calculator.view;
export default viewSlice.reducer;
