import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

// Components
import {
	TableFirstRow,
	AttributesBlock,
	TableCell,
	ConditionalTableItem,
} from '../../components';

// Slices
import { viewSelector, toggleInSelection } from '../../slices/calculator/view';
import {
	filtersSelector,
	unselectModel,
} from '../../slices/calculator/filters';
import { globalSelector } from '../../slices/global';
import { dataExistingSelector } from '../../slices/calculator/dataExisting';

// Types
import { Cluster, FiltersModel } from '../../types';

// Utils
import { lbsToKg, inToCm, TBtoTiB, MBtoMiB } from '../../utils/conversions';
import strings from '../../utils/strings.json';
import { useURLFilters } from '../../hooks';

type ColumnProps = {
	cluster: Cluster;
};

const Column = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	scroll-snap-align: start;
	width: 180px;
`;

const TableColumn: FunctionComponent<ColumnProps> = (props) => {
	const dispatch = useDispatch();
	const [columnHeight, setColumnHeight] = useState<number | null | undefined>(
		null,
	);
	const columnRef = useRef<HTMLDivElement>(null);
	const viewSlice = useSelector(viewSelector);
	const filters = useSelector(filtersSelector);
	const attributes = filters.attributes;
	const { encodeSelectionState, pushHistory } = useURLFilters();
	const dataExisting = useSelector(dataExistingSelector);
	const existing = dataExisting?.existing;
	const { measurements, dataUnits } = useSelector(globalSelector);
	const { tableMode, showCategory, isInAnimation, selection } = viewSlice;
	const summary = tableMode === 'summary';
	const {
		modelUid,
		modelName,
		capacity,
		usableCapacity,
		efficiency,
		nodeCount,
		// nodeCountScalesTo,
		performanceClass,
		burstWrite,
		cachedRead,
		capacityScalesTo,
		iops,
		sustainedWrite,
		singleStreamWrite,
		uncachedRead,
		singleStreamCachedRead,
		singleStreamUncachedRead,
		frontEndPorts,
		frontEndNetworking,
		backEndPorts,
		backEndNetworking,
		writeVolume,
		clusterOverwriteCadence,
		nodeOutageTolerance,
		driveOutageTolerance,
		stripeWidth,
		dataElementsPerStripe,
		rackSpaceRequired,
		height,
		width,
		depth,
		weight,
		typicalWatts,
		typicalAmps110V,
		typicalAmps240V,
		typicalThermalBTU,
		usableCapacityAdded,
		nodeAdded,
		frontEndPortsAdded,
		backEndPortsAdded,
		rackSpaceRequiredAdded,
		weightAdded,
		typicalWattsAdded,
		frontEndNetworkingAdded,
		backEndNetworkingAdded,
	} = props.cluster;

	/*
	 ** For the sticky column header and the left sticky filters to work we need to set fixed dimension for the table
	 ** This method will calculate the right dimensions for the column
	 */
	const getColumnHeightFromDOM = (): number | null => {
		if (columnRef.current) {
			// Using the Filter columns as the reference element
			const filtersColumnElement = columnRef.current;

			if (filtersColumnElement && filtersColumnElement.children.length) {
				let res = 0;
				Array.from(filtersColumnElement.children).forEach((item) => {
					if (item instanceof HTMLElement) {
						res += item.offsetHeight;
					}
				});
				return res;
			}
		}
		return null;
	};

	// Re-setting height on Summary / All switch and on Category fold
	useEffect(() => {
		if (!isInAnimation) {
			const calculatedHeight = getColumnHeightFromDOM();
			if (calculatedHeight) setColumnHeight(calculatedHeight);
		}
	}, [summary, showCategory, isInAnimation, viewSlice.mode]);

	const handleDeleteClick = (modelUid: string) => {
		dispatch(unselectModel(modelUid));
		// Pushing Unselected items in URL to save state
		let cloned: FiltersModel[] = JSON.parse(JSON.stringify(filters.models));
		cloned = cloned.map((item) => {
			if (item.modelUid === modelUid) item.selected = !item.selected;
			return item;
		});
		cloned = cloned.filter((item) => !item.selected);
		const uncheckedLabels: string[] = cloned.map((item) => item.modelUid);
		pushHistory(encodeSelectionState(uncheckedLabels), 'selection');
	};

	/*
	 ** For the sticky column header to work we need to set a fixed height on the column
	 ** We base this height on the filter column height
	 */
	return (
		<Column
			ref={columnRef}
			style={{
				height:
					columnHeight ||
					document.getElementById('filters-column')?.scrollHeight,
			}}
			className={`table-column ${
				viewSlice.selection.includes(modelUid) ? 'selected' : ''
			}`}
		>
			<TableFirstRow
				label={modelName}
				uid={modelUid}
				onClick={(value: string) => {
					dispatch(toggleInSelection(value));
				}}
				selected={selection.includes(modelUid)}
				existing={viewSlice.mode === 'existing'}
				unselectModel={handleDeleteClick}
			/>
			<AttributesBlock category="capacity">
				<TableCell
					data={
						dataUnits === 'base10' ? usableCapacity : TBtoTiB(usableCapacity)
					}
					unitLabel={dataUnits === 'base10' ? 'TB' : 'TiB'}
					decimals={1}
				/>
				{viewSlice.mode === 'existing' && (
					<TableCell
						data={
							dataUnits === 'base10'
								? usableCapacityAdded || 0
								: TBtoTiB(usableCapacityAdded || 0)
						}
						unitLabel={dataUnits === 'base10' ? 'TB' : 'TiB'}
						decimals={1}
						existing={true}
					/>
				)}
				<TableCell data={`${stripeWidth},${dataElementsPerStripe}`} />
				<ConditionalTableItem
					show={!summary || !!attributes['capacity'].value}
					forcedDisplay={summary && !!attributes['capacity'].value}
				>
					<TableCell
						data={dataUnits === 'base10' ? capacity : TBtoTiB(capacity)}
						unitLabel={dataUnits === 'base10' ? 'TB' : 'TiB'}
						decimals={1}
					/>
				</ConditionalTableItem>
				<TableCell
					data={
						dataUnits === 'base10'
							? capacityScalesTo
							: TBtoTiB(capacityScalesTo)
					}
					unitLabel={dataUnits === 'base10' ? 'TB' : 'TiB'}
					decimals={1}
					// warning={
					// 	nodeCountScalesTo < 100 && usableCapacity * 1.5 > capacityScalesTo
					// 		? strings.tableWarnings.capacityScalesTo
					// 		: undefined
					// }
				/>
				<ConditionalTableItem
					show={!summary || !!attributes['efficiency'].value}
					forcedDisplay={summary && !!attributes['efficiency'].value}
				>
					<TableCell data={efficiency * 100} decimals={1} unitLabel="%" />
				</ConditionalTableItem>
			</AttributesBlock>
			<AttributesBlock category="nodes">
				<TableCell data={nodeCount} unitLabel="nodes" />
				{viewSlice.mode === 'existing' && (
					<TableCell data={nodeAdded || 0} unitLabel="nodes" existing={true} />
				)}
				{/*<TableCell*/}
				{/*	data={nodeCountScalesTo}*/}
				{/*	unitLabel="nodes"*/}
				{/*	warning={*/}
				{/*		nodeCountScalesTo < 100 &&*/}
				{/*		nodeCount * 1.5 > nodeCountScalesTo &&*/}
				{/*		nodeCountScalesTo - nodeCount < 20*/}
				{/*			? strings.tableWarnings.nodeCountScalesTo*/}
				{/*			: undefined*/}
				{/*	}*/}
				{/*/>*/}
			</AttributesBlock>
			<AttributesBlock
				category="cost"
				titleValue={performanceClass}
				unfoldable={true}
			></AttributesBlock>
			<AttributesBlock category="performance">
				<TableCell
					data={dataUnits === 'base10' ? burstWrite : MBtoMiB(burstWrite)}
					unitLabel={dataUnits === 'base10' ? 'MB/s' : 'MiB/s'}
					warning={
						viewSlice.mode === 'existing' &&
						existing &&
						existing?.burstWrite > burstWrite
							? strings.tableWarnings.burstWrite
							: undefined
					}
				/>
				<TableCell
					data={dataUnits === 'base10' ? cachedRead : MBtoMiB(cachedRead)}
					unitLabel={dataUnits === 'base10' ? 'MB/s' : 'MiB/s'}
					warning={
						viewSlice.mode === 'existing' &&
						existing &&
						existing?.cachedRead > cachedRead
							? strings.tableWarnings.cachedRead
							: undefined
					}
				/>
				<TableCell
					data={iops}
					warning={
						viewSlice.mode === 'existing' && existing && existing?.iops > iops
							? strings.tableWarnings.iops
							: undefined
					}
				/>
				<ConditionalTableItem
					show={!summary || !!attributes['sustainedWrite'].value}
					forcedDisplay={summary && !!attributes['sustainedWrite'].value}
				>
					<TableCell
						data={
							dataUnits === 'base10' ? sustainedWrite : MBtoMiB(sustainedWrite)
						}
						unitLabel={dataUnits === 'base10' ? 'MB/s' : 'MiB/s'}
						warning={
							viewSlice.mode === 'existing' &&
							existing &&
							existing?.sustainedWrite > sustainedWrite
								? strings.tableWarnings.sustainedWrite
								: undefined
						}
					/>
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['singleStreamWrite'].value}
					forcedDisplay={summary && !!attributes['singleStreamWrite'].value}
				>
					<TableCell
						data={
							dataUnits === 'base10'
								? singleStreamWrite
								: MBtoMiB(singleStreamWrite)
						}
						unitLabel={dataUnits === 'base10' ? 'MB/s' : 'MiB/s'}
						warning={
							viewSlice.mode === 'existing' &&
							existing &&
							existing?.singleStreamWrite > singleStreamWrite
								? strings.tableWarnings.singleStreamWrite
								: undefined
						}
					/>
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['uncachedRead'].value}
					forcedDisplay={summary && !!attributes['uncachedRead'].value}
				>
					<TableCell
						data={dataUnits === 'base10' ? uncachedRead : MBtoMiB(uncachedRead)}
						unitLabel={dataUnits === 'base10' ? 'MB/s' : 'MiB/s'}
						warning={
							viewSlice.mode === 'existing' &&
							existing &&
							existing?.uncachedRead > uncachedRead
								? strings.tableWarnings.uncachedRead
								: undefined
						}
					/>
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['singleStreamCachedRead'].value}
					forcedDisplay={
						summary && !!attributes['singleStreamCachedRead'].value
					}
				>
					<TableCell
						data={
							dataUnits === 'base10'
								? singleStreamCachedRead
								: MBtoMiB(singleStreamCachedRead)
						}
						unitLabel={dataUnits === 'base10' ? 'MB/s' : 'MiB/s'}
						warning={
							viewSlice.mode === 'existing' &&
							existing &&
							existing?.singleStreamCachedRead > singleStreamCachedRead
								? strings.tableWarnings.singleStreamCachedRead
								: undefined
						}
					/>
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['singleStreamUncachedRead'].value}
					forcedDisplay={
						summary && !!attributes['singleStreamUncachedRead'].value
					}
				>
					<TableCell
						data={
							dataUnits === 'base10'
								? singleStreamUncachedRead
								: MBtoMiB(singleStreamUncachedRead)
						}
						unitLabel={dataUnits === 'base10' ? 'MB/s' : 'MiB/s'}
						warning={
							viewSlice.mode === 'existing' &&
							existing &&
							existing?.singleStreamUncachedRead > singleStreamUncachedRead
								? strings.tableWarnings.singleStreamUncachedRead
								: undefined
						}
					/>
				</ConditionalTableItem>
			</AttributesBlock>
			<AttributesBlock category="dataProtection">
				<TableCell data={driveOutageTolerance} />
				<TableCell
					data={nodeOutageTolerance}
					warning={
						nodeCount / 25 > nodeOutageTolerance
							? strings.tableWarnings.nodeOutageTolerance
							: undefined
					}
				/>
				{/* 				<ConditionalTableItem
					show={!summary || !!attributes['stripeWidth'].value}
					forcedDisplay={summary && !!attributes['stripeWidth'].value}
				>
					<TableCell data={stripeWidth} />
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['dataElementsPerStripe'].value}
					forcedDisplay={summary && !!attributes['dataElementsPerStripe'].value}
				>
					<TableCell data={dataElementsPerStripe} />
				</ConditionalTableItem>
 				*/}
			</AttributesBlock>
			<AttributesBlock category="networking">
				<TableCell data={frontEndPorts} unitLabel="ports" />
				{viewSlice.mode === 'existing' && (
					<TableCell
						data={frontEndPortsAdded || 0}
						unitLabel="ports"
						existing={true}
					/>
				)}
				<TableCell data={backEndPorts} unitLabel="ports" />
				{viewSlice.mode === 'existing' && (
					<TableCell
						data={backEndPortsAdded || 0}
						unitLabel="ports"
						existing={true}
					/>
				)}
				<TableCell
					data={frontEndNetworking}
					unitLabel="GbE"
					warning={
						viewSlice.mode === 'existing' &&
						existing &&
						frontEndNetworkingAdded &&
						(existing?.frontEndNetworking > frontEndNetworking ||
							existing?.frontEndNetworking > frontEndNetworkingAdded)
							? strings.tableWarnings.frontEndNetworkingLower
							: existing &&
							  frontEndNetworkingAdded &&
							  (existing?.frontEndNetworking < frontEndNetworking ||
									existing?.frontEndNetworking < frontEndNetworkingAdded)
							? strings.tableWarnings.frontEndNetworkingHigher
							: undefined
					}
				/>
				<TableCell
					data={backEndNetworking}
					unitLabel="GbE"
					warning={
						viewSlice.mode === 'existing' &&
						existing &&
						backEndNetworkingAdded &&
						(existing?.backEndNetworking > backEndNetworking ||
							existing?.backEndNetworking > backEndNetworkingAdded)
							? strings.tableWarnings.backEndNetworkingLower
							: existing &&
							  backEndNetworkingAdded &&
							  (existing?.backEndNetworking < backEndNetworking ||
									existing?.backEndNetworking < backEndNetworkingAdded)
							? strings.tableWarnings.backEndNetworkingHigher
							: undefined
					}
				/>
			</AttributesBlock>
			<AttributesBlock category="endurance">
				<TableCell
					data={dataUnits === 'base10' ? writeVolume : TBtoTiB(writeVolume)}
					unitLabel={dataUnits === 'base10' ? 'TB/day' : 'TiB/day'}
					decimals={1}
					warning={
						viewSlice.mode === 'existing' &&
						existing &&
						existing?.writeVolume > writeVolume
							? strings.tableWarnings.writeVolume
							: undefined
					}
				/>
				<ConditionalTableItem
					show={!summary || !!attributes['clusterOverwriteCadence'].value}
					forcedDisplay={
						summary && !!attributes['clusterOverwriteCadence'].value
					}
				>
					<TableCell
						data={clusterOverwriteCadence}
						unitLabel={'days'}
						warning={
							viewSlice.mode === 'existing' &&
							existing &&
							existing?.clusterOverwriteCadence * 1.2 < clusterOverwriteCadence
								? strings.tableWarnings.clusterOverwriteCadence
								: undefined
						}
					/>
				</ConditionalTableItem>
			</AttributesBlock>
			<AttributesBlock category="environmentals">
				<TableCell data={rackSpaceRequired} unitLabel="U" />
				{viewSlice.mode === 'existing' && (
					<TableCell
						data={rackSpaceRequiredAdded || 0}
						unitLabel="U"
						existing={true}
					/>
				)}
				<ConditionalTableItem
					show={!summary || !!attributes['height'].value}
					forcedDisplay={summary && !!attributes['height'].value}
				>
					<TableCell
						data={
							measurements === 'standard' ? height : inToCm(height).toFixed(2)
						}
						unitLabel={measurements === 'standard' ? 'in' : 'cm'}
					/>
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['width'].value}
					forcedDisplay={summary && !!attributes['width'].value}
				>
					<TableCell
						data={
							measurements === 'standard' ? width : inToCm(width).toFixed(2)
						}
						unitLabel={measurements === 'standard' ? 'in' : 'cm'}
					/>
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['depth'].value}
					forcedDisplay={summary && !!attributes['depth'].value}
				>
					<TableCell
						data={
							measurements === 'standard' ? depth : inToCm(depth).toFixed(2)
						}
						unitLabel={measurements === 'standard' ? 'in' : 'cm'}
						warning={
							viewSlice.mode === 'existing' &&
							existing &&
							existing?.depth < depth
								? strings.tableWarnings.depth
								: undefined
						}
					/>
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['weight'].value}
					forcedDisplay={summary && !!attributes['weight'].value}
				>
					<TableCell
						data={
							measurements === 'standard' ? weight : lbsToKg(weight).toFixed(2)
						}
						unitLabel={measurements === 'standard' ? 'lbs' : 'kg'}
					/>
					{viewSlice.mode === 'existing' && (
						<TableCell
							data={
								measurements === 'standard'
									? weightAdded || 0
									: lbsToKg(weightAdded || 0).toFixed(2)
							}
							unitLabel={measurements === 'standard' ? 'lbs' : 'kg'}
							existing={true}
						/>
					)}
				</ConditionalTableItem>
				<TableCell data={typicalWatts} unitLabel="W" />
				{viewSlice.mode === 'existing' && (
					<TableCell
						data={typicalWattsAdded || 0}
						unitLabel="W"
						existing={true}
					/>
				)}
				<ConditionalTableItem
					show={!summary || !!attributes['typicalAmps110V'].value}
					forcedDisplay={summary && !!attributes['typicalAmps110V'].value}
				>
					<TableCell data={typicalAmps110V} unitLabel="A" />
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['typicalAmps240V'].value}
					forcedDisplay={summary && !!attributes['typicalAmps240V'].value}
				>
					<TableCell data={typicalAmps240V} unitLabel="A" />
				</ConditionalTableItem>
				<ConditionalTableItem
					show={!summary || !!attributes['typicalThermalBTU'].value}
					forcedDisplay={summary && !!attributes['typicalThermalBTU'].value}
				>
					<TableCell data={typicalThermalBTU} unitLabel="BTU/hr" />
				</ConditionalTableItem>
			</AttributesBlock>
		</Column>
	);
};

export default TableColumn;
