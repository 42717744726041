import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../hooks';

// img
import errorImg from '../../assets/qumulo_logo.svg';

const ErrorWrapper = styled.div`
	display: flex;
	justify-content: center;
	vertical-align: middle;
	height: 100vh;
	width: 100vw;
	background-color: #f4f3f2;
	padding: 40px;
`;

const ElementsWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const ImageError = styled.img`
	width: 30vw;
	max-width: 500px;
	min-width: 200px;
	padding-bottom: 10%;
`;

const CenterWrapper = styled.div`
	display: flex;
	justify-content: center;
	vertical-align: middle;
	flex-direction: column;
`;

const TitleError = styled.span`
	font-size: 1.15em;
	font-weight: 500;
	line-height: 1.4em;
	padding-bottom: 20px;
	text-align: center;
`;

const TextError = styled.span`
	font-size: 1em;
	padding-bottom: 50px;
	text-align: center;
`;

const ErrorEmailAuth = (props: { errorDescription: string }) => {
	const query = useQuery();
	const history = useHistory();

	const messages = [
		{
			key: 'email_verification_needed',
			value: (
				<>
					<TextError>
						Please verify your email. Once complete, you can log in below.
					</TextError>
					<button
						className="btn btn-primary"
						onClick={() => {
							query.delete('error');
							query.delete('error_description');
							history.push(`?${query.toString()}`);
							window.location.reload();
						}}
					>
						Log in
					</button>
				</>
			),
		},
	];

	return (
		<ErrorWrapper>
			<ElementsWrapper>
				<CenterWrapper>
					<ImageError src={errorImg} />
				</CenterWrapper>
				<ElementsWrapper>
					<CenterWrapper>
						<TitleError>Welcome to the Qumulo Sizing Tool!</TitleError>
					</CenterWrapper>
					<CenterWrapper>
						{
							messages.find((item) => item.key === props.errorDescription)
								?.value
						}
					</CenterWrapper>
				</ElementsWrapper>
			</ElementsWrapper>
		</ErrorWrapper>
	);
};

export default ErrorEmailAuth;
