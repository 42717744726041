import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsBrokenAxis from 'highcharts/modules/broken-axis';
import ChartHeader from '../ChartHeader';

// Colors
import colors from '../../../../utils/chartsColors.json';

import {
	fetchChartsCapacity,
	fetchChartsCapacityExisting,
	chartsSelector,
} from '../../../../slices/calculator/charts';
import { filtersSelector } from '../../../../slices/calculator/filters';
import { dataExistingSelector } from '../../../../slices/calculator/dataExisting';
import { viewSelector } from '../../../../slices/calculator/view';
import { globalSelector } from '../../../../slices/global';

import { ChartData, ChartReq, ChartReqExisting } from '../../../../types';
import {
	calculateCostPerRawTB,
	formatChartData,
	yoyGrowthToDate,
} from '../utils';
import {
	defaultChartOptions,
	getBaseUsageYAxisPlotlinesOptions,
	getMaxNodesXPlotLinesOptions,
} from '../chartDefaultConfig';
import { formatNumberWithUnit } from '../../../../utils/conversions';

highchartsBrokenAxis(Highcharts);

type CapacityChartProps = {
	model: string;
	encoding: number[];
	nodes: number;
	readOnly?: boolean;
	nodeProtect: number;
};

// function showMoreDetails(e) {
// 	console.log(e);
// }

// (function (H) {
// 	H.wrap(H.Tooltip.prototype, 'refresh', function (proceed, point) {
// 		proceed.apply(this, Array.prototype.slice.call(arguments, 1));

// 		var button = this.label.div.querySelector('button');

// 		if (button && !button.addedEvent) {
// 			button.addEventListener('click', function (event) {
// 				showMoreDetails(event);
// 			});
// 			button.addedEvent = true;
// 		}
// 	});
// })(Highcharts);

const CapacityChart = (props: CapacityChartProps) => {
	const dispatch = useDispatch();
	const chartsSlice = useSelector(chartsSelector);
	const globalSlice = useSelector(globalSelector);
	const filtersSlice = useSelector(filtersSelector);
	const { clusters } = useSelector(dataExistingSelector);
	const viewSlice = useSelector(viewSelector);
	const dataUnits = globalSlice.dataUnits;
	const chartRef = useRef<{
		chart: Highcharts.Chart;
		container: React.RefObject<HTMLDivElement>;
	}>(null);

	const selectedCluster = clusters.find(
		(item) => item.modelUid === viewSlice.selection[0],
	);

	const [chartData, setChartData] = useState<ChartData>([]);

	const [chartOptions, setChartOptions] =
		useState<Highcharts.Options>(defaultChartOptions);

	// Fetch
	useEffect(() => {
		if (
			viewSlice.mode === 'existing' &&
			viewSlice.selection.length &&
			props.encoding &&
			selectedCluster
		) {
			const params: ChartReqExisting = {
				existingCluster: {
					nodes: filtersSlice.existings.map((cluster) => ({
						modelUid: cluster.modelUid,
						nodeCount: cluster.nodeCount !== undefined ? cluster.nodeCount : 0,
						replace: cluster.replace,
					})),
					nodeProtect: parseInt(filtersSlice.nodeOutageTolerance),
					encoding: {
						n: parseInt(filtersSlice.stripeWidth.split(',')[0]),
						k: parseInt(filtersSlice.stripeWidth.split(',')[1]),
					},
				},
				selected: {
					modelUid: selectedCluster.modelUid,
					nodeCount: selectedCluster.nodeAdded || 0,
					encoding: {
						n: selectedCluster.stripeWidth,
						k: selectedCluster.dataElementsPerStripe,
					},
					nodeProtect: selectedCluster.nodeOutageTolerance,
				},
				toMaxNodes: chartsSlice.selector.showMaxScaling,
				allowTranscoding: filtersSlice.allowTranscoding,
			};
			dispatch(fetchChartsCapacityExisting(params));
		} else if (
			viewSlice.mode !== 'existing' &&
			viewSlice.selection.length &&
			props.encoding
		) {
			const params: ChartReq = {
				modelUid: props.model,
				nodes: props.nodes,
				encoding: props.encoding,
				nodeOutageTolerance: props.nodeProtect,
				toMaxNodes: chartsSlice.selector.showMaxScaling,
			};
			dispatch(fetchChartsCapacity(params));
		}
	}, [
		dispatch,
		filtersSlice.existings,
		filtersSlice.nodeOutageTolerance,
		filtersSlice.allowTranscoding,
		filtersSlice.stripeWidth,
		selectedCluster,
		viewSlice.mode,
		viewSlice.selection,
		chartsSlice.selector.showMaxScaling,
		props.model,
		props.nodes,
		props.encoding,
		props.readOnly,
		props.nodeProtect,
	]);

	// Format
	useEffect(() => {
		if (!chartsSlice.capacity.loading && !chartsSlice.capacity.errors)
			setChartData(
				formatChartData(
					chartsSlice.capacity.details,
					'usableCapacity',
					dataUnits,
					chartsSlice.selector.showMaxScaling,
				),
			);
	}, [
		chartsSlice.capacity.details,
		chartsSlice.capacity.errors,
		chartsSlice.capacity.loading,
		chartsSlice.selector.showMaxScaling,
		dataUnits,
	]);

	// options
	useEffect(() => {
		const lastData: Highcharts.PointOptionsObject = chartData[
			chartData.length - 1
		] as Highcharts.PointOptionsObject;

		setChartOptions({
			subtitle: !(
				viewSlice.mode === 'existing' && !filtersSlice.allowTranscoding
			)
				? {
						text: '<span style="font-size: 16px">This forecast assumes transcoding to higher efficiencies</span>',
						verticalAlign: 'bottom',
						align: 'right',
				  }
				: undefined,
			xAxis: {
				plotLines: !props.readOnly
					? getMaxNodesXPlotLinesOptions(
							chartsSlice.capacity.isMaxNodeReached,
							lastData,
					  )
					: undefined,
				tickInterval: 1,
				minorTicks: false
			},
			yAxis: {
				title: {
					margin: 35,
					text: `USABLE CAPACITY (${dataUnits === 'base10' ? 'TB' : 'TiB'})`,
				},
				labels: {
					formatter: (context) =>
						`${
							typeof context.value === 'string'
								? Highcharts.numberFormat(parseInt(context.value), 0, '.', ',')
								: Highcharts.numberFormat(context.value, 0, '.', ',')
						} ${dataUnits === 'base10' ? 'TB' : 'TiB'}`,
				},
				plotLines: getBaseUsageYAxisPlotlinesOptions(
					dataUnits,
					chartsSlice.selector.baseUsage,
				),
			},
			tooltip: {
				useHTML: true,
				backgroundColor: 'rgba(255, 255, 255, 1)',
				borderWidth: 3,
				borderRadius: 15,
				borderColor: colors.tooltipColor,
				padding: 0,
				shared: true,
				formatter: function () {
					const intl = new Intl.NumberFormat('en-US');
					const capacityPoint = this.points?.[0];
					const { yoyGrowth, costPerRawTB } = chartsSlice.selector;
					let options = '';

					if (yoyGrowth && chartsSlice.capacity.details.length) {
						const yoyDate = yoyGrowthToDate(
							this.y,
							chartsSlice.capacity.details[0].usableCapacity,
							yoyGrowth,
						);
						if (yoyDate)
							options += `<span class='chart-tooltip-body-text'>${yoyDate}</span>`;
					}

					if (costPerRawTB && !chartsSlice.summaryView) {
						options += `<span class='chart-tooltip-body-text'>
								${globalSlice.currency}
								${calculateCostPerRawTB(costPerRawTB, this.y, globalSlice.currency)}
							</span>`;
					}

					// @ts-ignore
					// @ts-ignore
					return `
						<div class='chart-tooltip'>
							<span class='chart-tooltip-header'>
								<span class='chart-tooltip-header-title'>
								${this.points[0].x} nodes
								</span>
							</span>
							<div class='chart-tooltip-divider'></div>

							<div class='chart-tooltip-body'>
							<span class='chart-tooltip-body-text'>
							<div class='diamond-small' style='background-color: ${
								this.points[0].color
							};'></div>

								${intl.format(parseFloat(this.y.toFixed(1)))}
								${dataUnits === 'base10' ? ' TB' : ' TiB'}
							</span>
							<span class='chart-tooltip-body-text'>
							EC: ${capacityPoint?.point?.custom?.encoding[0]},  ${capacityPoint?.point?.custom?.encoding[1]}
							</span>


								${
									viewSlice.mode === 'existing'
										? `<span class='chart-tooltip-body-text'>
											${
												chartsSlice.summaryView
													? formatNumberWithUnit(
															// @ts-ignore
															capacityPoint?.point?.custom
																?.nodeAdded as number,
															0,
													  )
													: intl.format(
															// @ts-ignore
															capacityPoint?.point?.custom
																?.nodeAdded as number,
													  )
											} nodes added
										</span>
										<span class='chart-tooltip-body-text'>
											${
												chartsSlice.summaryView
													? formatNumberWithUnit(
															// @ts-ignore
															capacityPoint?.point?.custom
																?.usableCapacityAdded as number,
															0,
													  )
													: intl.format(
															// @ts-ignore
															capacityPoint?.point?.custom
																?.usableCapacityAdded as number,
													  )
											} TB capacity added
										</span>`
										: ''
								}
								${
									options.length > 0
										? `
										<div class='chart-tooltip-divider'></div>
										<div class='chart-tooltip-body'>
											${options}
										</div>
									`
										: ''
								}
							</div>
						</div>
					`;
				},
			},
			series: [
				{
					name: 'Capacity',
					type: 'line',
					color: colors.blue,
					data: chartData,
					dataLabels: {
						enabled: !chartsSlice.summaryView,
						format: '{point.y:.1f}',
						y: -10,
					},
					marker: {
						enabled: true,
						radius: 7,
					},
				},
			],
		});
	}, [
		chartData,
		chartsSlice.capacity.details,
		chartsSlice.capacity.isMaxNodeReached,
		chartsSlice.selector,
		chartsSlice.summaryView,
		dataUnits,
		globalSlice.currency,
		viewSlice.mode,
		props.readOnly,
		filtersSlice.allowTranscoding,
	]);

	useEffect(() => {
		const chart = chartRef.current?.chart;
		chartsSlice.capacity.loading ? chart?.showLoading() : chart?.hideLoading();
	}, [chartsSlice.capacity.loading]);

	return (
		<div className="details-chart-container">
			{!props.readOnly && (
				<ChartHeader>
					<div className="legends-wrapper">
						<div className="diamond-small" />
						<div className="legend-text">
							{viewSlice.mode === 'existing' && viewSlice.selection[0].length
								? clusters?.find(
										(cluster) => cluster.modelUid === viewSlice.selection[0],
								  )?.nodeCount
								: props.nodes}{' '}
							Node Cluster
						</div>
					</div>
				</ChartHeader>
			)}
			<div style={{ height: '100%' }}>
				<HighchartsReact
					highcharts={Highcharts}
					options={chartOptions}
					containerProps={{ style: { height: '100%' } }}
					ref={chartRef}
				/>
			</div>
		</div>
	);
};

export default CapacityChart;
