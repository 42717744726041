import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { Switch } from '../../../components/forms';
import { useDispatch, useSelector } from 'react-redux';
import { globalSelector } from '../../../slices/global';
import {
	chartsSelector,
	setShowMaxScaling,
	setBaseUsage,
	setYOYGrowth,
	setChartType,
} from '../../../slices/calculator/charts';

type Props = {
	compact?: boolean;
	children: ReactNode;
};

const ChartHeaderWrapper = styled.div`
	padding: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const ActionsWrapper = styled.div<Props>`
	display: flex;

	${(props) =>
		props.compact &&
		`
		flex-direction: column;
		align-items: center;
	`}

	& > * {
		padding-top: 5px;
		padding-bottom: 5px;
	}

	> label {
		margin-left: 20px;
	}

	& > label > span {
		margin-right: 10px;
	}
`;

const ChartHeader: FunctionComponent<Props> = (props) => {
	const dispatch = useDispatch();
	const chartsSlice = useSelector(chartsSelector);
	const globalSlice = useSelector(globalSelector);
	const selector = chartsSlice.selector;
	const { dataUnits } = globalSlice;

	return (
		<ChartHeaderWrapper>
			<select
				className="chart-type-select"
				value={selector.chartType || 'Capacity Planning'}
				// @ts-ignore
				onChange={(e) => dispatch(setChartType(e.target.value))}
			>
				<option value="Capacity Planning">Capacity Planning</option>
				{/*{viewSlice.mode !== 'existing' ? (*/}
				{/*	<option value="Capacity & Resilience">Capacity & Resilience</option>*/}
				{/*) : null}*/}
				<option value="Capacity & IOPS">Capacity & IOPS</option>
				<option value="Capacity & Throughput">Capacity & Throughput</option>
			</select>
			<div className="vertical-divider" />
			{props.children}
			<div className="vertical-divider" />
			<div style={{ display: 'flex' }}>
				<Switch
					checked={selector.showMaxScaling}
					onChange={(e) => dispatch(setShowMaxScaling(e))}
					label={'Show max scaling'}
				/>
				<ActionsWrapper compact={props.compact}>
					<label htmlFor="" style={{ marginBottom: '0px' }}>
						<span className="header-text">{`Base Usage (${
							dataUnits === 'base10' ? 'TB' : 'TiB'
						}) `}</span>
						<input
							style={{ maxWidth: '70px' }}
							type="number"
							value={selector.baseUsage || ''}
							onChange={(e) => dispatch(setBaseUsage(parseInt(e.target.value)))}
						/>
						<i
							onClick={() => dispatch(setBaseUsage(null))}
							className="fas fa-xs fa-times-circle clear-button"
						/>
					</label>
					<label htmlFor="" style={{ marginBottom: '0px' }}>
						<span className="header-text">{`YOY Growth (${
							dataUnits === 'base10' ? 'TB' : 'TiB'
						}) `}</span>
						<input
							style={{ maxWidth: '70px' }}
							type="number"
							value={selector.yoyGrowth || ''}
							onChange={(e) => dispatch(setYOYGrowth(parseInt(e.target.value)))}
							min={0}
						/>
						<i
							onClick={() => dispatch(setYOYGrowth(null))}
							className="fas fa-xs fa-times-circle clear-button"
						/>
					</label>
				</ActionsWrapper>
			</div>
		</ChartHeaderWrapper>
	);
};

export default ChartHeader;
