import React from 'react';
import styled from 'styled-components';
import { useModelsProperties, useModelPicture } from '../../hooks';
import { FormattedNodeDetail } from '../../types';
import { formatCapacity } from '../../utils/conversions';
import RowValue from '../RowValue';

type PrintModalNodeItemTabProps = {
	data: FormattedNodeDetail;
	nodeCount: number;
};

const ItemWrapper = styled.div`
	margin-bottom: 0px;
	break-after: page;
`;

const PrintModalNodeItemTab = (props: PrintModalNodeItemTabProps) => {
	const detailsProperties = useModelsProperties().getDetailsProperties();
	const { data, nodeCount } = props;
	const [frontPic, backPic] = useModelPicture(data?.modelUid);

	const topBlock = (
		<div className="print-modal-top-block row" style={{ marginRight: '0px' }}>
			<div className="col-lg-6 col-md-12 top-block-left-block">
				<div className="top-block-counter ">
					<div className="value blue bold">{data?.modelNameShort}</div>
					<div className="sub-title">Model</div>
				</div>

				<div className="top-block-counter">
					<div className="value">
						{typeof data?.rawCapacity.value == 'number' &&
							data?.rawCapacity.value.toFixed(1)}
						&nbsp;
						<span style={{ textTransform: 'none' }}>
							{data?.rawCapacity.unit}
						</span>
					</div>
					<div className="sub-title">Raw Capacity</div>
				</div>
				<div className="top-block-counter ">
					<div className="value ">{nodeCount}</div>
					<div className="sub-title">Nodes</div>
				</div>
			</div>
			<div className="col-lg-6 col-md-12 top-block-right-block">
				{frontPic && (
					<div className="top-block-pic">
						<img src={frontPic} alt="Front view" />
						<span className="pic-label">Front view</span>
					</div>
				)}
				{backPic && (
					<div className="top-block-pic">
						<img src={backPic} alt="Back view" />
						<span className="pic-label">Rear view</span>
					</div>
				)}
			</div>
		</div>
	);

	const nodeItemLayout = (
		<ItemWrapper >
			{topBlock}
			<div className="print-modal-data-wrapper">
				<div className="row data-row">
					<div className="data-cat col-md-6">
						<div className="data-block-title-wrapper">
							<h4 className="data-block-title">Performance</h4>
							<div className="divider"></div>
						</div>
						<div className="row">
							<div className="col-md-12 data-block-item">
								<RowValue
									label={detailsProperties.performanceClass.formattedLabel}
									data={data?.performanceClass.value}
									decimals={0}
									unitLabel={data?.performanceClass.unit}
								/>
								<RowValue
									label={detailsProperties.cpu.formattedLabel}
									data={data?.cpu.value}
									decimals={0}
									unitLabel={data?.cpu.unit}
								/>
								<RowValue
									label={detailsProperties.memory.formattedLabel}
									data={
										typeof data?.memory.value === 'string'
											? data?.memory.value.replace('GB', ' GB')
											: data.memory.value
									}
								/>
								{typeof data?.hddCount.value === 'number' &&
								data?.hddCount.value ? (
									<RowValue
										label={detailsProperties.hddCount.formattedLabel}
										data={`${data?.hddCount.value} x ${
											typeof data?.hddSize.value === 'number' &&
											formatCapacity(data?.hddSize.value)
										} ${data?.hddSize.unit}`}
									/>
								) : null}
								{typeof data?.ssdCount.value === 'number' &&
								data?.ssdCount.value ? (
									<RowValue
										label={detailsProperties.ssdCount.formattedLabel}
										data={`${data?.ssdCount.value} x ${
											typeof data?.ssdSize.value === 'number' &&
											formatCapacity(data?.ssdSize.value)
										} ${data?.ssdSize.unit}`}
									/>
								) : null}
							</div>
						</div>
					</div>

					<div className="data-cat col-md-6">
						<div className="data-block-title-wrapper">
							<h4 className="data-block-title">Networking</h4>
							<div className="divider"></div>
						</div>
						<div className="row">
							<div className="col-md-12 data-block-item">
								<RowValue
									label={detailsProperties.frontEndPorts.formattedLabel}
									data={data?.frontEndPorts.value}
									decimals={0}
									unitLabel={data?.frontEndPorts.unit}
								/>
								<RowValue
									label={detailsProperties.backEndPorts.formattedLabel}
									data={data?.backEndPorts.value}
									decimals={0}
									unitLabel={data?.backEndPorts.unit}
								/>
								<RowValue
									label={detailsProperties.networkingSpeed.formattedLabel}
									data={data?.networkingSpeed.value}
									decimals={0}
									unitLabel={data?.networkingSpeed.unit}
								/>
								<RowValue
									label={detailsProperties.networkConnector.formattedLabel}
									data={data?.networkConnector.value}
									decimals={0}
									unitLabel={data?.networkConnector.unit}
								/>
								<RowValue
									label={detailsProperties.managementNetwork.formattedLabel}
									data={data?.managementNetwork.value}
									decimals={0}
									unitLabel={data?.managementNetwork.unit}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="row data-row">
					<div className="data-cat col-md-12">
						<div className="data-block-title-wrapper">
							<h4 className="data-block-title">Environmentals</h4>
							<div className="divider"></div>
						</div>
						<div className="row">
							<div className="col-md-6 data-block-item">
								<RowValue
									label={detailsProperties.weight.formattedLabel}
									data={data?.weight.value}
									decimals={2}
									unitLabel={data?.weight.unit}
								/>
								<RowValue
									label={detailsProperties.height.formattedLabel}
									data={data?.height.value}
									decimals={2}
									unitLabel={data?.height.unit}
								/>
								<RowValue
									label={detailsProperties.width.formattedLabel}
									data={data?.width.value}
									decimals={2}
									unitLabel={data?.width.unit}
								/>
								<RowValue
									label={detailsProperties.depth.formattedLabel}
									data={data?.depth.value}
									decimals={2}
									unitLabel={data?.depth.unit}
								/>
								<RowValue
									label={detailsProperties.powerSupply.formattedLabel}
									data={data?.powerSupply.value}
									decimals={0}
									unitLabel={data?.powerSupply.unit}
								/>
								<RowValue
									label={detailsProperties.powerRequirements.formattedLabel}
									data={data?.powerRequirements.value}
									decimals={0}
									unitLabel={data?.powerRequirements.unit}
								/>
								<RowValue
									label={
										detailsProperties.typicalPowerConsumption.formattedLabel
									}
									data={data?.typicalPowerConsumption.value}
									decimals={0}
									unitLabel={data?.typicalPowerConsumption.unit}
								/>
								<RowValue
									label={
										detailsProperties.maximumOperatingAltitude.formattedLabel
									}
									data={`Up to ${data?.maximumOperatingAltitude.value}${data?.maximumOperatingAltitude.unit}`}
								/>
							</div>

							<div className="col-md-6 data-block-item">
								<RowValue
									label={detailsProperties.typicalThermalRating.formattedLabel}
									data={data?.typicalThermalRating.value}
									decimals={0}
									unitLabel={data?.typicalThermalRating.unit}
								/>
								<RowValue
									label={
										detailsProperties.maximumPowerConsumption.formattedLabel
									}
									data={data?.maximumPowerConsumption.value}
									decimals={0}
									unitLabel={data?.maximumPowerConsumption.unit}
								/>
								<RowValue
									label={detailsProperties.maximumThermalRating.formattedLabel}
									data={data?.maximumThermalRating.value}
									decimals={0}
									unitLabel={data?.maximumThermalRating.unit}
								/>
								<RowValue
									label={detailsProperties.operatingTemperatureF.formattedLabel}
									data={`${data?.operatingTemperatureF.min.value}${data?.operatingTemperatureF.min.unit} - ${data?.operatingTemperatureF.max.value}${data?.operatingTemperatureF.max.unit}`}
								/>
								<RowValue
									label={
										detailsProperties.nonOperatingTemperatureF.formattedLabel
									}
									data={`${data?.nonOperatingTemperatureF.min.value}${data?.nonOperatingTemperatureF.min.unit} - ${data?.nonOperatingTemperatureF.max.value}${data?.nonOperatingTemperatureF.max.unit}`}
								/>
								<RowValue
									label={detailsProperties.operatingHumidity.formattedLabel}
									data={`${data?.operatingHumidity.min.value}${data?.operatingHumidity.min.unit} - ${data?.operatingHumidity.max.value}${data?.operatingHumidity.max.unit} (non-condensing)`}
								/>
								<RowValue
									label={detailsProperties.nonOperatingHumidity.formattedLabel}
									data={`${data?.nonOperatingHumidity.min.value}${data?.nonOperatingHumidity.min.unit} - ${data?.nonOperatingHumidity.max.value}${data?.nonOperatingHumidity.max.unit} (non-condensing)`}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ItemWrapper>
	);

	return data ? nodeItemLayout : null;
};

export default PrintModalNodeItemTab;
