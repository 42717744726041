import React, { useEffect, useState, useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsBrokenAxis from 'highcharts/modules/broken-axis';

// Colors
import colors from '../../../../utils/chartsColors.json';
import { useDispatch, useSelector } from 'react-redux';
import { globalSelector } from '../../../../slices/global';
import ChartHeader from '../ChartHeader';
import {
	chartsSelector,
	fetchChartsThroughput,
	fetchChartsThroughputExisting,
} from '../../../../slices/calculator/charts';
import { filtersSelector } from '../../../../slices/calculator/filters';
import { dataExistingSelector } from '../../../../slices/calculator/dataExisting';
import { viewSelector } from '../../../../slices/calculator/view';

import {
	calculateCostPerRawTB,
	formatChartData,
	yoyGrowthToDate,
} from '../utils';
import { ChartData, ChartReq, ChartReqExisting } from '../../../../types';
import {
	defaultChartOptions,
	getBaseUsageYAxisPlotlinesOptions,
	getMaxNodesXPlotLinesOptions,
} from '../chartDefaultConfig';
import { formatNumberWithUnit } from '../../../../utils/conversions';

highchartsBrokenAxis(Highcharts);

type ThroughputChartProps = {
	model: string;
	encoding: number[];
	nodes: number;
	readOnly?: boolean;
	nodeProtect: number;
};

const ThroughputChartWrapper = (props: ThroughputChartProps) => {
	const dispatch = useDispatch();
	const chartsSlice = useSelector(chartsSelector);
	const globalSlice = useSelector(globalSelector);
	const filtersSlice = useSelector(filtersSelector);
	const { clusters } = useSelector(dataExistingSelector);
	const viewSlice = useSelector(viewSelector);
	const dataUnits = globalSlice.dataUnits;
	const chartRef = useRef<{
		chart: Highcharts.Chart;
		container: React.RefObject<HTMLDivElement>;
	}>(null);

	const selectedCluster = clusters.find(
		(item) => item.modelUid === viewSlice.selection[0],
	);


	const [capacityChartData, setCapacityChartData] = useState<ChartData>([]);
	const [readChartData, setReadChartData] = useState<ChartData>([]);
	const [writeChartData, setWriteChartData] = useState<ChartData>([]);

	const [chartOptions, setChartOptions] =
		useState<Highcharts.Options>(defaultChartOptions);

	// Fetch
	useEffect(() => {
		if (
			viewSlice.mode === 'existing' &&
			viewSlice.selection.length &&
			props.encoding &&
			selectedCluster
		) {
			const params: ChartReqExisting = {
				existingCluster: {
					nodes: filtersSlice.existings.map((cluster) => ({
						modelUid: cluster.modelUid,
						nodeCount: cluster.nodeCount !== undefined ? cluster.nodeCount : 0,
						replace: cluster.replace,
					})),
					nodeProtect: parseInt(filtersSlice.nodeOutageTolerance),
					encoding: {
						n: parseInt(filtersSlice.stripeWidth.split(',')[0]),
						k: parseInt(filtersSlice.stripeWidth.split(',')[1]),
					},
				},
				selected: {
					modelUid: selectedCluster.modelUid,
					nodeCount: selectedCluster.nodeAdded || 0,
					encoding: {
						n: selectedCluster.stripeWidth,
						k: selectedCluster.dataElementsPerStripe,
					},
					nodeProtect: selectedCluster.nodeOutageTolerance,
				},
				toMaxNodes: chartsSlice.selector.showMaxScaling,
				allowTranscoding: filtersSlice.allowTranscoding,
			};
			dispatch(fetchChartsThroughputExisting(params));
		} else if (
			viewSlice.mode !== 'existing' &&
			viewSlice.selection.length &&
			props.encoding
		) {
			const params: ChartReq = {
				modelUid: props.model,
				nodes: props.nodes,
				encoding: props.encoding,
				nodeOutageTolerance: props.nodeProtect,
				toMaxNodes: chartsSlice.selector.showMaxScaling,
			};
			dispatch(fetchChartsThroughput(params));
		}
	}, [
		dispatch,
		viewSlice.mode,
		viewSlice.selection,
		filtersSlice.existings,
		filtersSlice.nodeOutageTolerance,
		filtersSlice.allowTranscoding,
		filtersSlice.stripeWidth,
		selectedCluster,
		chartsSlice.selector.showMaxScaling,
		props.model,
		props.nodes,
		props.encoding,
		props.readOnly,
		props.nodeProtect,
	]);

	// Format
	useEffect(() => {
		if (!chartsSlice.throughput.loading && !chartsSlice.throughput.errors) {
			setCapacityChartData(
				formatChartData(
					chartsSlice.throughput.details,
					'usableCapacity',
					dataUnits,
					chartsSlice.selector.showMaxScaling,
				),
			);
			setReadChartData(
				formatChartData(
					chartsSlice.throughput.details,
					'cachedRead',
					dataUnits,
					chartsSlice.selector.showMaxScaling,
				),
			);
			setWriteChartData(
				formatChartData(
					chartsSlice.throughput.details,
					'burstWrite',
					dataUnits,
					chartsSlice.selector.showMaxScaling,
				),
			);
		}
	}, [
		chartsSlice.selector.showMaxScaling,
		chartsSlice.throughput.details,
		chartsSlice.throughput.errors,
		chartsSlice.throughput.loading,
		dataUnits,
	]);

	useEffect(() => {
		const lastData: Highcharts.PointOptionsObject = capacityChartData[
			capacityChartData.length - 1
		] as Highcharts.PointOptionsObject;

		setChartOptions({
			legend: { enabled: props.readOnly },
			xAxis: {
				plotLines: !props.readOnly
					? getMaxNodesXPlotLinesOptions(
							chartsSlice.capacity.isMaxNodeReached,
							lastData,
					  )
					: undefined,
				tickInterval: 1,
				minorTicks: false

			},
			yAxis: [
				{
					title: {
						margin: 35,
						text: `USABLE CAPACITY (${dataUnits === 'base10' ? 'TB' : 'TiB'})`,
					},
					labels: {
						formatter: (context) =>
							`${
								typeof context.value === 'string'
									? Highcharts.numberFormat(
											parseInt(context.value),
											0,
											'.',
											',',
									  )
									: Highcharts.numberFormat(context.value, 0, '.', ',')
							} ${dataUnits === 'base10' ? 'TB' : 'TiB'}`,
					},
					plotLines: getBaseUsageYAxisPlotlinesOptions(
						dataUnits,
						chartsSlice.selector.baseUsage,
					),
				},
				{
					opposite: true,
					title: {
						margin: 35,
						text: `THROUGHPUT (${dataUnits === 'base10' ? 'MB' : 'MiB'}/S)`,
					},
				},
			],
			tooltip: {
				useHTML: true,
				backgroundColor: 'rgba(255, 255, 255, 1)',
				borderWidth: 3,
				borderRadius: 15,
				borderColor: colors.tooltipColor,
				padding: 0,
				shared: true,
				formatter: function () {
					const intl = new Intl.NumberFormat('en-US');
					const capacityPoint = this.points?.[0];
					const readPoint = this.points?.[1];
					const writePoint = this.points?.[2];
					const { yoyGrowth, costPerRawTB } = chartsSlice.selector;
					let options = '';

					if (
						yoyGrowth &&
						chartsSlice.throughput.details.length &&
						!chartsSlice.summaryView
					) {
						const yoyDate = yoyGrowthToDate(
							this.y,
							chartsSlice.throughput.details[0].usableCapacity,
							yoyGrowth,
						);
						if (yoyDate)
							options += `<span class='chart-tooltip-body-text'>${yoyDate}</span>`;
					}

					if (costPerRawTB && !chartsSlice.summaryView) {
						options += `<span class='chart-tooltip-body-text'>
								${globalSlice.currency}
								${calculateCostPerRawTB(costPerRawTB, this.y, globalSlice.currency)}
							</span>`;
					}

					return `
						<div class='chart-tooltip'>
							<span class='chart-tooltip-header'>
								<span class='chart-tooltip-header-title'>
									${this.points[0].x} nodes
								</span>
							</span>
							<div class='chart-tooltip-divider'></div>
							<div class='chart-tooltip-body'>
								<span class='chart-tooltip-header-title'>
								<div class='diamond-small' style='background-color: ${
									this.points[0].color
								};'></div>

											${intl.format(parseFloat(this.y.toFixed(1)))}${
						dataUnits === 'base10' ? ' TB' : ' TiB'
					}
								</span>
								<span class='chart-tooltip-body-text'>
								EC: ${capacityPoint?.point?.custom?.encoding[0]},  ${capacityPoint?.point?.custom?.encoding[1]}
								</span>

								<div class='chart-tooltip-divider'></div>

								<span class='chart-tooltip-body-text'>
								<div class='square' style='background-color: ${
									this.points[2].color
								};'></div>&nbsp;

									${chartsSlice.summaryView ? 'W' : 'Burst Writes'}: ${intl.format(
						parseFloat((writePoint?.y as number).toFixed(1)),
					)}
									${dataUnits === 'base10' ? 'MB' : 'MiB'}/S
								</span>
								<span class='chart-tooltip-body-text'>
								<div class='square' style='background-color: ${
									this.points[1].color
								};'></div>&nbsp;

								${chartsSlice.summaryView ? 'R' : 'Cached Reads'}: ${intl.format(
						parseFloat((readPoint?.y as number).toFixed(1)),
					)}
												${dataUnits === 'base10' ? 'MB' : 'MiB'}/S
								</span>
								${
									viewSlice.mode === 'existing'
										? `<span class='chart-tooltip-body-text'>
											${
												chartsSlice.summaryView
													? formatNumberWithUnit(
															// @ts-ignore
															capacityPoint?.point?.custom
																?.nodeAdded as number,
															0,
													  )
													: intl.format(
															// @ts-ignore
															capacityPoint?.point?.custom
																?.nodeAdded as number,
													  )
											} nodes added
										</span>
										<span class='chart-tooltip-body-text'>
											${
												chartsSlice.summaryView
													? formatNumberWithUnit(
															// @ts-ignore
															capacityPoint?.point?.custom
																?.usableCapacityAdded as number,
															0,
													  )
													: intl.format(
															// @ts-ignore
															capacityPoint?.point?.custom
																?.usableCapacityAdded as number,
													  )
											} TB capacity added
										</span>`
										: ''
								}
								${
									options.length > 0
										? `<div class='chart-tooltip-divider'></div>${options}`
										: ''
								}
							</div>
						</div>
					`;
				},
			},
			series: [
				{
					name: 'Capacity',
					yAxis: 0,
					type: 'line',
					zIndex: 2,
					color: colors.blue,
					data: capacityChartData,
					marker: {
						enabled: true,
						radius: 5,
					},
					dataLabels: {
						enabled: !chartsSlice.summaryView,
						format: '{point.y:.1f}',
						y: -10,
					},
				},
				{
					name: 'Cached Reads',
					yAxis: 1,
					type: 'areaspline',
					opacity: 0.5,
					color: colors.lightBlue,
					data: readChartData,
					zIndex: 1,
					marker: {
						enabled: false,
					},
				},
				{
					name: 'Burst Writes',
					yAxis: 1,
					type: 'areaspline',
					opacity: 0.5,
					color: colors.darkBlue,
					data: writeChartData,
					zIndex: 1,
					marker: {
						enabled: false,
					},
				},
			],
		});
	}, [
		capacityChartData,
		chartsSlice.selector,
		chartsSlice.summaryView,
		chartsSlice.throughput.details,
		chartsSlice.throughput.isMaxNodeReached,
		chartsSlice.capacity.isMaxNodeReached,
		dataUnits,
		globalSlice.currency,
		readChartData,
		viewSlice.mode,
		writeChartData,
		props.readOnly,
	]);

	useEffect(() => {
		const chart = chartRef.current?.chart;
		chartsSlice.throughput.loading
			? chart?.showLoading()
			: chart?.hideLoading();
	}, [chartsSlice.throughput.loading]);

	return (
		<div className="details-chart-container">
			{!props.readOnly && (
				<ChartHeader compact>
					<div className="legends-wrapper">
						<div className="legend-wrapper">
							<div className="diamond-small" />
							<div className="legend-text">
								{viewSlice.mode === 'existing' && viewSlice.selection[0].length
									? clusters?.find(
											(cluster) => cluster.modelUid === viewSlice.selection[0],
									  )?.nodeCount
									: props.nodes}{' '}
								Node Cluster
							</div>
						</div>
						<div className="legend-wrapper">
							<div
								className="square"
								style={{ background: colors.lightBlue }}
							/>
							<div className="legend-text" style={{ color: colors.lightBlue }}>
								Cached Reads
							</div>
						</div>
						<div className="legend-wrapper">
							<div className="square" style={{ background: colors.darkBlue }} />
							<div className="legend-text" style={{ color: colors.darkBlue }}>
								Burst Writes
							</div>
						</div>
					</div>
				</ChartHeader>
			)}
			<div style={{ height: '100%' }}>
				<HighchartsReact
					highcharts={Highcharts}
					options={chartOptions}
					containerProps={{ style: { height: '100%' } }}
					ref={chartRef}
				/>
			</div>
		</div>
	);
};

export default ThroughputChartWrapper;
