import React, { FunctionComponent, ReactNode, useMemo } from 'react';

type RowCustomValueProps = {
	label: string;
	unitLabel?: string;
	children: ReactNode;
};

const RowCustomValue: FunctionComponent<RowCustomValueProps> = (props) => {
	const { unitLabel, label, children } = props;

	return useMemo(() => {
		return (
			<div className="data-block-data">
				<div className="label">{label}</div>
				<div className="value">
					{children}
					&nbsp;
					{unitLabel}
				</div>
			</div>
		);
	}, [label, children, unitLabel]);
};

export default RowCustomValue;
