import { createSlice } from '@reduxjs/toolkit';
import { uid } from 'uid';
import { modelsSelector } from './models';
import { viewSelector, toggleInSelection } from './view';

// Types
import {
	FiltersSliceState,
	RootState,
	FiltersAttributes,
	Model,
	FiltersModel,
	AppDispatch,
	ExistingItem,
} from '../../types';

export const initialState: FiltersSliceState = {
	attributes: {
		usableCapacity: {
			value: 100,
			filter: 'none',
		},
		capacity: {
			value: null,
			filter: 'none',
		},
		nodeCount: {
			value: null,
			filter: 'none',
		},
		capacityScalesTo: {
			value: null,
			filter: 'none',
		},
		nodeCountScalesTo: {
			value: null,
			filter: 'none',
		},
		efficiency: {
			value: null,
			filter: 'none',
		},
		burstWrite: {
			value: null,
			filter: 'none',
		},
		cachedRead: {
			value: null,
			filter: 'none',
		},
		iops: {
			value: null,
			filter: 'none',
		},
		sustainedWrite: {
			value: null,
			filter: 'none',
		},
		singleStreamWrite: {
			value: null,
			filter: 'none',
		},
		uncachedRead: {
			value: null,
			filter: 'none',
		},
		singleStreamCachedRead: {
			value: null,
			filter: 'none',
		},
		singleStreamUncachedRead: {
			value: null,
			filter: 'none',
		},
		frontEndPorts: {
			value: null,
			filter: 'none',
		},
		backEndPorts: {
			value: null,
			filter: 'none',
		},
		frontEndNetworking: {
			value: null,
			filter: 'none',
		},
		backEndNetworking: {
			value: null,
			filter: 'none',
		},
		writeVolume: {
			value: null,
			filter: 'none',
		},
		clusterOverwriteCadence: {
			value: null,
			filter: 'none',
		},
		driveOutageTolerance: {
			value: null,
			filter: 'none',
		},
		stripeWidth: {
			value: null,
			filter: 'none',
		},
		dataElementsPerStripe: {
			value: null,
			filter: 'none',
		},
		nodeOutageTolerance: {
			value: null,
			filter: 'none',
		},
		rackSpaceRequired: {
			value: null,
			filter: 'none',
		},
		height: {
			value: null,
			filter: 'none',
		},
		width: {
			value: null,
			filter: 'none',
		},
		depth: {
			value: null,
			filter: 'none',
		},
		weight: {
			value: null,
			filter: 'none',
		},
		typicalWatts: {
			value: null,
			filter: 'none',
		},
		typicalAmps110V: {
			value: null,
			filter: 'none',
		},
		typicalAmps240V: {
			value: null,
			filter: 'none',
		},
		typicalThermalBTU: {
			value: null,
			filter: 'none',
		},
	},
	showRecommendedOnly: true,
	models: [],
	highEndurance: false,
	limitedAvailability: false,
	availablePlatforms: [],
	platforms: [],
	availablePerformanceClasses: [],
	performanceClasses: [],
	architectures: {
		allNVMe: true,
		hybrid: true,
	},
	existings: [],
	stripeWidth: '',
	nodeOutageTolerance: '',
	allowTranscoding: true,
};

const filtersSlice = createSlice({
	name: 'filters',
	initialState,
	reducers: {
		setPlatforms: (state, { payload }: { payload: string[] }) => {
			state.availablePlatforms = sortPlatforms(payload);
			if (state.platforms.length === 0)
				state.platforms = state.availablePlatforms;
		},
		setPerformanceClasses: (state, { payload }: { payload: string[] }) => {
			state.availablePerformanceClasses = payload;
			if (state.performanceClasses.length === 0)
				state.performanceClasses = state.availablePerformanceClasses;
			return state;
		},
		setFilters: (state, { payload }: { payload: FiltersSliceState }) => {
			payload.platforms = sortPlatforms(payload.platforms);
			return payload;
		},
		setModels: (state, { payload }: { payload: Model[] }) => {
			state.models = payload.map((item) =>
				Object.assign({ selected: true }, item),
			);
			return state;
		},
		toggleModel: (state, { payload }: { payload: FiltersModel }) => {
			const objectIndex = state.models.findIndex(
				(i) => i.modelUid === payload.modelUid,
			);
			if (objectIndex >= 0)
				state.models[objectIndex].selected =
					!state.models[objectIndex].selected;
			return state;
		},
		unselectModel: (state, { payload }: { payload: string }) => {
			const objectIndex = state.models.findIndex((i) => i.modelUid === payload);
			if (objectIndex >= 0) state.models[objectIndex].selected = false;
			return state;
		},
		selectOnly: (state, { payload }: { payload: string[] }) => {
			const filtered = state.models.filter((item) =>
				payload.includes(item.modelUid),
			);
			state.models.forEach((element) => {
				if (!filtered.includes(element)) element.selected = false;
			});
			return state;
		},
		selectAll: (state) => {
			state.models.forEach((element) => {
				element.selected = true;
			});
			return state;
		},
		selectNone: (state) => {
			state.models.forEach((element) => {
				element.selected = false;
			});
			return state;
		},
		setAttributes: (state, { payload }: { payload: FiltersAttributes }) => {
			state.attributes = payload;
			return state;
		},
		resetFilters: () => {
			return initialState;
		},
		addExisting: (
			state,
			{ payload }: { payload: { modelUid: string; nodeCount: number, replace?: boolean } },
		) => {
			state.existings.push({
				modelUid: payload.modelUid,
				nodeCount: payload.nodeCount,
				id: uid(),
				replace: payload.replace
			});
			return state;
		},
		editExisting: (state, { payload }: { payload: ExistingItem }) => {
			const index = state.existings.indexOf(
				state.existings.filter((item) => item.id === payload.id)[0],
			);
			if (index >= 0) {
				state.existings[index] = payload;
			}
			return state;
		},
		deleteExisting: (state, { payload }: { payload: { id: string } }) => {
			state.existings = state.existings.filter(
				(item) => item.id !== payload.id,
			);
			return state;
		},
		clearExistings: (state) => {
			state.existings = [];
			return state;
		},
		setStripeWidth: (state, { payload }: { payload: string }) => {
			state.stripeWidth = payload;
			return state;
		},
		clearStripeWidth: (state) => {
			state.stripeWidth = '';
			return state;
		},
		setNodeOutageTolerance: (state, { payload }: { payload: string }) => {
			state.nodeOutageTolerance = payload;
			return state;
		},
		clearNodeOutageTolerance: (state) => {
			state.nodeOutageTolerance = '';
			return state;
		},
		clearFiltersForExisting: (state) => {
			state.attributes.stripeWidth = {
				value: null,
				filter: 'none',
			};
			state.attributes.dataElementsPerStripe = {
				value: null,
				filter: 'none',
			};
			state.attributes.nodeOutageTolerance = {
				value: null,
				filter: 'none',
			};
			return state;
		},
		setShowRecommendedOnly: (state, { payload }: { payload: boolean }) => {
			state.showRecommendedOnly = payload;
		},
		setAllowTranscoding: (state, { payload }: { payload: boolean }) => {
			state.allowTranscoding = payload;
		},
	},
});

export const {
	setPlatforms,
	setPerformanceClasses,
	setFilters,
	setModels,
	setAttributes,
	toggleModel,
	unselectModel,
	selectAll,
	selectNone,
	selectOnly,
	resetFilters,
	addExisting,
	editExisting,
	deleteExisting,
	clearExistings,
	setStripeWidth,
	clearStripeWidth,
	setNodeOutageTolerance,
	clearNodeOutageTolerance,
	clearFiltersForExisting,
	setShowRecommendedOnly,
	setAllowTranscoding,
} = filtersSlice.actions;
export const filtersSelector = (state: RootState) => state.calculator.filters;
export default filtersSlice.reducer;

export function updateFilters(filters: FiltersSliceState) {
	return async (dispatch: AppDispatch, getState: () => RootState) => {
		const modelsSlice = modelsSelector(getState());
		const viewSlice = viewSelector(getState());
		let { models } = modelsSlice;
		const { selection } = viewSlice;

		if (!modelsSlice.errors && !modelsSlice.loading) {
			// Platforms
			models = models.filter((item) =>
				filters.platforms.includes(item.platform),
			);
			// Performance Classes
			models = models.filter((item) =>
				filters.performanceClasses.includes(item.performanceClass),
			);
			// Architectures
			models = models.filter(
				(item) =>
					(item.architecture === 'Hybrid' && filters.architectures.hybrid) ||
					(item.architecture === 'All-NVMe' && filters.architectures.allNVMe),
			);

			models = models.filter(
				(item) => !item.highEndurance || filters.highEndurance,
			);
			models = models.filter(
				(item) => !item.limitedAvailability || filters.limitedAvailability,
			);

			// Checking if any state has been stored in URL
			const query = new URLSearchParams(window.location.search).get(
				'selection',
			);
			const uncheckedURL: string[] = query?.length
				? JSON.parse(atob(query || ''))
				: [];

			// Getting unchecked items
			let unchecked: string[] = [];
			if (filters.models)
				unchecked = filters.models
					.filter((item) => !item.selected)
					.map((item) => item.modelUid);
			const merged = unchecked.concat(uncheckedURL);
			// Adding selected fields
			models = models.map((item) =>
				Object.assign({ selected: !merged.includes(item.modelUid) }, item),
			);
		}
		// Unselect model if not in selection anymore
		if (
			selection.length &&
			!models.find((item) => selection.indexOf(item.modelUid) !== -1)
		)
			dispatch(toggleInSelection(selection[0]));

		dispatch(setFilters(Object.assign(filters, { models })));
	};
}

function sortPlatforms(platforms: string[]) {
	let sortedPlatforms: string[] = [];
	sortedPlatforms = platforms.filter((platform) => platform !== 'qumulo');
	sortedPlatforms.sort();
	if (platforms.includes('qumulo')) {
		sortedPlatforms.splice(0, 0, 'qumulo');
	}
	return sortedPlatforms;
}
