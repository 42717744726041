import React, { FunctionComponent, useMemo } from 'react';
import { formatNumber } from '../utils/conversions';

type RowValueProps = {
	label: string;
	data: string | number | number[] | string[] | undefined;
	unitLabel?: string;
	decimals?: number;
};

const RowValue: FunctionComponent<RowValueProps> = (props) => {
	const { data, unitLabel, decimals, label } = props;

	const memoCell = useMemo(() => {
		return (
			<div className="data-block-data">
				<div className="label">{label}</div>
				<div className="value">
					{typeof data === 'number' ? formatNumber(data, decimals || 0) : data}
					&nbsp;
					{unitLabel}
				</div>
			</div>
		);
	}, [data, unitLabel, decimals, label]);

	return memoCell;
};

export default RowValue;
