import QC1UFront from '../assets/models/QC-1U-FRONT.png';
import QC1URear from '../assets/models/QC-1U-REAR.png';
import QC4UFront from '../assets/models/QC-4U-FRONT.png';
import QC4URear from '../assets/models/QC-4U-REAR.png';
import Q2UHybridFront from '../assets/models/Q-2U-HYBRID-FRONT.png';
import Q2UHybridRear from '../assets/models/Q-2U-HYBRID-REAR.png';
import Q1UHybridFront from '../assets/models/Q-1U-HYBRID-FRONT.png';
import Q1UHybridRear from '../assets/models/Q-1U-HYBRID-REAR.png';
import P2UFront from '../assets/models/P-2U-FRONT.png';
import P2URear from '../assets/models/P-2U-REAR.png';
import HPEApolloFront from '../assets/models/HPE-APOLLO-FRONT.png';
import HPEApolloRear from '../assets/models/HPE-APOLLO-REAR.png';
import HPEProLiantDL325Front from '../assets/models/HPE-PROLIANT-DL325-FRONT.png'
import HPEProLiantDL325Rear from '../assets/models/HPE-PROLIANT-DL325-REAR.png'
import SM1114SFront from '../assets/models/SM-1114S-FRONT.png'
import SM1114SRear from '../assets/models/SM-1114S-REAR.png'

export default function useModelPicture(modelUid: string) {
	switch (modelUid) {
		case 'QM-L2-QC24-24TB':
		case 'QM-L2-QC24-HE-24TB':
		case 'QM-L2-QC40-40TB':
		case 'QM-L2-QC40-HE-40TB':
			return [QC1UFront, QC1URear];

		case 'QM-L2-QC104-104TB':
		case 'QM-L2-QC104-HE-104TB':
		case 'QM-L2-QC208-208TB':
		case 'QM-L2-QC208-HE-208TB':
		case 'QM-L2-QC260-260TB':
		case 'QM-L2-QC260-HE-260TB':
		case 'QM-L2-QC360-360TB':
			return [QC4UFront, QC4URear];

		case 'QM-L3-K-144T-144TB':
		case 'QM-L3-K-144T-HE-144TB':
		case 'QM-L3-K-168T-168TB':
		case 'QM-L3-K-168T-HE-168TB':
		case 'QM-L2-C-72T-72TB':
		case 'QM-L2-C-72T-HE-72TB':
		case 'QM-L2-C-168T-168TB':
		case 'QM-L2-C-168T-HE-168TB':
			return [Q1UHybridFront, Q1UHybridRear];

		case 'QM-L1-P-23T-23TB':
		case 'QM-L1-P-92T-92TB':
		case 'QM-L1-P-184T-184TB':
		case 'QM-L1-P-368T-368TB':
			return [P2UFront, P2URear];

		case 'HP-G9-L2-90TB':
		case 'HP-G9-L2-180TB':
		case 'HP-G9-L3-288TB':
		case 'HP-G10-L2-36TB':
		case 'HP-G10-L2-90TB':
		case 'HP-G10-L2-192TB':
		case 'HP-G10-L3-336TB':
			return [HPEApolloFront, HPEApolloRear];

        case 'QM-L2-C-192T-192TB':
		case 'QM-L2-C-432T-432TB':
		case 'QM-L3-K-432T-432TB':
		case 'QM-L3-K-432T-HE-432TB':
            return [Q2UHybridFront, Q2UHybridRear];

		case 'HP-G10+-L1-34TB':
		case 'HP-G10+-L1-145TB':
		case 'HP-G10+-L1-291TB':
            return [HPEProLiantDL325Front, HPEProLiantDL325Rear];

        case 'SM-1114S-L1-30TB':
        case 'SM-1114S-L1-76TB':
        case 'SM-1114S-L1-153TB':
            return [SM1114SFront, SM1114SRear];

		default:
			return [null, null];
	}
}
