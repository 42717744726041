export const lbsToKg = (value: number) => {
	return value / 2.2046;
};

export const kgToLbs = (value: number) => {
	return value * 2.2046;
};

export const inToCm = (value: number) => {
	return value * 2.54;
};

export const cmToIn = (value: number) => {
	return value / 2.54;
};

export const MBtoMiB = (value: number) => {
	return (value * (1000 * 1000)) / (1024 * 1024);
};

export const MiBtoMB = (value: number) => {
	return (value * (1024 * 1024)) / (1000 * 1000);
};

export const TBtoTiB = (value: number) => {
	return (value * (1000 * 1000 * 1000 * 1000)) / (1024 * 1024 * 1024 * 1024);
};

export const TiBtoTB = (value: number) => {
	return (value * (1024 * 1024 * 1024 * 1024)) / (1000 * 1000 * 1000 * 1000);
};

export const FtoC = (value: number) => {
	return (value - 32) * (5 / 9);
};

export const WtoBTU = (value: number) => {
	return value * 3.412142;
};

export const TBtoGB = (value: number) => {
	return value * 1000;
};

export const TBtoGiB = (value: number) => {
	return TBtoTiB(value) * 1024;
};

export const ftTom = (value: number) => {
	return value * 0.3048;
};

export const toFixedNumber = (
	num: number,
	digits: number,
	base: number,
): number => {
	const pow = Math.pow(base || 10, digits);
	return Math.round(num * pow) / pow;
};

export const formatNumber = (value: number, decimals: number): string => {
	return toFixedNumber(value, decimals || 0, 10).toLocaleString('en-US');
};

export function formatNumberWithUnit(num: number, decimals = 1): string {
	if (num === 0) return '0';

	const k = 1000;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

	const i = Math.floor(Math.log(num) / Math.log(k));

	return (num / Math.pow(k, i)).toFixed(dm) + sizes[i];
}

export const formatCapacity = (value: number) => {
	if (Math.round(value) !== value) return value.toFixed(2);
	return value.toFixed(0);
};

export const indexToMonth = (value: number) => {
	const monthNames = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	return monthNames[value] || '';
};
