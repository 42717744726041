import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	chartsSelector,
	fetchChartsResilienceSelector,
	setDefaultSelectedInfo,
	setResilienceSelectorConfig,
} from '../../../../slices/calculator/charts';
import { LoadingSpinner } from '../../../../components';
import Select, { OptionProps } from '../../../../components/Select';

type SelectorProps = {
	value?: number;
	disabled?: boolean;
	min?: number;
	max?: number;
	fromArray?: Array<number>;
	optTitle: string;
	onClick: (value: string) => void;
};
const Selector = ({
	value,
	disabled,
	min,
	max,
	fromArray,
	optTitle,
	onClick,
}: SelectorProps) => {
	let items: Array<OptionProps> = [];

	if (fromArray !== undefined) {
		items = fromArray.map((value) => ({
			value: value.toString(),
			label: `${value} ${optTitle}`,
		}));
	} else {
		items = Array.from(new Array((max || 0) + 1))
			.map((_, index) => ({
				value: index.toString(),
				label: `${index} ${optTitle}`,
			}))
			.slice(min);
	}

	return (
		<Select
			disabled={disabled}
			placeholder={value ? `${value} ${optTitle}` : 'Select...'}
			value={value ? value.toString() : undefined}
			items={items}
			onClick={onClick}
		/>
	);
};

export type Props = {
	modelUid: string;
	toMaxNodes: boolean;
	nodes: number;
};
const ResilienceBlockSelector = (props: Props) => {
	const dispatch = useDispatch();
	const chartsSlice = useSelector(chartsSelector);
	const selected = chartsSlice.resilience.chart.selected;
	const [nodeInfo, setNodeInfo] = useState<{ min: number; max: number }>({
		min: 0,
		max: 0,
	});
	const [driveProtectInfo, setDriveProtectInfo] = useState<Array<number>>([]);
	const [nodeProtectInfo, setNodeProtectionInfo] = useState<Array<number>>([]);
	const [
		driveProtectInfoPlaceholder,
		setDriveProtectInfoPlaceholder,
	] = useState<Array<number>>([]);
	const [nodeProtectInfoPlaceholder, setNodeProtectInfoPlaceholder] = useState<
		Array<number>
	>([]);

	const [nodeCounter, setNodeCounter] = useState(selected?.node || undefined);
	const [driveProtectCounter, setDriveProtectCounter] = useState(
		selected?.driveProtect || undefined,
	);
	const [nodeProtectCounter, setNodeProtectCounter] = useState(
		selected?.nodeProtect || undefined,
	);

	const fetch = useCallback(
		(node, driveProtect, nodeProtect) => {
			const params = {
				modelUid: props.modelUid,
				toMaxNodes: props.toMaxNodes,
				infos: [
					{
						nodes: node,
						driveProtect: driveProtect,
						nodeProtect: nodeProtect,
					},
				],
			};
			dispatch(fetchChartsResilienceSelector(params));
		},
		[dispatch, props.modelUid, props.toMaxNodes],
	);

	useEffect(() => {
		if (!nodeCounter && !driveProtectCounter && !nodeProtectCounter) {
			fetch(props.nodes, undefined, undefined);
		}
	}, [
		driveProtectCounter,
		fetch,
		nodeCounter,
		nodeProtectCounter,
		props.nodes,
	]);

	useEffect(() => {
		const {
			loading,
			errors,
			details,
			data,
			info,
		} = chartsSlice.resilience.selector;
		if (!loading && !errors && details) {
			setNodeInfo({
				min: info.minNodes,
				max: info.maxNodes,
			});

			setDriveProtectInfo(details.driveProtect);

			if (
				nodeCounter &&
				driveProtectCounter &&
				details.driveProtect.length > 1 &&
				details.driveProtect.includes(driveProtectCounter)
			) {
				fetch(
					nodeCounter,
					driveProtectCounter,
					details.nodeProtect &&
						nodeProtectCounter &&
						details.nodeProtect.includes(nodeProtectCounter)
						? nodeProtectCounter
						: undefined,
				);
			}

			if (
				details.driveProtect.length === 1 &&
				nodeCounter &&
				!driveProtectCounter
			) {
				setDriveProtectCounter(details.driveProtect[0]);
				fetch(
					nodeCounter,
					details.driveProtect[0],
					details.nodeProtect &&
						nodeProtectCounter &&
						details.nodeProtect.includes(nodeProtectCounter)
						? nodeProtectCounter
						: undefined,
				);
			}

			setNodeProtectionInfo(details.nodeProtect);

			if (
				details.nodeProtect.length === 1 &&
				nodeCounter &&
				!nodeProtectCounter
			) {
				setNodeProtectCounter(details.nodeProtect[0]);
				if (driveProtectCounter) {
					fetch(nodeCounter, driveProtectCounter, details.nodeProtect[0]);
				}
			}

			// set placeholders
			if (data) {
				if (
					nodeCounter &&
					data.nodeProtect === undefined &&
					data.driveProtect === undefined
				) {
					setDriveProtectInfoPlaceholder(details.driveProtect);
				}
				if (
					nodeCounter &&
					data.driveProtect &&
					data.nodeProtect === undefined
				) {
					setNodeProtectInfoPlaceholder(details.nodeProtect);
				}
			}
		}
	}, [
		chartsSlice.resilience.selector,
		driveProtectCounter,
		fetch,
		nodeCounter,
		nodeProtectCounter,
	]);

	// invalidate fields if counter is not in counterInfo
	useEffect(() => {
		if (
			driveProtectCounter &&
			!driveProtectInfo.includes(driveProtectCounter)
		) {
			setDriveProtectCounter(undefined);
		}
		if (nodeProtectCounter && !nodeProtectInfo.includes(nodeProtectCounter)) {
			setNodeProtectCounter(undefined);
		}
	}, [
		driveProtectCounter,
		driveProtectInfo,
		nodeProtectCounter,
		nodeProtectInfo,
	]);

	const reset = useCallback(
		(hard = true) => {
			setNodeInfo({ min: 0, max: 0 });
			setNodeProtectionInfo([]);
			setDriveProtectInfo([]);
			setNodeCounter(undefined);
			setDriveProtectCounter(undefined);
			setNodeProtectCounter(undefined);
			if (hard) {
				dispatch(setResilienceSelectorConfig(undefined));
			}
		},
		[dispatch],
	);

	const confirm = useCallback(
		(node: number, driveProtect: number, nodeProtect: number) => {
			dispatch(
				setResilienceSelectorConfig({
					node: node,
					driveProtect: driveProtect,
					nodeProtect: nodeProtect,
				}),
			);
		},
		[dispatch],
	);

	useEffect(() => {
		if (
			nodeCounter &&
			(driveProtectCounter || driveProtectInfo.length > 0) &&
			(nodeProtectCounter || nodeProtectInfo.length > 0)
		) {
			confirm(
				nodeCounter,
				driveProtectCounter || driveProtectInfo[0],
				nodeProtectCounter || nodeProtectInfo[0],
			);
		}
	}, [
		confirm,
		dispatch,
		driveProtectCounter,
		driveProtectInfo,
		nodeCounter,
		nodeProtectCounter,
		nodeProtectInfo,
	]);

	const isFilled = (): boolean => {
		return !!nodeCounter && !!driveProtectCounter && !!nodeProtectCounter;
	};

	return (
		<div className="resilience-block-selector">
			{chartsSlice.resilience.selector.loading ? (
				<LoadingSpinner centered small />
			) : (
				<>
					<Selector
						value={nodeCounter}
						onClick={(value) => {
							setNodeCounter(parseInt(value));
							fetch(parseInt(value), undefined, undefined);
						}}
						min={nodeInfo.min}
						max={nodeInfo.max}
						optTitle={'Node'}
					/>
					<div>w/</div>
					<Selector
						disabled={!nodeCounter}
						value={driveProtectCounter}
						onClick={(value) => {
							setDriveProtectCounter(parseInt(value));
							fetch(nodeCounter, parseInt(value), undefined);
						}}
						fromArray={
							isFilled() ? driveProtectInfoPlaceholder : driveProtectInfo
						}
						optTitle={'Drive Protection'}
					/>
					<div>&</div>
					<Selector
						disabled={!nodeCounter}
						value={nodeProtectCounter}
						onClick={(value) => {
							setNodeProtectCounter(parseInt(value));
							fetch(nodeCounter, driveProtectCounter, parseInt(value));
						}}
						fromArray={
							isFilled() ? nodeProtectInfoPlaceholder : nodeProtectInfo
						}
						optTitle={'Node Protection'}
					/>
					<i
						onClick={reset}
						className="fas fa-xs fa-times-circle clear-button"
						style={{ marginLeft: 0 }}
					/>
					<button
						className="btn btn-primary btn-confirm"
						style={{ marginLeft: '8px' }}
						disabled={
							!nodeCounter || !driveProtectCounter || !nodeProtectCounter
						}
						onClick={() => {
							if (nodeCounter && driveProtectCounter && nodeProtectCounter) {
								dispatch(
									setDefaultSelectedInfo({
										node: nodeCounter,
										driveProtect: driveProtectCounter,
										nodeProtect: nodeProtectCounter,
									}),
								);
								reset(false);
							}
						}}
					>
						Choose
					</button>
				</>
			)}
		</div>
	);
};

export default ResilienceBlockSelector;
