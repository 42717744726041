import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
	globalSelector,
	setMeasurements,
	setDataUnits,
	setCurrency,
} from '../slices/global';

import grumpquat from '../assets/grump.png';
import currencies from '../utils/currencies.json';

type GlobalSettingsModalProps = {
	show: boolean;
	handleClose: () => void;
};

const ModalTitle = styled.h5`
	margin: 0px;
	font-size: 16px;
`;

const Title = styled.h5`
	margin: 15px 0px;
	font-size: 16px;
`;

const GlobalSettingWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
`;

const GlobalSettingsLabel = styled.label`
	margin-bottom: 0px;
	font-weight: 200;
`;

const GlobalSettingsSelect = styled.select`
	width: 120px;
	text-align: left;
	border: none;
`;

const Divider = styled.hr`
	margin-left: -1rem;
	margin-right: -1rem;
`;

const Grumpquat = styled.img`
	height: 100px;
	position: absolute;
	bottom: 80px;
	right: 20px;
`;

const GlobalSettingsModal = (props: GlobalSettingsModalProps) => {
	const { show, handleClose } = props;
	const dispatch = useDispatch();
	const global = useSelector(globalSelector);
	const { measurements, dataUnits, currency } = global;

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		if (e) {
			const name = e.target?.name;
			const value = e.target?.value;

			if (
				name === 'measurements' &&
				(value === 'metric' || value === 'standard')
			)
				dispatch(setMeasurements(value));
			if (name === 'dataUnits' && (value === 'base2' || value === 'base10'))
				dispatch(setDataUnits(value));
			if (name === 'currency') dispatch(setCurrency(value));
		}
	};

	const currenciesOptions = (() => {
		const res: JSX.Element[] = [];
		for (const key in currencies) {
			if (Object.prototype.hasOwnProperty.call(currencies, key)) {
				// @ts-ignore
				const element = currencies[key];
				if (element.code && element.symbol) {
					res.push(
						<option value={element.code} key={element.code}>
							{element.symbol} - {element.name}
						</option>,
					);
				}
			}
		}
		return res;
	})();

	return (
		<Modal
			show={show}
			dialogClassName="global-settings-modal"
			onHide={handleClose}
		>
			<Modal.Header className="no-border" closeButton>
				<ModalTitle>Preferences</ModalTitle>
			</Modal.Header>
			<Modal.Body>
				<GlobalSettingWrapper>
					<GlobalSettingsLabel className="global-settings-label">
						Measurements
					</GlobalSettingsLabel>
					<GlobalSettingsSelect
						name="measurements"
						value={measurements}
						className="global-settings-select"
						onChange={handleChange}
					>
						<option value="standard">Imperial</option>
						<option value="metric">Metric</option>
					</GlobalSettingsSelect>
				</GlobalSettingWrapper>
				<GlobalSettingWrapper>
					<GlobalSettingsLabel className="global-settings-label">
						Data Units
					</GlobalSettingsLabel>
					<GlobalSettingsSelect
						name="dataUnits"
						value={dataUnits}
						className="global-settings-select"
						onChange={handleChange}
					>
						<option value="base10">Base 10</option>
						<option value="base2">Base 2</option>
					</GlobalSettingsSelect>
				</GlobalSettingWrapper>

				{currencies && (
					<GlobalSettingWrapper>
						<GlobalSettingsLabel className="global-settings-label">
							Currency
						</GlobalSettingsLabel>
						<GlobalSettingsSelect
							name="currency"
							value={currency}
							className="global-settings-select"
							onChange={handleChange}
						>
							{currenciesOptions}
						</GlobalSettingsSelect>
					</GlobalSettingWrapper>
				)}

				<Divider />
				<Title>About</Title>
				<Grumpquat src={grumpquat} />
				<GlobalSettingWrapper>
					<GlobalSettingsLabel className="global-settings-label">
						Version {process.env.REACT_APP_SOURCE_VERSION?.substring(0, 7)}
					</GlobalSettingsLabel>
				</GlobalSettingWrapper>
				<GlobalSettingWrapper>
					<GlobalSettingsLabel className="global-settings-label modal-credit">
						Brought to you by:
						<br />
						Ted Carpenter,
						<br />
						David Sniderman,
						<br />
						Jason Sturgeon, Kylie Jack,
						<br />
						and our friends at TrackIt
					</GlobalSettingsLabel>
				</GlobalSettingWrapper>
			</Modal.Body>
		</Modal>
	);
};

export default GlobalSettingsModal;
