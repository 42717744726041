import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { OverlayTrigger, Popover } from 'react-bootstrap';

// Hooks
import {
	useWindowSize,
	useURLFilters,
	usePrevious,
	useModelsProperties,
} from '../../hooks';

// Components
import {
	AttributesBlock,
	ConditionalTableItem,
	LoadingSpinner,
	AddedTableAttribute,
} from '../../components';
import { TableAttribute, EncodingTableAttribute } from '../../components/forms';
import TableColumn from './TableColumn';
import ExistingColumn from './ExistingColumn';

// Slices
import {
	filtersSelector,
	setAttributes,
} from '../../slices/calculator/filters';
import { dataSelector, fetchClusters } from '../../slices/calculator/data';
import {
	dataExistingSelector,
	fetchExisting,
} from '../../slices/calculator/dataExisting';
import {
	viewSelector,
	setTableMode,
	toggleAttributes,
	toggleInSelection,
} from '../../slices/calculator/view';
import { modelsSelector } from '../../slices/calculator/models';
import { globalSelector } from '../../slices/global';

// Types
import { FilterAttribute } from '../../types';

// Assets
import menuIcon from '../../assets/menu-icon.svg';
import menuIconBlue from '../../assets/menu-icon-blue.svg';
import switchesIcon from '../../assets/switches-icon.svg';
import switchesIconBlue from '../../assets/switches-icon-blue.svg';
import strings from '../../utils/strings.json';

export type StyledTableProps = {
	folded: boolean;
};

const TableWrapper = styled.div`
	display: flex;
	overflow-x: hidden;
	display: flex;
	flex-grow: 1;
	position: relative;
`;

const Table = styled.div`
	overflow-x: scroll;
	display: flex;
	flex-grow: 0;
	position: relative;
	background-color: #f3f3f3;
	scroll-snap-type: x mandatory;
	${(props: StyledTableProps) => {
		if (props.folded)
			return css`
				scroll-padding-left: 280px;
			`;
		else
			return css`
				scroll-padding-left: 490px;
			`;
	}}
`;

const FiltersColumn = styled.div`
	flex-shrink: 0;
	position: sticky;
	left: 0px;
	z-index: 998;
`;

const FiltersColumnHeader = styled.div`
	position: sticky;
	top: 0px;
	width: 100%;
	z-index: 99999;
	padding: 10px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const FiltersColumnHeaderTitle = styled.h5`
	font-size: 20px;
	margin: 0px;
`;

const FiltersColumnHeaderRigthBlockWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const NoResultsText = styled.h5`
	text-align: center;
	flex-grow: 1;
	align-self: center;
`;

const ActionIcon = styled.img`
	cursor: pointer;
	margin: 8px;
`;

const TableComponent = () => {
	const filters = useSelector(filtersSelector);
	const data = useSelector(dataSelector);
	const dataExisting = useSelector(dataExistingSelector);
	const viewSlice = useSelector(viewSelector);
	const modelsSlice = useSelector(modelsSelector);
	const globalSlice = useSelector(globalSelector);
	const {
		mode,
		tableMode,
		showCategory,
		showAttributes,
		isInAnimation,
		showFilters,
	} = viewSlice;
	const tableData = mode === 'new' ? data : dataExisting;
	const summary = tableMode === 'summary';
	const dispatch = useDispatch();
	const { attributes } = filters;
	const { encodeFilters, encodeRows, pushHistory } = useURLFilters();
	const previousAttributes = usePrevious(filters.attributes);
	const modelProperties = useModelsProperties().getAllProperties();
	const existingProperties = useModelsProperties().getExistingProperties();
	// These will be used to catch window resize events
	const [width, height] = useWindowSize();
	const [fullRadiusTable, setFullRadiusTable] = useState(false);
	/*
	 ** Defining a local state to handle the table dimensions
	 ** We use the width and height vars as dependencies in useEffect to catch window resize events
	 */
	const [tableWidth, setTableWidth] = useState(0);

	// We set tableHeight at null at init time because we can't calculate the height before the DOM is loaded
	const [tableHeight, setTableHeight] = useState<number | null>(null);

	// Is model selected utility function
	const isModelSelected = useCallback(
		(modelUid: string) => {
			const res = filters.models.filter((i) => i.modelUid === modelUid);
			if (res.length) return res[0].selected;
			return null;
		},
		[filters.models],
	);

	/*
	 ** For the sticky column header and the left sticky filters to work we need to set fixed dimension for the table
	 ** These methods will calculate the right dimensions for the table based on the Calculator element and the left filter panel
	 ** We cannot base these dimensions on the immediate parent "Table panel" element as the parent width will depend on the table width
	 ** and that would end up in a race condition between the Table render and its parent render
	 */
	const getTableWidthFromDOM = useCallback((): number | null => {
		const calculatorElement = document.getElementById('calculator');
		if (calculatorElement) {
			// Since we use border-box box sizing we have to manually substract the padding to get the inner width of the element
			const calculatorStyle = window.getComputedStyle(calculatorElement, null);
			const calculatorWidth: number =
				calculatorElement.clientWidth -
				(parseFloat(calculatorStyle.paddingLeft) +
					parseFloat(calculatorStyle.paddingRight));
			const filtersPanelWidth: number =
				document.getElementById('filters-panel')?.offsetWidth || 40;
			const filtersColumnWidth: number =
				document.getElementById('filters-column')?.offsetWidth || 489;
			const existingColumnWidth: number =
				document.getElementById('existing-column')?.offsetWidth || 0;

			// No models are displayed
			if (
				(filters.models.length &&
					!filters.models.filter((i) => i.selected === true).length) ||
				!tableData.clusters.length
			) {
				setFullRadiusTable(true);
				return filtersColumnWidth + existingColumnWidth;
			}

			// Getting columns total width to calculate actual table size
			const columns = document.getElementsByClassName('table-column');
			let columnsWidth = 0;
			for (let i = 0; i < columns.length; i++) {
				const item = columns[i] as HTMLElement;
				columnsWidth += item.offsetWidth;
			}

			// Removing 20 because the table is also padded by 20px
			const fullWidthTable = calculatorWidth - 20;
			const filtersPaddedTable = calculatorWidth - filtersPanelWidth - 20;
			const actualTableSize = filtersColumnWidth + columnsWidth;

			if (
				actualTableSize < (width > 1200 ? filtersPaddedTable : fullWidthTable)
			) {
				setFullRadiusTable(true);
				return actualTableSize;
			}
			setFullRadiusTable(false);
			return filtersPaddedTable;
		}
		return null;
	}, [width, tableData.clusters.length, filters.models]);

	useEffect(() => {
		setTableWidth(getTableWidthFromDOM() || 0);
	}, [getTableWidthFromDOM, viewSlice.showAttributes]);

	const getTableHeightFromDOM = (): number | null => {
		// Using the Filter columns as the reference element
		const filtersColumnElement = document.getElementById('filters-column');

		if (filtersColumnElement && filtersColumnElement.children.length) {
			let res = 0;
			Array.from(filtersColumnElement.children).forEach((item) => {
				if (item instanceof HTMLElement) {
					res += item.offsetHeight;
				}
			});
			return res;
		}
		return null;
	};

	const [debouncedFetch] = useDebouncedCallback(() => {
		viewSlice.mode === 'existing'
			? dispatch(fetchExisting(filters.showRecommendedOnly, filters.allowTranscoding))
			: dispatch(fetchClusters());
	}, 1000);

	// Initial data fetch
	useEffect(() => {
		if (!modelsSlice.loading && !modelsSlice.errors) {
			//dispatch(fetchClusters());
			debouncedFetch();
		}
	}, [modelsSlice, dispatch, debouncedFetch]);

	// Fetching on location change
	useEffect(() => {
		if (
			JSON.stringify(previousAttributes) !== JSON.stringify(filters.attributes)
		) {
			debouncedFetch();
		}
	}, [filters.attributes, debouncedFetch, previousAttributes]);

	// Fetching on Global Setings change
	useEffect(() => {
		debouncedFetch();
	}, [globalSlice.measurements, globalSlice.dataUnits, debouncedFetch]);

	// Changing width on different events (columns change, window resize, ...)
	useEffect(() => {
		const calculatedWidth = getTableWidthFromDOM();
		if (calculatedWidth) setTableWidth(calculatedWidth);
	}, [
		width,
		height,
		getTableWidthFromDOM,
		filters.models,
		tableData.clusters,
		tableData.loading,
		dataExisting.existing,
		showFilters,
	]);

	// On "Summary / All" switch  and on Category fold
	useEffect(() => {
		if (!isInAnimation) setTableHeight(getTableHeightFromDOM());
	}, [summary, showCategory, isInAnimation, mode]);

	const handleChange = (name: string, value: FilterAttribute) => {
		debouncedFetch();
		const newAttributes = Object.assign({}, attributes, { [name]: value });
		pushHistory(
			encodeFilters(Object.assign({}, filters, { attributes: newAttributes })),
			'filters',
		);
		dispatch(setAttributes(newAttributes));
	};

	const handleEncodingChange = (newValue: string | null) => {
		let newAttributes = null;
		if (newValue) {
			const stripeWidth = newValue.split(',')[0];
			const dataPerStripe = newValue.split(',')[1] || null;

			if (stripeWidth && dataPerStripe) {
				newAttributes = Object.assign({}, attributes, {
					stripeWidth: { value: parseInt(stripeWidth), filter: 'equal' },
					dataElementsPerStripe: {
						value: parseInt(dataPerStripe),
						filter: 'equal',
					},
				});
			}
		} else {
			newAttributes = Object.assign({}, attributes, {
				stripeWidth: { value: null, filter: 'none' },
				dataElementsPerStripe: {
					value: null,
					filter: 'none',
				},
			});
		}

		if (newAttributes) {
			debouncedFetch();
			pushHistory(
				encodeFilters(
					Object.assign({}, filters, { attributes: newAttributes }),
				),
				'filters',
			);
			dispatch(setAttributes(newAttributes));
		}
	};

	// Use in case of text-based attribute
	// const handleStringChange = (
	// 	name: string,
	// 	value: PerformanceClassAttribute,
	// ) => {
	// 	debouncedFetch();
	// 	const newAttributes = Object.assign({}, attributes, { [name]: value });
	// 	pushHistory(
	// 		encodeFilters(Object.assign({}, filters, { attributes: newAttributes })),
	// 		'filters',
	// 	);
	// 	dispatch(setAttributes(newAttributes));
	// };

	// Select model if only one left + Selection behavior fixes
	useEffect(() => {
		let count: (string | undefined)[] = tableData.clusters.map((item) => {
			if (isModelSelected(item.modelUid)) return item.modelUid;
			return undefined;
		});
		count = count.filter((item) => !!item);
		if (
			count.length > 1 &&
			viewSlice.selection.length &&
			!count.find((item) => item === viewSlice.selection[0])?.length
		)
			dispatch(toggleInSelection(viewSlice.selection[0]));
		if (
			count.length === 1 &&
			viewSlice.selection.length &&
			viewSlice.selection[0] !== count[0]
		)
			dispatch(toggleInSelection(viewSlice.selection[0]));

		if (count[0] && count.length === 1 && viewSlice.selection[0] !== count[0])
			dispatch(toggleInSelection(count[0]));
	}, [tableData.clusters, dispatch, isModelSelected, viewSlice.selection]);

	const renderColumns = () => {
		if (tableData.loading) return 'Loading ...';
		if (tableData.errors) return 'Errors';

		return tableData.clusters.map((item) => {
			if (isModelSelected(item.modelUid))
				return <TableColumn cluster={item} key={item.modelUid} />;
			return null;
		});
	};

	const handleAllRowChange = () => {
		const res: string = tableMode === 'summary' ? 'all' : 'summary';
		dispatch(setTableMode(res));
		pushHistory(encodeRows(res), 'rows');
	};

	const filtersColumnWidth = viewSlice.showAttributes ? '490px' : '280px';

	return (
		<TableWrapper>
			<Table
				className={`main-table ${fullRadiusTable ? 'full-radius' : ''}`}
				id="main-table"
				folded={!showAttributes}
				style={{
					width: `${tableWidth}px`,
				}}
			>
				<FiltersColumn
					id="filters-column"
					className="filters-column"
					style={{
						width: filtersColumnWidth,
						height:
							tableHeight ||
							document.getElementById('filters-column')?.scrollHeight,
					}}
				>
					<FiltersColumnHeader className="filters-column-header">
						<FiltersColumnHeaderTitle>Results</FiltersColumnHeaderTitle>
						<FiltersColumnHeaderRigthBlockWrapper>
							{/* <Switch
										onChange={(value) => {
											value
												? dispatch(selectOnly(selection))
												: dispatch(selectAll());
										}}
										checked={onlySelection}
										label={'Selection only'}
										id="selection-only-switch"
									/> */}
							<OverlayTrigger
								placement={'top'}
								overlay={
									<Popover id="controls-toggle-tooltip">
										<Popover.Content>
											{tableMode === 'summary'
												? 'Summarizing rows.'
												: 'Showing all rows.'}
											<br />
											{tableMode === 'summary'
												? 'Click to show all rows.'
												: 'Click to show summary rows.'}
										</Popover.Content>
									</Popover>
								}
							>
								<ActionIcon
									src={tableMode === 'summary' ? menuIcon : menuIconBlue}
									alt="Toggle All Rows"
									onClick={() => handleAllRowChange()}
									id="summary-rows-switch"
								/>
							</OverlayTrigger>
							<OverlayTrigger
								placement={'top'}
								overlay={
									<Popover id="controls-toggle-tooltip">
										<Popover.Content>
											{showAttributes
												? 'Showing controls.'
												: 'Hiding controls.'}
											<br />
											{showAttributes
												? 'Click to hide controls.'
												: 'Click to show controls.'}
										</Popover.Content>
									</Popover>
								}
							>
								<ActionIcon
									className="attributes-toggle-button"
									src={!showAttributes ? switchesIcon : switchesIconBlue}
									alt="Toggle Controls"
									onClick={() => dispatch(toggleAttributes())}
									id="summary-rows-switch"
								/>
							</OverlayTrigger>

							{/* <Switch
										onChange={(value) => handleAllRowChange()}
										checked={tableMode !== 'summary'}
										label={'Show all Rows'}
										id="summary-rows-switch"
									/> */}
						</FiltersColumnHeaderRigthBlockWrapper>
					</FiltersColumnHeader>
					<AttributesBlock
						title="Capacity"
						category="capacity"
						titleTooltip={strings.categoryTooltips.capacity}
					>
						<TableAttribute
							showForm={viewSlice.showAttributes}
							label={modelProperties['usableCapacity'].formattedLabel}
							name="usableCapacity"
							value={attributes.usableCapacity.value}
							filter={attributes.usableCapacity.filter}
							onChange={handleChange}
						/>
						{mode === 'existing' && (
							<AddedTableAttribute
								label={
									existingProperties['usableCapacityAdded']?.formattedLabel ||
									''
								}
							/>
						)}
						<EncodingTableAttribute
							showForm={viewSlice.showAttributes}
							label={modelProperties['encoding'].formattedLabel}
							name="encoding"
							value={
								attributes.stripeWidth.value &&
								attributes.dataElementsPerStripe.value
									? `${attributes.stripeWidth.value},${attributes.dataElementsPerStripe.value}`
									: ''
							}
							onChange={handleEncodingChange}
							disabled={mode === 'existing' && !filters.allowTranscoding}
						/>
						<ConditionalTableItem
							show={!summary || !!attributes['capacity'].value}
							forcedDisplay={summary && !!attributes['capacity'].value}
						>
							<TableAttribute
								showForm={viewSlice.showAttributes}
								label={modelProperties['capacity'].formattedLabel}
								name="capacity"
								value={attributes.capacity.value}
								filter={attributes.capacity.filter}
								onChange={handleChange}
							/>
						</ConditionalTableItem>
						<TableAttribute
							showForm={viewSlice.showAttributes}
							label={modelProperties['capacityScalesTo'].formattedLabel}
							name="capacityScalesTo"
							value={attributes.capacityScalesTo.value}
							filter={attributes.capacityScalesTo.filter}
							onChange={handleChange}
						/>
						<ConditionalTableItem
							show={!summary || !!attributes['efficiency'].value}
							forcedDisplay={summary && !!attributes['efficiency'].value}
						>
							<TableAttribute
								showForm={viewSlice.showAttributes}
								label={modelProperties['efficiency'].formattedLabel}
								name="efficiency"
								value={attributes.efficiency.value}
								filter={attributes.efficiency.filter}
								onChange={handleChange}
							/>
						</ConditionalTableItem>
					</AttributesBlock>
					<AttributesBlock
						title="Nodes"
						category="nodes"
						titleTooltip={strings.categoryTooltips.nodes}
					>
						<TableAttribute
							showForm={viewSlice.showAttributes}
							label={modelProperties['nodeCount'].formattedLabel}
							name="nodeCount"
							value={attributes.nodeCount.value}
							filter={attributes.nodeCount.filter}
							onChange={handleChange}
						/>
						{mode === 'existing' && (
							<AddedTableAttribute
								label={existingProperties['nodeAdded']?.formattedLabel || ''}
							/>
						)}
						{/*<TableAttribute*/}
						{/*	showForm={viewSlice.showAttributes}*/}
						{/*	label={*/}
						{/*		mode === 'existing'*/}
						{/*			? modelProperties['encodingScalesTo'].formattedLabel*/}
						{/*			: modelProperties['nodeCountScalesTo'].formattedLabel*/}
						{/*	}*/}
						{/*	name="nodeCountScalesTo"*/}
						{/*	value={attributes.nodeCountScalesTo.value}*/}
						{/*	filter={attributes.nodeCountScalesTo.filter}*/}
						{/*	onChange={handleChange}*/}
						{/*/>*/}
					</AttributesBlock>
					<AttributesBlock
						title="Performance Class"
						category="cost"
						unfoldable={true}
						titleTooltip={strings.categoryTooltips.performanceClass}
					></AttributesBlock>
					<AttributesBlock
						title="Performance"
						category="performance"
						titleTooltip={strings.categoryTooltips.performance}
					>
						<TableAttribute
							showForm={viewSlice.showAttributes}
							label={modelProperties['burstWrite'].formattedLabel}
							name="burstWrite"
							value={attributes.burstWrite.value}
							filter={attributes.burstWrite.filter}
							onChange={handleChange}
						/>
						<TableAttribute
							showForm={viewSlice.showAttributes}
							label={modelProperties['cachedRead'].formattedLabel}
							name="cachedRead"
							value={attributes.cachedRead.value}
							filter={attributes.cachedRead.filter}
							onChange={handleChange}
						/>
						<TableAttribute
							showForm={viewSlice.showAttributes}
							label={modelProperties['iops'].formattedLabel}
							name="iops"
							value={attributes.iops.value}
							filter={attributes.iops.filter}
							onChange={handleChange}
						/>
						<ConditionalTableItem
							show={!summary || !!attributes['sustainedWrite'].value}
							forcedDisplay={summary && !!attributes['sustainedWrite'].value}
						>
							<TableAttribute
								showForm={viewSlice.showAttributes}
								label={modelProperties['sustainedWrite'].formattedLabel}
								name="sustainedWrite"
								value={attributes.sustainedWrite.value}
								filter={attributes.sustainedWrite.filter}
								onChange={handleChange}
							/>
						</ConditionalTableItem>
						<ConditionalTableItem
							show={!summary || !!attributes['singleStreamWrite'].value}
							forcedDisplay={summary && !!attributes['singleStreamWrite'].value}
						>
							<TableAttribute
								showForm={viewSlice.showAttributes}
								label={modelProperties['singleStreamWrite'].formattedLabel}
								name="singleStreamWrite"
								value={attributes.singleStreamWrite.value}
								filter={attributes.singleStreamWrite.filter}
								onChange={handleChange}
							/>
						</ConditionalTableItem>
						<ConditionalTableItem
							show={!summary || !!attributes['uncachedRead'].value}
							forcedDisplay={summary && !!attributes['uncachedRead'].value}
						>
							<TableAttribute
								showForm={viewSlice.showAttributes}
								label={modelProperties['uncachedRead'].formattedLabel}
								name="uncachedRead"
								value={attributes.uncachedRead.value}
								filter={attributes.uncachedRead.filter}
								onChange={handleChange}
							/>
						</ConditionalTableItem>
						<ConditionalTableItem
							show={!summary || !!attributes['singleStreamCachedRead'].value}
							forcedDisplay={
								summary && !!attributes['singleStreamCachedRead'].value
							}
						>
							<TableAttribute
								showForm={viewSlice.showAttributes}
								label={modelProperties['singleStreamCachedRead'].formattedLabel}
								name="singleStreamCachedRead"
								value={attributes.singleStreamCachedRead.value}
								filter={attributes.singleStreamCachedRead.filter}
								onChange={handleChange}
							/>
						</ConditionalTableItem>
						<ConditionalTableItem
							show={!summary || !!attributes['singleStreamUncachedRead'].value}
							forcedDisplay={
								summary && !!attributes['singleStreamUncachedRead'].value
							}
						>
							<TableAttribute
								showForm={viewSlice.showAttributes}
								label={
									modelProperties['singleStreamUncachedRead'].formattedLabel
								}
								name="singleStreamUncachedRead"
								value={attributes.singleStreamUncachedRead.value}
								filter={attributes.singleStreamUncachedRead.filter}
								onChange={handleChange}
							/>
						</ConditionalTableItem>
					</AttributesBlock>
					<AttributesBlock
						title="Data Protection"
						category="dataProtection"
						titleTooltip={strings.categoryTooltips.dataProtection}
					>
						<TableAttribute
							showForm={viewSlice.showAttributes}
							label={modelProperties['driveOutageTolerance'].formattedLabel}
							name="driveOutageTolerance"
							value={attributes.driveOutageTolerance.value}
							filter={attributes.driveOutageTolerance.filter}
							onChange={handleChange}
						/>
						<TableAttribute
							showForm={viewSlice.showAttributes}
							label={modelProperties['nodeOutageTolerance'].formattedLabel}
							name="nodeOutageTolerance"
							value={attributes.nodeOutageTolerance.value}
							filter={attributes.nodeOutageTolerance.filter}
							onChange={handleChange}
							disabled={mode === 'existing' && !filters.allowTranscoding}
						/>
						{/* 						<ConditionalTableItem
							show={!summary || !!attributes['stripeWidth'].value}
							forcedDisplay={summary && !!attributes['stripeWidth'].value}
						>
							<TableAttribute
								showForm={viewSlice.showAttributes}
								label={modelProperties['stripeWidth'].formattedLabel}
								name="stripeWidth"
								value={attributes.stripeWidth.value}
								filter={attributes.stripeWidth.filter}
								onChange={handleChange}
							/>
						</ConditionalTableItem>
						<ConditionalTableItem
							show={!summary || !!attributes['dataElementsPerStripe'].value}
							forcedDisplay={
								summary && !!attributes['dataElementsPerStripe'].value
							}
						>
							<TableAttribute
								showForm={viewSlice.showAttributes}
								label={modelProperties['dataElementsPerStripe'].formattedLabel}
								name="dataElementsPerStripe"
								value={attributes.dataElementsPerStripe.value}
								filter={attributes.dataElementsPerStripe.filter}
								onChange={handleChange}
							/>
						</ConditionalTableItem>
 						*/}
					</AttributesBlock>
					<AttributesBlock
						title="Networking"
						category="networking"
						titleTooltip={strings.categoryTooltips.networking}
					>
						<TableAttribute
							showForm={viewSlice.showAttributes}
							label={modelProperties['frontEndPorts'].formattedLabel}
							name="frontEndPorts"
							value={attributes.frontEndPorts.value}
							filter={attributes.frontEndPorts.filter}
							onChange={handleChange}
						/>
						{mode === 'existing' && (
							<AddedTableAttribute
								label={
									existingProperties['frontEndPortsAdded']?.formattedLabel || ''
								}
							/>
						)}
						<TableAttribute
							showForm={viewSlice.showAttributes}
							label={modelProperties['backEndPorts'].formattedLabel}
							name="backEndPorts"
							value={attributes.backEndPorts.value}
							filter={attributes.backEndPorts.filter}
							onChange={handleChange}
						/>
						{mode === 'existing' && (
							<AddedTableAttribute
								label={
									existingProperties['backEndPortsAdded']?.formattedLabel || ''
								}
							/>
						)}
						<TableAttribute
							showForm={viewSlice.showAttributes}
							label={modelProperties['frontEndNetworking'].formattedLabel}
							name="frontEndNetworking"
							value={attributes.frontEndNetworking.value}
							filter={attributes.frontEndNetworking.filter}
							onChange={handleChange}
						/>
						<TableAttribute
							showForm={viewSlice.showAttributes}
							label={modelProperties['backEndNetworking'].formattedLabel}
							name="backEndNetworking"
							value={attributes.backEndNetworking.value}
							filter={attributes.backEndNetworking.filter}
							onChange={handleChange}
						/>
					</AttributesBlock>
					<AttributesBlock
						title="Endurance"
						category="endurance"
						titleTooltip={strings.categoryTooltips.endurance}
					>
						<TableAttribute
							showForm={viewSlice.showAttributes}
							label={modelProperties['writeVolume'].formattedLabel}
							name="writeVolume"
							value={attributes.writeVolume.value}
							filter={attributes.writeVolume.filter}
							onChange={handleChange}
						/>
						<ConditionalTableItem
							show={!summary || !!attributes['clusterOverwriteCadence'].value}
							forcedDisplay={
								summary && !!attributes['clusterOverwriteCadence'].value
							}
						>
							<TableAttribute
								showForm={viewSlice.showAttributes}
								label={
									modelProperties['clusterOverwriteCadence'].formattedLabel
								}
								name="clusterOverwriteCadence"
								value={attributes.clusterOverwriteCadence.value}
								filter={attributes.clusterOverwriteCadence.filter}
								onChange={handleChange}
							/>
						</ConditionalTableItem>
					</AttributesBlock>
					<AttributesBlock
						title="Environmentals"
						category="environmentals"
						titleTooltip={strings.categoryTooltips.environmentals}
					>
						<TableAttribute
							showForm={viewSlice.showAttributes}
							label={modelProperties['rackSpaceRequired'].formattedLabel}
							name="rackSpaceRequired"
							value={attributes.rackSpaceRequired.value}
							filter={attributes.rackSpaceRequired.filter}
							onChange={handleChange}
						/>
						{mode === 'existing' && (
							<AddedTableAttribute
								label={
									existingProperties['rackSpaceRequiredAdded']
										?.formattedLabel || ''
								}
							/>
						)}

						<ConditionalTableItem
							show={!summary || !!attributes['height'].value}
							forcedDisplay={summary && !!attributes['height'].value}
						>
							<TableAttribute
								showForm={viewSlice.showAttributes}
								label={modelProperties['height'].formattedLabel}
								name="height"
								value={attributes.height.value}
								filter={attributes.height.filter}
								onChange={handleChange}
							/>
						</ConditionalTableItem>
						<ConditionalTableItem
							show={!summary || !!attributes['width'].value}
							forcedDisplay={summary && !!attributes['width'].value}
						>
							<TableAttribute
								showForm={viewSlice.showAttributes}
								label={modelProperties['width'].formattedLabel}
								name="width"
								value={attributes.width.value}
								filter={attributes.width.filter}
								onChange={handleChange}
							/>
						</ConditionalTableItem>
						<ConditionalTableItem
							show={!summary || !!attributes['depth'].value}
							forcedDisplay={summary && !!attributes['depth'].value}
						>
							<TableAttribute
								showForm={viewSlice.showAttributes}
								label={modelProperties['depth'].formattedLabel}
								name="depth"
								value={attributes.depth.value}
								filter={attributes.depth.filter}
								onChange={handleChange}
							/>
						</ConditionalTableItem>
						<ConditionalTableItem
							show={!summary || !!attributes['weight'].value}
							forcedDisplay={summary && !!attributes['weight'].value}
						>
							<TableAttribute
								showForm={viewSlice.showAttributes}
								label={modelProperties['weight'].formattedLabel}
								name="weight"
								value={attributes.weight.value}
								filter={attributes.weight.filter}
								onChange={handleChange}
							/>
							{mode === 'existing' && (
								<AddedTableAttribute
									label={
										existingProperties['weightAdded']?.formattedLabel || ''
									}
								/>
							)}
						</ConditionalTableItem>
						<TableAttribute
							showForm={viewSlice.showAttributes}
							label={modelProperties['typicalWatts'].formattedLabel}
							name="typicalWatts"
							value={attributes.typicalWatts.value}
							filter={attributes.typicalWatts.filter}
							onChange={handleChange}
						/>
						{mode === 'existing' && (
							<AddedTableAttribute
								label={
									existingProperties['typicalWattsAdded']?.formattedLabel || ''
								}
							/>
						)}
						<ConditionalTableItem
							show={!summary || !!attributes['typicalAmps110V'].value}
							forcedDisplay={summary && !!attributes['typicalAmps110V'].value}
						>
							<TableAttribute
								showForm={viewSlice.showAttributes}
								label={modelProperties['typicalAmps110V'].formattedLabel}
								name="typicalAmps110V"
								value={attributes.typicalAmps110V.value}
								filter={attributes.typicalAmps110V.filter}
								onChange={handleChange}
							/>
						</ConditionalTableItem>
						<ConditionalTableItem
							show={!summary || !!attributes['typicalAmps240V'].value}
							forcedDisplay={summary && !!attributes['typicalAmps240V'].value}
						>
							<TableAttribute
								showForm={viewSlice.showAttributes}
								label={modelProperties['typicalAmps240V'].formattedLabel}
								name="typicalAmps240V"
								value={attributes.typicalAmps240V.value}
								filter={attributes.typicalAmps240V.filter}
								onChange={handleChange}
							/>
						</ConditionalTableItem>
						<ConditionalTableItem
							show={!summary || !!attributes['typicalThermalBTU'].value}
							forcedDisplay={summary && !!attributes['typicalThermalBTU'].value}
						>
							<TableAttribute
								showForm={viewSlice.showAttributes}
								label={modelProperties['typicalThermalBTU'].formattedLabel}
								name="typicalThermalBTU"
								value={attributes.typicalThermalBTU.value}
								filter={attributes.typicalThermalBTU.filter}
								onChange={handleChange}
							/>
						</ConditionalTableItem>
					</AttributesBlock>
				</FiltersColumn>
				{mode === 'existing' &&
					!dataExisting.errors &&
					!dataExisting.loading &&
					dataExisting.existing && (
						<ExistingColumn
							cluster={dataExisting.existing}
							existing={filters.existings}
						/>
					)}
				{!tableData.loading && renderColumns()}
			</Table>
			{(!tableData.loading &&
				!tableData.errors &&
				!tableData.clusters.length) ||
			(filters.models.length &&
				!filters.models.filter((i) => i.selected === true).length) ? (
				<NoResultsText className="no-result-text">
					No Results Found
				</NoResultsText>
			) : null}
			{tableData.loading ? <LoadingSpinner centered /> : null}
		</TableWrapper>
	);
};

export default TableComponent;
