import { createSlice } from '@reduxjs/toolkit';
import { fetchModelsList } from '../../api';
import { resIsValid } from '../../api/models';
// Types
import { Model, ModelsSliceState, AppDispatch, RootState } from '../../types';

// External actions
import {
	setPlatforms,
	updateFilters,
	filtersSelector,
	setPerformanceClasses,
} from './filters';

export const initialState: ModelsSliceState = {
	loading: false,
	errors: false,
	models: [],
};

const modelsSlice = createSlice({
	name: 'models',
	initialState,
	reducers: {
		getModels: (state) => {
			state.loading = true;
		},
		getModelsSuccess: (state, { payload }: { payload: Model[] }) => {
			state.models = payload;
			state.loading = false;
			state.errors = false;
		},
		getModelsFailure: (state) => {
			state.loading = false;
			state.errors = true;
		},
	},
});

export const {
	getModels,
	getModelsSuccess,
	getModelsFailure,
} = modelsSlice.actions;
export const modelsSelector = (state: RootState) => state.calculator.models;
export default modelsSlice.reducer;

export function fetchModels() {
	return async (dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(getModels());

		try {
			const data = await fetchModelsList();
			if (resIsValid(data) && data.models) {
				dispatch(
					getModelsSuccess(
						data.models.sort((a, b) => {
							if (
								a.platform === 'qumulo' &&
								b.platform === 'qumulo' &&
								a.modelUid &&
								b.modelUid
							) {
								return a.modelUid.localeCompare(b.modelUid);
							} else if (a.platform === 'qumulo' && b.platform !== 'qumulo')
								return -1;
							return 0;
						}),
					),
				);
				// Checking if any state has been stored in URL
				const query = new URLSearchParams(window.location.search).get(
					'selection',
				);
				const unchecked = query?.length ? JSON.parse(atob(query || '')) : [];
				// Setting models in the filters slice

				let platforms: string[] = [];
				data.models.forEach((model) => {
					if (!platforms.includes(model.platform))
						platforms.push(model.platform);
				});
				dispatch(setPlatforms(platforms));

				let performanceClasses: string[] = [];
				data.models.forEach((model) => {
					if (!performanceClasses.includes(model.performanceClass))
						performanceClasses.push(model.performanceClass);
				});
				performanceClasses.sort().reverse();
				dispatch(setPerformanceClasses(performanceClasses));

				dispatch(
					updateFilters(
						Object.assign({}, filtersSelector(getState()), {
							models: data.models.map((item) =>
								Object.assign({}, item, {
									selected: !(unchecked.indexOf(item.modelUid) >= 0),
								}),
							),
						}),
					),
				);
			} else {
				throw new Error('Models List API Response not valid');
			}
		} catch (error) {
			console.log(error);
			dispatch(getModelsFailure());
		}
	};
}
