import React from 'react';
import styled from 'styled-components';

import logo from '../../assets/qumulo_logo.svg';

type FormatForPrintProps = {
	lastRequest?: number;
	nodeDetailsItems: JSX.Element[];
	clusterTab: JSX.Element;
	title: JSX.Element;
	nodesTitle: string;
	ref?: React.MutableRefObject<undefined> | undefined;
};

const TopHeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;
const Logo = styled.img`
	width: 150px;
	margin-left: 25px;
`;

const HeaderText = styled.span`
	color: #dddddd;
	font-size: 12px;
`;

const DisclaimerText = styled.div``;

class FormatForPrint extends React.Component<FormatForPrintProps> {
	render() {
		const { clusterTab, lastRequest, nodeDetailsItems, title, nodesTitle } =
			this.props;
		const lastRequestDate = new Date(lastRequest || '');

		return (
			<div className="print-formatter">
				<TopHeaderWrapper>
					<Logo src={logo} />
					{lastRequest && (
						<HeaderText>
							{`
						Report generated ${lastRequestDate.toUTCString()} - Version ${
								process.env.REACT_APP_SOURCE_VERSION &&
								process.env.REACT_APP_SOURCE_VERSION.substring(0, 7)
							}						
						`}{' '}
						</HeaderText>
					)}
				</TopHeaderWrapper>
				<div className="print-section-title">{title}</div>
				{clusterTab}
				<div className="print-section-title" style={{ breakBefore: 'page'}}>
					<div className="print-modal-title">All Nodes</div>
					<div className="print-modal-sub-title">{nodesTitle}</div>
				</div>
				{nodeDetailsItems}
				<DisclaimerText className="print-disclaimer">
					This tool provides an estimate of Qumulo software performance
					characteristics running on specific server hardware. Data presented
					may have been derived from lab testing and/or simulations, and may not
					accurately represent all production workloads or environments due to
					factors specific to customer usage and infrastructure outside of
					Qumulo control. System performance (capacity and other attributes) are
					estimates only and provided for informational purposes only.
				</DisclaimerText>
			</div>
		);
	}
}

export default FormatForPrint;
