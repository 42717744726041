import { createSlice } from '@reduxjs/toolkit';
import {
	fetchCapacity,
	fetchCapacityExisting,
	fetchIOPS,
	fetchIOPSExisting,
	fetchThroughput,
	fetchThroughputExisting,
	fetchClusterDetails,
	fetchResilience,
} from '../../api';
import { resIsValid } from '../../api/charts';
import { formatCluster } from './data';

// Types
import {
	ChartsSliceState,
	AppDispatch,
	Cluster,
	RootState,
	ChartsCapacityRes,
	ChartsIOPSRes,
	ChartType,
	ChartsThroughputRes,
	FormattedCluster,
	ChartsResilienceRes,
	ChartReq,
	ChartReqExisting,
	ChartsResilienceReq,
	GlobalSliceState,
	ExistingItem,
} from '../../types';

export const initialState: ChartsSliceState = {
	selector: {
		showMaxScaling: false,
		baseUsage: 0,
		yoyGrowth: 0,
		costPerRawTB: 0,
		chartType: 'Capacity Planning',
	},
	capacity: {
		loading: false,
		errors: false,
		isMaxNodeReached: false,
		details: [],
	},
	iops: {
		loading: false,
		errors: false,
		isMaxNodeReached: false,
		details: [],
	},
	throughput: {
		loading: false,
		errors: false,
		isMaxNodeReached: false,
		details: [],
	},
	resilience: {
		selector: {
			info: {
				minNodes: 0,
				maxNodes: 0,
			},
			loading: false,
			errors: false,
			details: null,
		},
		chart: {
			loading: false,
			errors: false,
			details: [[]],
			defaultSelected: undefined,
			selected: undefined,
		},
	},
	summaryView: false,
	selectedCluster: undefined,
};

const chartsSlice = createSlice({
	name: 'charts',
	initialState,
	reducers: {
		getCapacity: (state) => {
			state.capacity.loading = true;
		},
		getCapacitySuccess: (
			state,
			{ payload }: { payload: ChartsCapacityRes },
		) => {
			state.capacity.details = payload.details;
			state.capacity.isMaxNodeReached = payload.isMaxNodeReached;
			state.capacity.loading = false;
			state.capacity.errors = false;
		},
		getCapacityFailure: (state) => {
			state.capacity.loading = false;
			state.capacity.errors = true;
		},
		getIOPS: (state) => {
			state.iops.loading = true;
		},
		getIOPSSuccess: (state, { payload }: { payload: ChartsIOPSRes }) => {
			state.iops.details = payload.details;
			state.iops.isMaxNodeReached = payload.isMaxNodeReached;
			state.iops.loading = false;
			state.iops.errors = false;
		},
		getIOPSFailure: (state) => {
			state.iops.loading = false;
			state.iops.errors = true;
		},
		getThroughput: (state) => {
			state.throughput.loading = true;
		},
		getThroughputSuccess: (
			state,
			{ payload }: { payload: ChartsThroughputRes },
		) => {
			state.throughput.details = payload.details;
			state.throughput.isMaxNodeReached = payload.isMaxNodeReached;
			state.throughput.loading = false;
			state.throughput.errors = false;
		},
		getThroughputFailure: (state) => {
			state.throughput.loading = false;
			state.throughput.errors = true;
		},
		getResilienceSelector: (state) => {
			state.resilience.selector.loading = true;
		},
		getResilienceSelectorSuccess: (
			state,
			{ payload }: { payload: ChartsResilienceRes },
		) => {
			state.resilience.selector.info = payload.info;
			state.resilience.selector.data = payload.data[0];
			state.resilience.selector.details = payload.data[0].details;
			state.resilience.selector.loading = false;
			state.resilience.selector.errors = false;
		},
		getResilienceSelectorFailure: (state) => {
			state.resilience.selector.loading = false;
			state.resilience.selector.errors = true;
		},
		getResilience: (state) => {
			state.resilience.chart.loading = true;
		},
		getResilienceSuccess: (
			state,
			{ payload }: { payload: ChartsResilienceRes },
		) => {
			state.resilience.chart.details = payload.data.map((d) => d.data);
			state.resilience.chart.loading = false;
			state.resilience.chart.errors = false;
		},
		getResilienceFailure: (state) => {
			state.resilience.chart.loading = false;
			state.resilience.chart.errors = true;
		},
		getSelectedClusterDetailsSuccess: (
			state,
			{ payload }: { payload: FormattedCluster },
		) => {
			state.selectedCluster = payload;
		},
		setResilienceSelectorConfig: (
			state,
			{
				payload,
			}: {
				payload:
					| { node: number; driveProtect: number; nodeProtect: number }
					| undefined;
			},
		) => {
			if (payload) {
				state.resilience.chart.selected = {
					node: payload.node,
					driveProtect: payload.driveProtect,
					nodeProtect: payload.nodeProtect,
				};
			} else {
				state.resilience.chart.selected = payload;
			}
		},
		setShowMaxScaling: (state, { payload }: { payload: boolean }) => {
			state.selector.showMaxScaling = payload;
		},
		setBaseUsage: (state, { payload }: { payload: number | null }) => {
			state.selector.baseUsage = payload;
		},
		setYOYGrowth: (state, { payload }: { payload: number | null }) => {
			state.selector.yoyGrowth = payload;
		},
		setChartType: (state, { payload }: { payload: ChartType }) => {
			state.selector.chartType = payload;
		},
		setCostPerRawTB: (state, { payload }: { payload: number | undefined }) => {
			state.selector.costPerRawTB = payload;
		},
		setSelectedCluster: (
			state,
			{ payload }: { payload: FormattedCluster | undefined },
		) => {
			state.selectedCluster = payload;
		},
		toggleSummaryView: (state, { payload }: { payload: boolean }) => {
			state.summaryView = payload;
		},
		setDefaultSelectedInfo: (
			state,
			{
				payload,
			}: {
				payload: { node: number; driveProtect: number; nodeProtect: number };
			},
		) => {
			state.resilience.chart.defaultSelected = payload;
		},
	},
});

export const {
	getCapacity,
	getCapacitySuccess,
	getCapacityFailure,
	getIOPS,
	getIOPSSuccess,
	getIOPSFailure,
	getThroughput,
	getThroughputSuccess,
	getThroughputFailure,
	getResilienceSelector,
	getResilienceSelectorSuccess,
	getResilienceSelectorFailure,
	setResilienceSelectorConfig,
	getResilience,
	getResilienceSuccess,
	getResilienceFailure,
	getSelectedClusterDetailsSuccess,
	setShowMaxScaling,
	setBaseUsage,
	setYOYGrowth,
	setChartType,
	setCostPerRawTB,
	setSelectedCluster,
	toggleSummaryView,
	setDefaultSelectedInfo,
} = chartsSlice.actions;
export const chartsSelector = (state: RootState) => state.calculator.charts;
export default chartsSlice.reducer;

export function fetchChartsCapacity(chartReq: ChartReq) {
	return async (dispatch: AppDispatch) => {
		dispatch(getCapacity());
		try {
			const data: ChartsCapacityRes = await fetchCapacity(chartReq);
			if (resIsValid(data)) {
				dispatch(getCapacitySuccess(data));
			} else {
				throw new Error('Details Fetch API Response not valid');
			}
		} catch (error) {
			dispatch(getCapacityFailure());
		}
	};
}

export function fetchChartsCapacityExisting(chartReq: ChartReqExisting) {
	return async (dispatch: AppDispatch) => {
		dispatch(getCapacity());
		try {
			const data: ChartsCapacityRes = await fetchCapacityExisting(chartReq);
			if (resIsValid(data)) {
				dispatch(getCapacitySuccess(data));
			} else {
				throw new Error('Details Fetch API Response not valid');
			}
		} catch (error) {
			dispatch(getCapacityFailure());
		}
	};
}

export function fetchChartsIOPS(chartReq: ChartReq) {
	return async (dispatch: AppDispatch) => {
		dispatch(getIOPS());
		try {
			const data: ChartsIOPSRes = await fetchIOPS(chartReq);
			if (resIsValid(data)) {
				dispatch(getIOPSSuccess(data));
			} else {
				throw new Error('Details Fetch API Response not valid');
			}
		} catch (error) {
			dispatch(getIOPSFailure());
		}
	};
}

export function fetchChartsIOPSExisting(chartReq: ChartReqExisting) {
	return async (dispatch: AppDispatch) => {
		dispatch(getIOPS());
		try {
			const data: ChartsIOPSRes = await fetchIOPSExisting(chartReq);
			if (resIsValid(data)) {
				dispatch(getIOPSSuccess(data));
			} else {
				throw new Error('Details Fetch API Response not valid');
			}
		} catch (error) {
			dispatch(getIOPSFailure());
		}
	};
}

export function fetchChartsThroughput(chartReq: ChartReq) {
	return async (dispatch: AppDispatch) => {
		dispatch(getThroughput());
		try {
			const data: ChartsThroughputRes = await fetchThroughput(chartReq);
			if (resIsValid(data)) {
				dispatch(getThroughputSuccess(data));
			} else {
				throw new Error('Details Fetch API Response not valid');
			}
		} catch (error) {
			dispatch(getThroughputFailure());
		}
	};
}

export function fetchChartsThroughputExisting(chartReq: ChartReqExisting) {
	return async (dispatch: AppDispatch) => {
		dispatch(getThroughput());
		try {
			const data: ChartsThroughputRes = await fetchThroughputExisting(chartReq);
			if (resIsValid(data)) {
				dispatch(getThroughputSuccess(data));
			} else {
				throw new Error('Details Fetch API Response not valid');
			}
		} catch (error) {
			dispatch(getThroughputFailure());
		}
	};
}

export function fetchChartsResilience(
	chartReq: ChartsResilienceReq,
	globalState: GlobalSliceState,
) {
	return async (dispatch: AppDispatch) => {
		dispatch(getResilience());
		try {
			const data: ChartsResilienceRes = await fetchResilience(chartReq);
			if (resIsValid(data)) {
				dispatch(getResilienceSuccess(data));
				if (data.data.length > 0 && data.data[0]?.hasOwnProperty('encoding')) {
					const clusterReq: any = {
						modelUid: data.data[0]?.modelUid,
						nodes: data.data[0]?.nodes,
						encoding: data.data[0]?.encoding,
						nodeOutageTolerance: data.data[0]?.nodeProtect,
					};
					const selectedCluster: Cluster = await fetchClusterDetails(
						clusterReq,
					);
					dispatch(
						getSelectedClusterDetailsSuccess(
							formatCluster(globalState, selectedCluster),
						),
					);
				}
			} else {
				throw new Error('Details Fetch API Response not valid');
			}
		} catch (error) {
			dispatch(getResilienceFailure());
		}
	};
}

export function fetchExistingDetails(
	existings: ExistingItem[],
	stripeWidth: string,
	nodeOutageTolerance: string
) {
	const encoding = stripeWidth.split(',');
	const nodeProtect = parseInt(nodeOutageTolerance);

	return Promise.all(existings.map(async cluster => await fetchClusterDetails({
		modelUid: cluster.modelUid,
		nodes: cluster.nodeCount,
		encoding: [
			parseInt(encoding[0]),
			parseInt(encoding[1])
		],
		nodeOutageTolerance: nodeProtect,
	})));
}

export function fetchChartsResilienceSelector(chartReq: ChartsResilienceReq) {
	return async (dispatch: AppDispatch) => {
		dispatch(getResilienceSelector());
		try {
			const data: ChartsResilienceRes = await fetchResilience(chartReq);

			if (resIsValid(data)) {
				dispatch(getResilienceSelectorSuccess(data));
			} else {
				throw new Error('Details Fetch API Response not valid');
			}
		} catch (error) {
			dispatch(getResilienceSelectorFailure());
		}
	};
}
