import { indexToMonth, TBtoTiB } from '../../../../utils/conversions';
import { ChartData } from '../../../../types';

export function formatChartData(
	toFormat: Array<any>,
	toFormatType: string,
	dataUnits: string,
	showMaxScaling: boolean,
): ChartData {
	const res: ChartData = [];

	if (toFormat) {
		for (let i = 0; i < toFormat.length; i++) {
			const element = toFormat[i];
			const custom = element;
			const yPoint =
				dataUnits === 'base10'
					? element[toFormatType]
					: TBtoTiB(element[toFormatType]);

			// Custom marker
			if (i === 0) {
				res.push({
					x: element.nodeCount,
					y: yPoint,
					marker: {
						symbol: 'diamond',
						radius: 9,
					},
					custom,
				});
			} else if (i === toFormat.length - 1 && showMaxScaling) {
				// Last marker if showing max
				res.push({
					x: element.nodeCount,
					y: yPoint,
					marker: {
						symbol: 'diamond',
						radius: 9,
					},
					custom,
				});
			} else {
				res.push({
					x: element.nodeCount,
					y: yPoint,
					marker: {
						radius: 5,
					},
					custom,
				});
			}
		}
	}
	return res;
}

export function yoyGrowthToDate(
	pointY: number,
	initialCapacity: number,
	yoyValue: number,
): string | null {
	const mom = Math.round(yoyValue / 12);
	const diff = (pointY - initialCapacity) / mom;
	const date = new Date();
	const newDate = new Date(date.setMonth(date.getMonth() + diff));

	if (isFinite(diff)) {
		return `${indexToMonth(newDate.getMonth())} ${
			newDate.getDay() + 1
		}, ${newDate.getFullYear()}`;
	} else {
		return null;
	}
}

export function calculateCostPerRawTB(
	costPerRawTB: number | undefined,
	value: number,
	currency: string,
): string | undefined {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: currency,
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});
	return costPerRawTB ? formatter.format(value * costPerRawTB) : undefined;
}
