import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.43);
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 999;
	display: none;
`;

const OverlayComponent = () => {
	return <Overlay className="overlay" />;
};

export default OverlayComponent;
