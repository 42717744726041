import { combineReducers } from 'redux';

import calculatorReducer from './calculator';
import globalReducer from './global';

const rootReducer = combineReducers({
	calculator: calculatorReducer,
	global: globalReducer,
});

// Exporting state type
export type RootStateExport = ReturnType<typeof rootReducer>;

export default rootReducer;
