import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

type BlockProps = {
	title: string;
	noBorder?: boolean;
};

const Block = styled.div`
	margin: 5px 0px;
`;

const BlockTitle = styled.span`
	display: block;
`;

const FiltersPanelBlock: FunctionComponent<BlockProps> = ({
	title,
	children,
	noBorder,
}) => {
	return (
		<Block className={`filters-panel-block ${noBorder ? 'no-border' : ''}`}>
			<BlockTitle className="filters-panel-block-title">{title}</BlockTitle>
			{children}
		</Block>
	);
};

export default FiltersPanelBlock;
