import React, { FunctionComponent, Fragment } from 'react';
import styled from 'styled-components';
import { useWindowSize } from '../../hooks';

// Types
import { FilterAttribute } from '../../types';

type TableAttributesProps = {
	label: string;
	name: string;
	value: number | null;
	filter: string;
	onChange: (name: string, value: FilterAttribute) => void;
	showForm: boolean;
	disabled?: boolean;
};

const Attribute = styled.div`
	display: flex;
	align-items: center;
	padding-left: 20px;
`;

const Label = styled.span`
	flex-basis: 241px;
	flex-shrink: 0;
`;

const MinMaxWrapper = styled.div`
	width: 90px;
`;

const ClearButton = styled.button`
	padding: 0px 3px 0px 3px !important;
	font-size: 20px !important;
`;

const Button = styled.button`
	border: none;
`;

const TableAttribute: FunctionComponent<TableAttributesProps> = (props) => {
	const { label, name, value, filter, onChange, showForm, disabled } = props;
	const [width] = useWindowSize();

	const setFilter = (filterValue: string) => {
		let res: string;
		// Cycling through on small devices
		if (width < 1200) {
			switch (filter) {
				case 'none':
					res = 'max';
					break;
				case 'max':
					res = 'min';
					break;
				case 'min':
					res = 'none';
					break;
				default:
					res = 'none';
					break;
			}
		} else {
			res = filterValue === filter ? 'none' : filterValue;
		}
		onChange(name, { value, filter: res });
	};

	const setValue = (newValue: number) => {
		onChange(name, { value: newValue, filter });
	};

	const handleClear = () => {
		onChange(name, { value: null, filter: 'none' });
	};

	const form = (
		<Fragment>
			<ClearButton
				className="btn btn-link clear-button"
				onClick={() => {
					handleClear();
				}}
				disabled={!value}
			>
				<i className="fas fa-times-circle"></i>
			</ClearButton>
            <input
                className="existing-input"
                type="number"
                min="0"
                value={value || ''}
                onChange={(e) => {
                    setValue(parseFloat(e.target.value));
                }}
                disabled={disabled}
            />
			<MinMaxWrapper
				className={`btn-group min-max-group ${!value ? 'disabled' : ''}`}
				role="group"
				aria-label="Table attribute"
			>
				<Button
					disabled={!value}
					onClick={() => {
						setFilter('min');
					}}
					className={`btn ${value && filter === 'min' ? 'active' : null}`}
				>
					min
				</Button>
				<Button
					disabled={!value}
					onClick={() => {
						setFilter('none');
					}}
					className={`btn ${value && filter === 'none' ? 'active' : null}`}
				>
					auto
				</Button>
				<Button
					disabled={!value}
					onClick={() => {
						setFilter('max');
					}}
					className={`btn ${value && filter === 'max' ? 'active' : null}`}
				>
					max
				</Button>
			</MinMaxWrapper>
		</Fragment>
	);

	return (
		<Attribute className="table-attribute">
			<Label className="table-attribute-label">
				{label}
				<i
					style={{ display: 'none' }}
					className="pl-2 hidden icon fas fa-magic"
				/>
			</Label>
			{showForm && form}
		</Attribute>
	);
};

export default TableAttribute;
