import React, { useState } from 'react';

export type OptionProps = {
	disabled?: boolean;
	value: string;
	label: string;
};

export type SelectProps = {
	items: Array<OptionProps>;
	onClick: (value: string) => void;
	disabled?: boolean;
	value?: string;
	placeholder?: string;
	className?: string;
};

const Select = ({
	items,
	onClick,
	disabled,
	value,
	placeholder = 'Select...',
	className,
	...rest
}: SelectProps) => {
	const [show, setShow] = useState(false);
	const selectedOption = items.find((item) => item.value === value);

	const handleClickOption = (e: any, value: string) => {
		e.preventDefault();
		if (onClick && typeof onClick === 'function') onClick(value);
	};

	const handleBlur = (e: any) => {
		if (e && e.relatedTarget) {
			e.relatedTarget.click();
		}
		setShow(false);
	};

	const toggleSelect = () => {
		if (!disabled) setShow(!show);
	};

	return (
		<div
			className={[`select ${disabled ? 'disabled' : ''}`, className].join(' ')}
			{...rest}
		>
			<button
				className="button-wrapper"
				type="button"
				onClick={toggleSelect}
				onBlur={handleBlur}
			>
				<div>{(selectedOption && selectedOption.label) || placeholder}</div>
				<i className={`dropdown-icon fa fa-chevron-${show ? 'down' : 'up'}`} />
			</button>
			{show && (
				<div className="menu-list">
					{items &&
						items.map((item, index) => (
							// eslint-disable-next-line jsx-a11y/anchor-is-valid
							<a
								className="option"
								href=""
								onClick={(e) => handleClickOption(e, item.value)}
								key={index}
							>
								{item.label}
							</a>
						))}
				</div>
			)}
		</div>
	);
};

export default Select;
