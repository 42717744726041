import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	chartsSelector,
	toggleSummaryView,
} from '../../slices/calculator/charts';
import { viewSelector } from '../../slices/calculator/view';
import { formattedDataExistingSelector } from '../../slices/calculator/dataExisting';
import { detailsSelector } from '../../slices/calculator/details';
import { filtersSelector } from '../../slices/calculator/filters';
import { Switch } from '../../components/forms';

const DetailsHeader = () => {
	const dispatch = useDispatch();
	const { selectedCluster, summaryView } = useSelector(chartsSelector);
	const { mode, selection } = useSelector(viewSelector);
	const dataExisting = useSelector(formattedDataExistingSelector);
	const { details } = useSelector(detailsSelector);
	const { existings } = useSelector(filtersSelector);

	return (
		<div className="details-header">
			<div className="details-header-model-container">
				<div className="diamond-large" />
				<div className="details-header-model-name">
					{mode === 'existing' && selection.length
						? `${
								dataExisting?.clusters?.find(
									(cluster) => cluster.modelUid === selection[0],
								)?.modelNameShort
						  }
							${existings?.filter(item => !item.replace)
								?.map(
									(cluster) =>
										` + ${
											details?.find(
												(clusterDetails) =>
													clusterDetails.modelUid === cluster.modelUid,
											)?.modelNameShort
										}`,
								)
								.join('')}
							`
						: `${selectedCluster?.modelNameShort}`}
				</div>
				<div>Qumulo Cluster</div>
			</div>
			<div className="details-header-actions">
				<Switch
					onChange={() => dispatch(toggleSummaryView(!summaryView))}
					checked={summaryView}
					label={'Summary View'}
				/>
			</div>
		</div>
	);
};

export default DetailsHeader;
