import { createSlice } from '@reduxjs/toolkit';

// Types
import { GlobalSliceState, RootState } from '../../types';

const lsMeasurement = localStorage.getItem('globalMeasurements');
const lsDataUnits = localStorage.getItem('globalDataUnits');
const lsCurrency = localStorage.getItem('currency');

export const initialState: GlobalSliceState = {
	measurements:
		lsMeasurement !== null &&
		(lsMeasurement === 'standard' || lsMeasurement === 'metric')
			? lsMeasurement
			: 'standard',
	dataUnits:
		lsDataUnits !== null &&
		(lsDataUnits === 'base2' || lsDataUnits === 'base10')
			? lsDataUnits
			: 'base10',
	currency: lsCurrency || 'USD',
};

const globalSlice = createSlice({
	name: 'global',
	initialState,
	reducers: {
		setMeasurements: (
			state,
			{ payload }: { payload: 'standard' | 'metric' },
		) => {
			localStorage.setItem('globalMeasurements', payload);
			state.measurements = payload;
			return state;
		},
		setDataUnits: (state, { payload }: { payload: 'base2' | 'base10' }) => {
			localStorage.setItem('globalDataUnits', payload);
			state.dataUnits = payload;
			return state;
		},
		setCurrency: (state, { payload }: { payload: string }) => {
			localStorage.setItem('currency', payload);
			state.currency = payload;
			return state;
		},
	},
});

export const {
	setMeasurements,
	setDataUnits,
	setCurrency,
} = globalSlice.actions;
export const globalSelector = (state: RootState) => state.global;
export default globalSlice.reducer;
