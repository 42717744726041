import { call } from './misc';
import { DataAPIResponse, FiltersAttributes } from '../types';

export const resIsValid = (res: any): res is DataAPIResponse => {
	return !!res.clusters;
};

export const fetchData = (filters: FiltersAttributes) => {
	return call('/', 'POST', { attributes: filters });
};
