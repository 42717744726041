import React, { FunctionComponent } from 'react';

type ItemProps = {
	show: boolean;
	forcedDisplay: boolean;
};

const ConditionalTableItem: FunctionComponent<ItemProps> = ({
	show,
	children,
	forcedDisplay,
}) => {
	return (
		<div
			style={{ display: `${show ? 'block' : 'none'}` }}
			className={forcedDisplay ? 'forced-display' : ''}
		>
			{children}
		</div>
	);
};

export default ConditionalTableItem;
