import { call } from './misc';
import { DataExistingAPIResponse, FiltersSliceState } from '../types';

export const resIsValid = (res: any): res is DataExistingAPIResponse => {
	return res.clusters && res.existing;
};

export const fetchExistingData = (
	filters: FiltersSliceState,
	recommendedOnly = true,
	allowTranscoding: boolean,
) => {
	const n = parseInt(filters.stripeWidth.split(',')[0]);
	const k = parseInt(filters.stripeWidth.split(',')[1]);
	return call('/existing', 'POST', {
		attributes: filters.attributes,
		existings: filters.existings,
		nodeOutageTolerance: parseInt(filters.nodeOutageTolerance),
		encoding: { n, k },
		recommendedOnly,
		allowTranscoding,
	});
};
