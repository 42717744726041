import { call } from './misc';
import { ModelsAPIResponse } from '../types';

export const resIsValid = (res: any): res is ModelsAPIResponse => {
	return !!res.models;
};

export const fetchModelsList = () => {
	return call('/models', 'GET');
};
