import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import styled from 'styled-components';
import { uid } from 'uid';

type TableWarningProps = {
	text: string;
	variant?: 'primary' | 'secondary';
};
const TableWarningWrapper = styled.span`
	margin-left: 5px;
	margin-right: 5px;
`;

const TableWarningIcon = styled.i``;

const TableWarningComponent = (props: TableWarningProps) => {
	const { text } = props;
	const id = uid();
	return (
		<TableWarningWrapper>
			<OverlayTrigger
				placement={'top'}
				overlay={
					<Popover id={id}>
						<Popover.Content>{text}</Popover.Content>
					</Popover>
				}
			>
				<TableWarningIcon
					className={`fas fa-exclamation-circle ${
						(props.variant || 'primary') === 'primary' ? 'blue' : ''
					}`}
				/>
			</OverlayTrigger>
		</TableWarningWrapper>
	);
};

export default TableWarningComponent;
