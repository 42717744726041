import colors from '../../../utils/chartsColors.json';
import {
	XAxisPlotLinesOptions,
	PointOptionsObject,
	YAxisPlotLinesOptions,
	Options,
} from 'highcharts';

export const defaultChartOptions: Options = {
	title: {
		text: '',
	},
	credits: {
		enabled: false,
	},
	xAxis: {
		title: {
			text: 'NODES',
			margin: 10,
		},
		crosshair: {
			color: colors.blue,
		},
		gridLineWidth: 1,
		maxPadding: 0.1,
		tickInterval: 1,
	},
	legend: {
		enabled: false,
	},
};

export function getBaseUsageYAxisPlotlinesOptions(
	dataUnits: string,
	baseUsage: number | null,
): Array<YAxisPlotLinesOptions> {
	return [
		{
			color: colors.black,
			width: 2,
			zIndex: 8,
			value: baseUsage || undefined,
			dashStyle: 'Dot',
			label: {
				text: `Base usage ${baseUsage}${
					dataUnits === 'base10' ? ' TB' : ' TiB'
				}`,
				align: 'center',
				y: -10,
			},
		},
	];
}

export function getMaxNodesXPlotLinesOptions(
	isMaxNodeReached: boolean,
	lastData: PointOptionsObject,
): Array<XAxisPlotLinesOptions> {
	if (!lastData) return [];
	return isMaxNodeReached
		? [
				{
					color: colors.blue,
					width: 2,
					value: lastData ? lastData.x : 0,
					label: {
						text: 'Max Nodes',
						verticalAlign: 'middle',
						x: 10,
						useHTML: true,
						style: {
							fontSize: '1em',
							color: colors.blue,
						},
					},
				},
		  ]
		: [
				{
					color: colors.blue,
					width: 2,
					dashStyle: 'ShortDot',
					value: lastData ? (lastData.x ? lastData.x + 0.9 : 0) : 0,
					label: {
						text: '<i class="fas fa-arrow-up"></i>&nbsp;More data',
						verticalAlign: 'middle',
						x: -20,
						useHTML: true,
						style: {
							fontSize: '1em',
							color: colors.blue,
							fontFamily: 'Helvetica',
						},
					},
				},
		  ];
}
