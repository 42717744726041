import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { PrivateRoute } from './components';

import PageHeader from './containers/PageHeader';

// Pages
import { Calculator } from './containers';
import ErrorEmailAuth from './containers/error/ErrorEmailAuth';

const AppContainer = styled.div`
	margin: 0px;
	width: 100%;
	height: 100%;
`;

const AppWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
	width: 100vw;
`;

const App = () => {
	const [error, setError] = useState<string | null>(null);
	const [errorDescription, setErrorDescription] = useState<string | null>(null);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const errorParam = params.get('error');
		const errorDescriptionParam = params.get('error_description');

		if (
			window.location.search.includes('error=') &&
			window.location.search.includes('error_description=')
		) {
			setError(errorParam);
			setErrorDescription(errorDescriptionParam);
		}
	}, []);

	return (
		<AppContainer>
			<AppWrapper id="calculator">
				<PageHeader hideLogButton={!!error && !!errorDescription} />
				<BrowserRouter>
					{error && errorDescription ? (
						<ErrorEmailAuth errorDescription={errorDescription} />
					) : (
						<PrivateRoute path="/" exact component={Calculator} />
					)}
				</BrowserRouter>
			</AppWrapper>
		</AppContainer>
	);
};

export default App;
