import React, { FunctionComponent } from 'react';
import ReactSwitch from 'react-switch';
import styled from 'styled-components';

type SwitchProps = {
	onChange: (
		checked: boolean,
		e: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
		id: string,
	) => void;
	checked: boolean;
	label?: string;
	id?: string;
};

const SwitchWrapper = styled.label`
	display: flex;
	align-items: center;
	margin-bottom: 0px;
	padding: 2px 0px;
`;

const Label = styled.span`
	margin-right: 10px;
`;

const Switch: FunctionComponent<SwitchProps> = (props) => {
	return (
		<SwitchWrapper className="custom-switch-wrapper" htmlFor={props.id}>
			<Label>{props.label}</Label>
			<ReactSwitch
				onChange={props.onChange}
				checked={props.checked}
				height={18}
				width={33}
				checkedIcon={false}
				uncheckedIcon={false}
				id={props.id}
				onColor={'#00A3CA'}
				offColor={'#979b9f'}
			/>
		</SwitchWrapper>
	);
};

export default Switch;
