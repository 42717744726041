import React from 'react';

type Props = {
	centered?: boolean;
	small?: boolean;
};
const LoadingSpinner = ({ centered, small }: Props) => (
	<div className={centered ? 'loading-spinner-center' : undefined}>
		<div className={`loading-spinner ${small ? 'small' : undefined}`} />
	</div>
);

export default LoadingSpinner;
