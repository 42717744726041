import React from 'react';
import styled from 'styled-components';

type AddedTableAttributeProps = {
	label: string;
};

const AddedAttribute = styled.div`
	padding-left: 40px;
	display: flex;
	align-items: center;
`;

const AddedTableAttribute = (props: AddedTableAttributeProps) => {
	const { label } = props;
	return (
		<AddedAttribute className="table-attribute added-attribute">
			<span className="table-attribute-label">{label}</span>
		</AddedAttribute>
	);
};

export default AddedTableAttribute;
