import React, { FunctionComponent } from 'react';

type CheckboxProps = {
	name?: string;
	block?: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	checked?: boolean;
	id?: string;
	label?: string;
};

const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
	const { name, block, onChange, checked, id, label } = props;
	// Setting padding on wrapper only if a label is specified
	const wrapperStyle = {
		paddingLeft: '30px',
	};

	return (
		<label
			className="custom-checkbox-wrapper"
			style={label && label.length ? wrapperStyle : {}}
		>
			<span className="custom-checkbox-label">{label}</span>
			<input
				className="custom-checkbox"
				type="checkbox"
				name={name}
				data-block={block || null}
				onChange={onChange}
				checked={checked}
				id={id}
			/>
			<span className="custom-checkbox-checkmark"></span>
		</label>
	);
};

export default Checkbox;
