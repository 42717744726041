import React, { useState } from 'react';
import PrintModal from '../calculator/PrintModal';
import { useSelector } from 'react-redux';
import { viewSelector } from '../../slices/calculator/view';

const DetailsFooter = () => {
	const [showPrintModal, setShowPrintModal] = useState(false);
	const view = useSelector(viewSelector);
	const lastRequestDate = new Date(view.lastRequest || 0);

	return (
		<div className="details-footer">
			<PrintModal
				show={showPrintModal}
				handleClose={() => {
					setShowPrintModal(false);
				}}
			/>
			<div className="solution-report-wrapper">
				<span className="bottom-line">
					{view.lastRequest &&
						`
					Information provided for estimation purposes only. Report generated ${lastRequestDate.toUTCString()} - Version ${
							process.env.REACT_APP_SOURCE_VERSION &&
							process.env.REACT_APP_SOURCE_VERSION.substring(0, 7)
						}
					`}
				</span>
				<button
					onClick={() => setShowPrintModal(true)}
					className="btn btn-secondary"
				>
					Generate Report
				</button>
			</div>
		</div>
	);
};

export default DetailsFooter;
