import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import InfoPanel from './InfoPanel';

// Charts
import CapacityChart from './highcharts/capacity/CapacityChartWrapper';
import IOPSChart from './highcharts/iops/IOPSChartWrapper';
import ThroughputChart from './highcharts/throughput/ThroughputChartWrapper';
import ResilienceChart from './highcharts/resilience/ResilienceChartWrapper';

// Slices
import { formattedDataSelector } from '../../slices/calculator/data';
import { formattedDataExistingSelector } from '../../slices/calculator/dataExisting';
import { setSelectedCluster } from '../../slices/calculator/charts';

// Types
import { chartsSelector } from '../../slices/calculator/charts';
import { viewSelector } from '../../slices/calculator/view';
import DetailsHeader from './DetailsHeader';
import DetailsFooter from './DetailsFooter';
import { usePrevious } from '../../hooks';
import { filtersSelector } from '../../slices/calculator/filters';
import { fetchExisting } from '../../slices/calculator/dataExisting';

const Details = () => {
	const dispatch = useDispatch();
	const chartType = useSelector(chartsSelector).selector.chartType;
	const selectedCluster = useSelector(chartsSelector).selectedCluster;
	const view = useSelector(viewSelector);
	const clusters = useSelector(formattedDataSelector);
	const existingClusters = useSelector(formattedDataExistingSelector);
	const previousClusters = usePrevious(clusters);
	const previousExistingClusters = usePrevious(existingClusters);
	const filters = useSelector(filtersSelector);
	const previousAttributes = usePrevious(filters.attributes);
	const viewSlice = useSelector(viewSelector);

	// Fetching on location change
	useEffect(() => {
		if (
			JSON.stringify(previousAttributes) !==
				JSON.stringify(filters.attributes) &&
			viewSlice.mode === 'existing'
		) {
			dispatch(fetchExisting(filters.showRecommendedOnly, filters.allowTranscoding));
		}
	}, [
		dispatch,
		filters.attributes,
		filters.showRecommendedOnly,
		filters.allowTranscoding,
		previousAttributes,
		viewSlice.mode,
	]);

	// Find model
	useEffect(() => {
		if (
			view.mode !== 'existing' &&
			clusters &&
			clusters.length &&
			JSON.stringify(previousClusters) !== JSON.stringify(clusters)
		) {
			const found = clusters.find(
				(item) => item.modelUid === view.selection[0],
			);
			if (found) dispatch(setSelectedCluster(found));
		} else if (
			view.mode === 'existing' &&
			existingClusters &&
			existingClusters.clusters &&
			existingClusters.clusters.length &&
			JSON.stringify(previousExistingClusters) !==
				JSON.stringify(existingClusters)
		) {
			const found = existingClusters.clusters.find(
				(item) => item.modelUid === view.selection[0],
			);
			if (found) dispatch(setSelectedCluster(found));
		}
	}, [
		clusters,
		existingClusters,
		dispatch,
		previousClusters,
		view.selection,
		view.mode,
		previousExistingClusters,
	]);

	const selectedChart = useCallback(() => {
		if (selectedCluster) {
			const chartProps = {
				model: selectedCluster.modelUid,
				nodes: selectedCluster.nodeCount.value as number,
				encoding: selectedCluster.encoding.value as number[],
				driveProtect: selectedCluster.driveOutageTolerance.value as number,
				nodeProtect: selectedCluster.nodeOutageTolerance.value as number,
			};
			switch (chartType) {
				default:
				case 'Capacity Planning':
					return <CapacityChart {...chartProps} />;
				case 'Capacity & IOPS':
					return <IOPSChart {...chartProps} />;
				case 'Capacity & Resilience':
					return <ResilienceChart {...chartProps} />;
				case 'Capacity & Throughput':
					return <ThroughputChart {...chartProps} />;
			}
		}
	}, [chartType, selectedCluster]);

	return (
		<div className="details">
			<div className="details-wrapper">
				<DetailsHeader />
				<div className="details-panel">
					{view.mode === 'existing' && view.selection.length ? (
						<InfoPanel
							data={existingClusters?.clusters?.find(
								(cluster) => cluster.modelUid === view.selection[0],
							)}
						/>
					) : (
						<InfoPanel data={selectedCluster} />
					)}
					<div className="details-chart-wrapper">{selectedChart()}</div>
				</div>
				<DetailsFooter />
			</div>
		</div>
	);
};

export default Details;
