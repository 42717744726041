import { call } from './misc';
import {
	ChartsCapacityRes,
	ChartsResilienceRes,
	ChartsIOPSRes,
	ChartsThroughputRes,
	ChartReq,
	ChartReqExisting,
	ChartsResilienceReq,
	Cluster,
} from '../types';

export const resIsValid = (res: any): res is ChartsCapacityRes => {
	return !!res;
};

export const fetchCapacity = (req: ChartReq): Promise<ChartsCapacityRes> => {
	return call('/new-cluster/forecast', 'POST', req);
};

export const fetchCapacityExisting = (
	req: ChartReqExisting,
): Promise<ChartsCapacityRes> => {
	return call('/expansion/forecast', 'POST', req);
};

export const fetchIOPS = (req: ChartReq): Promise<ChartsIOPSRes> => {
	return call('/new-cluster/forecast', 'POST', req);
};

export const fetchIOPSExisting = (
	req: ChartReqExisting,
): Promise<ChartsIOPSRes> => {
	return call('/expansion/forecast', 'POST', req);
};

export const fetchThroughput = (
	req: ChartReq,
): Promise<ChartsThroughputRes> => {
	return call('/new-cluster/forecast', 'POST', req);
};

export const fetchThroughputExisting = (
	req: ChartReqExisting,
): Promise<ChartsThroughputRes> => {
	return call('/expansion/forecast', 'POST', req);
};

export const fetchClusterDetails = (req: any): Promise<Cluster> => {
	return call('/cluster_details', 'POST', req);
};

export const fetchResilience = (
	req: ChartsResilienceReq,
): Promise<ChartsResilienceRes> => {
	return call('/details/capacity/resilience', 'POST', req);
};
