import { combineReducers } from 'redux';

import modelsReducer from './models';
import filtersReducer from './filters';
import dataReducer from './data';
import dataExistingReducer from './dataExisting';
import viewReducer from './view';
import detailsReducer from './details';
import chartsReducer from './charts';

const rootReducer = combineReducers({
	models: modelsReducer,
	filters: filtersReducer,
	data: dataReducer,
	dataExisting: dataExistingReducer,
	view: viewReducer,
	details: detailsReducer,
	charts: chartsReducer,
});

export default rootReducer;
