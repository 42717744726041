import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
import { useDebouncedCallback } from 'use-debounce';

// Components
import PrintModalClusterTab from '../../components/printmodal/PrintModalClusterTab';
import FormatForPrint from '../../components/printmodal/FormatForPrintComponent';

// Slices
import { formattedDataSelector } from '../../slices/calculator/data';
import { modelsSelector } from '../../slices/calculator/models';
import { viewSelector } from '../../slices/calculator/view';
import {
	fetchDetails,
	formattedDetailsSelector,
	detailsSelector,
} from '../../slices/calculator/details';

// Hooks & conversions
import PrintModalNodeItem from '../../components/printmodal/PrintModalNodeItem';
import { filtersSelector } from '../../slices/calculator/filters';
import { formattedDataExistingSelector } from '../../slices/calculator/dataExisting';
import { formatCapacity } from '../../utils/conversions';

export type PrintModalProps = {
	show: boolean;
	handleClose: () => void;
};

const PrintModalHeaderWrapper = styled.div`
	padding: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const PrintModalHeaderTitleWrapper = styled.div`
	display: flex;
	align-items: center;
`;

const PrintModalTitle = styled.h2``;

const PrintModalSubTitle = styled.h3``;

const NodesTabTitle = styled.h4``;

const PrintModal = (props: PrintModalProps) => {
	const { show, handleClose } = props;
	const data = useSelector(formattedDataSelector);
	const dataExisting = useSelector(formattedDataExistingSelector);
	const view = useSelector(viewSelector);
	const filters = useSelector(filtersSelector);
	const detailsSlice = useSelector(detailsSelector);
	const details = useSelector(formattedDetailsSelector);
	const { models } = useSelector(modelsSelector);
	const dispatch = useDispatch();
	const printRef = useRef();
	const [nodesTabTitle, setNodesTabTitle] = useState('');
	const [chartsToPrint, setChartsToPrint] = useState([true, true, true, true]);

	const pageStyle = `
	@media all {
		.col-md-4 {
			flex: 0 0 33.333333%;
			max-width: 33.333333%;
		}
	
		.col-md-6 {
			flex: 0 0 50%;
			max-width: 50%;
		}
	
		.col-md-8 {
			flex: 0 0 66.666667%;
			max-width: 66.666667%;
		}
	
		.top-block-left-block {
			flex: 0 0 50%;
			max-width: 50%;
			flex-direction: row !important;
			display: none;
			font-size: 0.8em;
		}
	
		.top-block-right-block {
			flex: 0 0 50%;
			max-width: 50%;
			display: flex;
			flex-direction: row !important;
		}

		.custom-checkbox-wrapper {
			display: none !important;
		}

		.print-modal-chart {
			display: flex;
			justify-content: center;
			align-items :center;
      page-break-before: auto;
      page-break-after: auto;
		}

		.details-chart-container {
			width:100% !important;
		}

		.no-print {
			display: none !important;
		}

	}

	@media print {
		.print-modal-chart-wrapper {
			page-break-inside: avoid;
		}
	}
`;

	const [debouncedFetch] = useDebouncedCallback((params: string[]) => {
		dispatch(fetchDetails(params));
	}, 1000);

	useEffect(() => {
		const getModelNameFull = (modelUid: string) => {
			if (models && models.length) {
				const found = models.find((item) => item.modelUid === modelUid);
				if (found) return found.modelNameFull;
			}
			return null;
		};

		const getNodesTabTitle = (models: string[]) => {
			if (models.length === 1) {
				return `${getModelNameFull(models[0])}`;
			} else {
				let res = '';
				for (let i = 0; i < models.length; i++) {
					const element = models[i];
					res += ` ${getModelNameFull(element)}`;
					if (i + 1 < models.length) res += ' + ';
				}
				return res;
			}
		};

		if (view.mode === 'new') {
			if (view.selection.length) {
				setNodesTabTitle(getNodesTabTitle(view.selection));
				debouncedFetch(view.selection);
			}
		} else if (view.mode === 'existing' && view.selection.length) {
			const detailsParams: string[] = [];
			let existings = [...filters.existings];
			detailsParams.push(view.selection[0]);
			existings.sort((a, b) =>
				(a.nodeCount || 0) < (b.nodeCount || 0) ? -1 : 1,
			);
			existings.forEach((item) => {
				if (view.selection.indexOf(item.modelUid) === -1)
					detailsParams.push(item.modelUid);
			});
			setNodesTabTitle(getNodesTabTitle(detailsParams));
			debouncedFetch(detailsParams);
		}
	}, [
		view.selection,
		view.isInChartPage,
		view.mode,
		dispatch,
		filters.existings,
		models,
		debouncedFetch,
	]);

	let modalData = null;

	if (
		view.mode === 'new' &&
		view.selection.length &&
		data.length &&
		data.find((item) => item.modelUid === view.selection[0])
	) {
		modalData = data.find((item) => item.modelUid === view.selection[0]);
	} else if (
		view.mode === 'existing' &&
		view.selection.length &&
		dataExisting &&
		dataExisting.clusters.length &&
		dataExisting.clusters.find((item) => item.modelUid === view.selection[0])
	) {
		modalData = dataExisting.clusters.find(
			(item) => item.modelUid === view.selection[0],
		);
	}

	// Get node count from model uid for nodes tab counters
	const getNodeCount = (modelUid: string) => {
		if (view.mode === 'new') {
			const cluster = data.find((item) => item.modelUid === view.selection[0]);
			if (typeof cluster?.nodeCount?.value === 'number')
				return cluster?.nodeCount?.value || 1;
		} else if (view.mode === 'existing' && dataExisting) {
			const existings = filters.existings;
			const cluster = dataExisting.clusters.find(
				(item) => item.modelUid === view.selection[0],
			);
			let res = 0;

			existings.forEach((item) => {
				if (item.modelUid === modelUid && item.nodeCount) res += item.nodeCount;
			});
			if (
				modelUid === view.selection[0] &&
				typeof cluster?.nodeAdded?.value === 'number'
			)
				res += cluster?.nodeAdded?.value;
			return res;
		}
		return 1;
	};

	const nodeDetailsItems = details.filter(item => !filters.existings.find(exItem => exItem.modelUid === item.modelUid && exItem.replace)).map((item) => (
		<PrintModalNodeItem
			data={item}
			key={item.modelUid}
			nodeCount={getNodeCount(item.modelUid)}
		/>
	));

	const modalTitle = (() => {
		if (view.mode === 'new' && view.selection.length) {
			return `New Qumulo Cluster`;
		} else {
			return 'Qumulo Cluster Expansion';
		}
	})();

	const modalSubTitle = (() => {
		if (view.mode === 'new' && view.selection.length) {
			return `${modalData ? `${modalData.nodeCount.value} nodes` : ''}, ${
				typeof modalData?.usableCapacity.value === 'number' &&
				modalData?.usableCapacity.value &&
				formatCapacity(modalData?.usableCapacity.value)
			} ${modalData?.usableCapacity.unit} Usable`;
		} else if (view.mode === 'existing') {
			return `${
				modalData?.nodeCount.value &&
				modalData?.nodeAdded?.value &&
				typeof modalData?.nodeCount.value === 'number' &&
				typeof modalData?.nodeAdded.value === 'number'
					? modalData?.nodeCount.value - modalData?.nodeAdded?.value
					: ''
			} Existing + ${modalData?.nodeAdded?.value || ''} New Node${
				(modalData?.nodeAdded?.value || 0) > 1 ? 's' : ''
			},  ${
				typeof modalData?.usableCapacity.value === 'number' &&
				modalData?.usableCapacity.value &&
				formatCapacity(modalData?.usableCapacity.value)
			} ${modalData?.usableCapacity.unit} Usable`;
		}
	})();

	const getFormattedDate = () => {
		const date = new Date();
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const dt = date.getDate();
		let dtString = '';
		let monthString = '';

		dtString = dt < 10 ? '0' + dt.toString() : dt.toString();
		monthString = month < 10 ? '0' + month.toString() : month.toString();

		return year.toString() + '-' + monthString + '-' + dtString;
	};

	const handlePrint = useReactToPrint({
		content: () => printRef.current || null,
		bodyClass: 'print-modal',
		onBeforeGetContent: () => {
			const elements = document.querySelectorAll(
				'.print-formatter .highcharts-container > svg',
			);
			elements.forEach((element) => {
				element.setAttribute('width', '900px');
			});
		},
		pageStyle,
		documentTitle: `Qumulo-Cluster-${
			view.mode === 'existing' ? 'Expansion-' : ''
		}${view.selection[0]}-${
			typeof modalData?.usableCapacity.value === 'number' &&
			modalData?.usableCapacity.value &&
			formatCapacity(modalData?.usableCapacity.value)
		}${modalData?.usableCapacity.unit}-${getFormattedDate()}`,
	});

	const clusterTab = (
		<PrintModalClusterTab
			data={modalData}
			existing={filters.existings}
			selection={view.selection[0]}
			mode={view.mode}
			chartsToPrint={chartsToPrint}
			setChartsToPrint={setChartsToPrint}
		/>
	);

	const titleElement = (
		<>
			<PrintModalTitle className="print-modal-title">
				{modalTitle}
			</PrintModalTitle>
			<PrintModalSubTitle className="print-modal-sub-title">
				{modalSubTitle}
			</PrintModalSubTitle>
		</>
	);

	return (
		<Modal
			show={show}
			onHide={handleClose}
			dialogClassName="print-modal"
			size="lg"
		>
			<Modal.Header closeButton>
				<PrintModalHeaderWrapper>
					<PrintModalHeaderTitleWrapper>
						{titleElement}
					</PrintModalHeaderTitleWrapper>
					{/* <Switch checked={true} onChange={() => {}} label={'Summary view'} /> */}
				</PrintModalHeaderWrapper>
			</Modal.Header>
			<Modal.Body>
				<Tabs defaultActiveKey="cluster" id="print-modal-tabs">
					<Tab
						eventKey="cluster"
						title={
							<>
								<i className="fas fa-layer-group" />
								&nbsp; Cluster
							</>
						}
					>
						{clusterTab}
					</Tab>
					<Tab
						eventKey="nodes"
						title={
							<>
								<i className="fas fa-server" />
								&nbsp; Nodes
							</>
						}
					>
						<NodesTabTitle className="nodes-tab-title">
							{nodesTabTitle}
						</NodesTabTitle>
						{!detailsSlice.loading && !detailsSlice.errors
							? nodeDetailsItems
							: null}
					</Tab>
				</Tabs>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-secondary" onClick={props.handleClose}>
					Close
				</button>
				<button className="btn btn-primary" onClick={handlePrint}>
					Export
				</button>
			</Modal.Footer>
			<div style={{ display: 'none' }}>
				<FormatForPrint
					ref={printRef}
					title={titleElement}
					nodesTitle={nodesTabTitle}
					lastRequest={view.lastRequest || undefined}
					nodeDetailsItems={nodeDetailsItems}
					clusterTab={clusterTab}
				/>
			</div>
		</Modal>
	);
};

export default PrintModal;
