import auth0 from '../utils/auth0';

export const call = (route: string, method: string, body?: any) => {
	return auth0.getTokenSilently().then((token: string) => {
		if (token) {
			const headers: any = {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`,
			};

			return fetch(process.env.REACT_APP_API_URL + route, {
				method,
				headers: new Headers(headers),
				body: body !== null ? JSON.stringify(body) : null,
			})
				.then((response) => {
					return response.json();
				})
				.then((error) => error)
				.catch((error) => error);
		}
	});
};
