import React, { useEffect, useState, useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsBrokenAxis from 'highcharts/modules/broken-axis';

// Colors
import colors from '../../../../utils/chartsColors.json';
import { useDispatch, useSelector } from 'react-redux';
import { globalSelector } from '../../../../slices/global';
import ChartHeader from '../ChartHeader';
import {
	chartsSelector,
	fetchChartsResilience,
	setChartType,
	setDefaultSelectedInfo,
} from '../../../../slices/calculator/charts';
import { viewSelector } from '../../../../slices/calculator/view';

import {
	calculateCostPerRawTB,
	yoyGrowthToDate,
	formatChartData,
} from '../utils';
import { ChartData, ChartsResilienceReq } from '../../../../types';

import {
	defaultChartOptions,
	getBaseUsageYAxisPlotlinesOptions,
	getMaxNodesXPlotLinesOptions,
} from '../chartDefaultConfig';
import { SeriesOptionsType } from 'highcharts';
import ResilienceBlockSelector from './ResilienceBlockSelector';
import {
	filtersSelector,
	setAttributes,
} from '../../../../slices/calculator/filters';
import { useURLFilters } from '../../../../hooks';

highchartsBrokenAxis(Highcharts);

type ResilienceChartProps = {
	model: string;
	nodes: number;
	driveProtect: number;
	nodeProtect: number;
	readOnly: boolean;
};

const ResilienceChartWrapper = (props: ResilienceChartProps) => {
	const dispatch = useDispatch();
	const chartsSlice = useSelector(chartsSelector);
	const globalSlice = useSelector(globalSelector);
	const viewSlice = useSelector(viewSelector);
	const dataUnits = globalSlice.dataUnits;
	const chartRef = useRef<{
		chart: Highcharts.Chart;
		container: React.RefObject<HTMLDivElement>;
	}>(null);
	const defaultSelectedInfo = chartsSlice.resilience.chart.defaultSelected;
	const selectedInfo = chartsSlice.resilience.chart.selected;
	const [isSameSeries, setISameSeries] = useState(false);
	const [chartData, setChartData] = useState<ChartData>([]);
	const [secondChartData, setSecondChartData] = useState<ChartData>([]);
	const filters = useSelector(filtersSelector);
	const { attributes } = filters;
	const { encodeFilters, pushHistory } = useURLFilters();

	const [chartOptions, setChartOptions] =
		useState<Highcharts.Options>(defaultChartOptions);

	useEffect(() => {
		if (viewSlice.mode === 'existing') {
			dispatch(setChartType('Capacity Planning'));
		}
	}, [dispatch, viewSlice.mode]);

	useEffect(() => {
		if (
			viewSlice.mode !== 'existing' &&
			defaultSelectedInfo &&
			(attributes.nodeCount.value !== defaultSelectedInfo?.node ||
				attributes.driveOutageTolerance.value !==
					defaultSelectedInfo?.driveProtect ||
				attributes.nodeOutageTolerance.value !==
					defaultSelectedInfo?.nodeProtect)
		) {
			const newAttributes = Object.assign({}, attributes, {
				nodeCount: {
					value: defaultSelectedInfo?.node || props.nodes,
					filter: 'equal',
				},
				driveOutageTolerance: {
					value: defaultSelectedInfo?.driveProtect || props.driveProtect,
					filter: 'equal',
				},
				nodeOutageTolerance: {
					value: defaultSelectedInfo?.nodeProtect || props.nodeProtect,
					filter: 'equal',
				},
			});
			pushHistory(
				encodeFilters(
					Object.assign({}, filters, { attributes: newAttributes }),
				),
				'filters',
			);
			dispatch(setAttributes(newAttributes));
		}
	}, [
		attributes,
		defaultSelectedInfo,
		dispatch,
		viewSlice.mode,
		encodeFilters,
		filters,
		props.driveProtect,
		props.nodeProtect,
		props.nodes,
		pushHistory,
	]);

	useEffect(() => {
		dispatch(
			setDefaultSelectedInfo({
				node: defaultSelectedInfo?.node || props.nodes,
				driveProtect: defaultSelectedInfo?.driveProtect || props.driveProtect,
				nodeProtect: defaultSelectedInfo?.nodeProtect || props.nodeProtect,
			}),
		);
	}, [
		defaultSelectedInfo?.driveProtect,
		defaultSelectedInfo?.node,
		defaultSelectedInfo?.nodeProtect,
		dispatch,
		props.driveProtect,
		props.nodeProtect,
		props.nodes,
	]);

	// check if series are equals
	useEffect(() => {
		if (selectedInfo) {
			const { node, nodeProtect, driveProtect } = selectedInfo;
			setISameSeries(
				node === defaultSelectedInfo?.node &&
					nodeProtect === defaultSelectedInfo?.nodeProtect &&
					driveProtect === defaultSelectedInfo?.driveProtect,
			);
		} else {
			setISameSeries(false);
		}
	}, [
		selectedInfo,
		defaultSelectedInfo?.driveProtect,
		defaultSelectedInfo?.nodeProtect,
		defaultSelectedInfo?.node,
	]);

	// Fetch
	useEffect(() => {
		let infos = [];

		if (defaultSelectedInfo) {
			infos.push({
				nodes: defaultSelectedInfo?.node,
				driveProtect: defaultSelectedInfo?.driveProtect,
				nodeProtect: defaultSelectedInfo?.nodeProtect,
			});
		}

		if (selectedInfo) {
			infos.push({
				nodes: selectedInfo.node,
				driveProtect: selectedInfo.driveProtect,
				nodeProtect: selectedInfo.nodeProtect,
			});
		}
		if (infos.length > 0) {
			const params: ChartsResilienceReq = {
				modelUid: props.model,
				toMaxNodes: chartsSlice.selector.showMaxScaling,
				infos: infos,
			};
			dispatch(fetchChartsResilience(params, globalSlice));
		}
	}, [
		dispatch,
		globalSlice,
		chartsSlice.selector.showMaxScaling,
		props.model,
		defaultSelectedInfo?.driveProtect,
		defaultSelectedInfo?.nodeProtect,
		selectedInfo,
		defaultSelectedInfo?.node,
		defaultSelectedInfo,
	]);

	// Format
	useEffect(() => {
		if (
			!chartsSlice.resilience.chart.loading &&
			!chartsSlice.resilience.chart.errors
		) {
			setChartData(
				formatChartData(
					chartsSlice.resilience.chart.details[0],
					'capacity',
					dataUnits,
					chartsSlice.selector.showMaxScaling,
				),
			);
		}
		if (selectedInfo) {
			setSecondChartData(
				formatChartData(
					chartsSlice.resilience.chart.details[1],
					'capacity',
					dataUnits,
					chartsSlice.selector.showMaxScaling,
				),
			);
		} else {
			setSecondChartData([]);
		}
	}, [
		chartsSlice.resilience.chart.details,
		chartsSlice.resilience.chart.errors,
		chartsSlice.resilience.chart.loading,
		selectedInfo,
		chartsSlice.selector.showMaxScaling,
		dataUnits,
	]);

	useEffect(() => {
		const firstLastData: Highcharts.PointOptionsObject = chartData[
			chartData.length - 1
		] as Highcharts.PointOptionsObject;
		const secondlastData: Highcharts.PointOptionsObject = secondChartData[
			secondChartData.length - 1
		] as Highcharts.PointOptionsObject;

		const lastData = secondlastData
			? (secondlastData.x || 0) > (firstLastData.x || 0)
				? secondlastData
				: firstLastData
			: firstLastData;

		const series: Array<SeriesOptionsType> = [
			{
				name: `${defaultSelectedInfo?.node} Node Cluster w/ \
				${defaultSelectedInfo?.driveProtect} Drive Protection & \
				${defaultSelectedInfo?.nodeProtect} Node Protection`,
				yAxis: 0,
				type: 'line',
				zIndex: 2,
				color: colors.blue,
				data: chartData,
				marker: {
					enabled: true,
					radius: 5,
				},
				dataLabels: {
					enabled: !chartsSlice.summaryView,
					format: '{point.y:.1f}',
					y: -10,
				},
			},
			{
				name: `${selectedInfo?.node} Node Cluster w/ \
				${selectedInfo?.driveProtect} Drive Protection & \
				${selectedInfo?.nodeProtect} Node Protection`,
				yAxis: 0,
				type: 'line',
				zIndex: 3,
				color: colors.yellow,
				data: secondChartData,
				marker: {
					enabled: true,
					radius: 5,
				},
				dataLabels: {
					enabled: !chartsSlice.summaryView,
					format: '{point.y:.1f}',
					y: -10,
				},
			},
		];

		setChartOptions({
			legend: { enabled: props.readOnly },
			xAxis: {
				plotLines: !props.readOnly
					? getMaxNodesXPlotLinesOptions(
							chartsSlice.selector.showMaxScaling,
							lastData,
					  )
					: undefined,
				tickInterval: 1,
			},
			yAxis: {
				title: {
					margin: 35,
					text: `USABLE CAPACITY (${dataUnits === 'base10' ? 'TB' : 'TiB'})`,
				},
				labels: {
					formatter: (context) =>
						`${
							typeof context.value === 'string'
								? Highcharts.numberFormat(parseInt(context.value), 0, '.', ',')
								: Highcharts.numberFormat(context.value, 0, '.', ',')
						} ${dataUnits === 'base10' ? 'TB' : 'TiB'}`,
				},
				plotLines: getBaseUsageYAxisPlotlinesOptions(
					dataUnits,
					chartsSlice.selector.baseUsage,
				),
			},
			tooltip: {
				useHTML: true,
				backgroundColor: 'rgba(255, 255, 255, 1)',
				borderWidth: 3,
				borderRadius: 15,
				borderColor: colors.tooltipColor,
				padding: 0,
				shared: true,
				formatter: function () {
					if (!this.points) return '';
					const intl = new Intl.NumberFormat('en-US');
					const hasMultiplePts = this.points.length >= 2;
					const isPt1FirstChart = this.points[0].series.index === 0;
					const { yoyGrowth, costPerRawTB } = chartsSlice.selector;
					let options = '';
					let optHeader = '';

					const driveProtectPt1 = isPt1FirstChart
						? defaultSelectedInfo?.driveProtect
						: selectedInfo?.driveProtect;
					const nodeProtectPt1 = isPt1FirstChart
						? defaultSelectedInfo?.nodeProtect
						: selectedInfo?.nodeProtect;

					if (chartsSlice.summaryView) {
						optHeader += `<span class='chart-tooltip-body-text'>
							D${driveProtectPt1} ${
							hasMultiplePts ? `vs D${selectedInfo?.driveProtect}<br/>` : ''
						} N${nodeProtectPt1} ${
							hasMultiplePts ? `vs N${selectedInfo?.nodeProtect}` : ''
						}`;
					} else {
						optHeader += `<span class='chart-tooltip-body-text'>
							${driveProtectPt1} Drive Prot. ${
							hasMultiplePts
								? `vs ${selectedInfo?.driveProtect} Drive Prot.`
								: ''
						}
						</span>
						<span class='chart-tooltip-body-text'>
							${nodeProtectPt1} Node Prot. ${
							hasMultiplePts ? `vs ${selectedInfo?.nodeProtect} Node Prot.` : ''
						}
						</span>`;
					}

					if (
						yoyGrowth &&
						chartsSlice.resilience.chart.details.length &&
						!chartsSlice.summaryView
					) {
						const yoyDatePt1 = yoyGrowthToDate(
							this.points[0].y,
							chartsSlice.resilience.chart.details[isPt1FirstChart ? 0 : 1][0]
								.capacity,
							yoyGrowth,
						);
						let yoyDatePt2 = null;
						if (hasMultiplePts) {
							yoyDatePt2 = yoyGrowthToDate(
								this.points[1].y,
								chartsSlice.resilience.chart.details[1][0].capacity,
								yoyGrowth,
							);
						}
						if (yoyDatePt1)
							options += `<span class='chart-tooltip-body-text'>${yoyDatePt1} ${
								yoyDatePt2 ? `vs ${yoyDatePt2}` : ''
							}</span>`;
					}

					if (costPerRawTB && !chartsSlice.summaryView) {
						options += `<span class='chart-tooltip-body-text'>
							${globalSlice.currency}
							${calculateCostPerRawTB(
								costPerRawTB,
								this.points[0].y as number,
								globalSlice.currency,
							)}
							${
								hasMultiplePts
									? `vs 
								${globalSlice.currency}
								${calculateCostPerRawTB(
									costPerRawTB,
									this.points[1].y as number,
									globalSlice.currency,
								)}
							`
									: ''
							}
						</span>`;
					}

					return `
						<div class='chart-tooltip'>
							<span class='chart-tooltip-header'>
								<span class='chart-tooltip-header-title'>
								${this.points[0].x} nodes
								</span>
							</span>
							<div class='chart-tooltip-divider'></div>
							<div class='chart-tooltip-body'>
								<span class='chart-tooltip-header-title'>
								<div class='diamond-small' style='background-color: ${
									this.points[0].color
								};'></div>
								${intl.format(this.points[0].y)}
								${dataUnits === 'base10' ? ' TB' : ' TiB'}
								${
									hasMultiplePts
										? `vs 
										<div class='diamond-small' style='background-color: ${
											this.points[1].color
										};'></div>
										${intl.format(this.points[1].y)}
										${dataUnits === 'base10' ? ' TB' : ' TiB'}
								`
										: ''
								}
							</span>
							<div class='chart-tooltip-divider'></div>

								${optHeader}
								${
									options.length > 0
										? `
										<div class='chart-tooltip-divider'></div>
											${options}
									`
										: ''
								}
							</div>
						</div>
					`;
				},
			},
			series: isSameSeries ? [series[0]] : series,
		});
	}, [
		chartData,
		chartsSlice.resilience.chart.details,
		selectedInfo?.driveProtect,
		selectedInfo?.nodeProtect,
		chartsSlice.selector,
		chartsSlice.summaryView,
		dataUnits,
		globalSlice.currency,
		isSameSeries,
		defaultSelectedInfo?.driveProtect,
		defaultSelectedInfo?.nodeProtect,
		defaultSelectedInfo?.node,
		secondChartData,
		props.readOnly,
		selectedInfo?.node,
	]);

	useEffect(() => {
		const chart = chartRef.current?.chart;
		chartsSlice.resilience.chart.loading
			? chart?.showLoading()
			: chart?.hideLoading();
	}, [chartsSlice.resilience.chart.loading]);

	return (
		<div className="details-chart-container">
			{!props.readOnly && (
				<ChartHeader compact>
					<div className="legends-wrapper">
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
							}}
						>
							<div className="legend-wrapper">
								<div className="diamond-small" />
								<div className="legend-text">
									{defaultSelectedInfo?.node} Node Cluster w/{' '}
									{defaultSelectedInfo?.driveProtect} Drive Protection &{' '}
									{defaultSelectedInfo?.nodeProtect} Node Protection
								</div>
							</div>
							<div className="legend-wrapper">
								<div
									className="diamond-small"
									style={{ backgroundColor: colors.yellow }}
								/>
								<ResilienceBlockSelector
									toMaxNodes={chartsSlice.selector.showMaxScaling}
									modelUid={props.model}
									nodes={defaultSelectedInfo?.node || props.nodes}
								/>
							</div>
						</div>
					</div>
				</ChartHeader>
			)}
			<div style={{ height: '100%' }}>
				<HighchartsReact
					highcharts={Highcharts}
					options={chartOptions}
					containerProps={{ style: { height: '100%' } }}
					ref={chartRef}
				/>
			</div>
		</div>
	);
};
export default ResilienceChartWrapper;
