import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import RowValue from '../../components/RowValue';

// Hooks
import { useModelPicture, useModelsProperties } from '../../hooks';

// Slices
import {
	chartsSelector,
	setCostPerRawTB,
} from '../../slices/calculator/charts';
import { viewSelector } from '../../slices/calculator/view';
import { filtersSelector } from '../../slices/calculator/filters';
import { formattedDataExistingSelector } from '../../slices/calculator/dataExisting';
import { globalSelector, setCurrency } from '../../slices/global';
import { useDispatch, useSelector } from 'react-redux';

// Types
import { FormattedCluster, Cluster } from '../../types';

// Api
import { fetchExistingDetails } from '../../slices/calculator/charts';

// Others
import RowCustomValue from '../../components/RowCustomValue';
import currencies from '../../utils/currencies.json';
import { formatNumber } from '../../utils/conversions';

type InfoPanelProps = {
	data: FormattedCluster | undefined;
};

const CountersWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
`;

const InfoPanel = (props: InfoPanelProps) => {
	const dispatch = useDispatch();
	const headerRef = useRef<HTMLDivElement>(null);
	const { costPerRawTB } = useSelector(chartsSelector).selector;
	const { mode } = useSelector(viewSelector);
	const { currency } = useSelector(globalSelector);
	const { existings, stripeWidth, nodeOutageTolerance } =
		useSelector(filtersSelector);
	const dataExisting = useSelector(formattedDataExistingSelector);
	const { data } = props;
	const [frontPic, backPic] = useModelPicture(props.data?.modelUid || '');
	const properties = useModelsProperties().getAllProperties();
	const [clusterDetails, setClusterDetails] = useState<Cluster[]>([]);

	useEffect(() => {
		if (mode === 'existing') {
			const promiseDetails = fetchExistingDetails(
				existings,
				stripeWidth,
				nodeOutageTolerance,
			);
			promiseDetails.then((res) => {
				setClusterDetails(res.filter(item => !existings.find(exItem => exItem.modelUid === item.modelUid && exItem.replace)));
			});
		}
	}, [mode, existings, stripeWidth, nodeOutageTolerance]);
	const currenciesOptions = (() => {
		const res: JSX.Element[] = [];
		for (const key in currencies) {
			if (Object.prototype.hasOwnProperty.call(currencies, key)) {
				// @ts-ignore
				const element = currencies[key];
				if (element.code && element.symbol) {
					res.push(
						<option value={element.code} key={element.code}>
							{element.symbol}
						</option>,
					);
				}
			}
		}
		return res;
	})();

	if (data) {
		return (
			<div className="details-node-info">
				<div className="details-node-header" ref={headerRef}>
					<h4>{data.modelNameFull}</h4>
					<CountersWrapper>
						<div className="top-block-counter">
							<div className="value">{data.nodeCount.value}</div>
							<div className="sub-title">Nodes</div>
						</div>
						<div className="top-block-counter">
							<div className="value">
								{typeof data.usableCapacity.value === 'number' &&
									formatNumber(data.usableCapacity.value, 0)}
							</div>
							<div className="sub-title">
								Usable Capacity ({data.usableCapacity.unit})
							</div>
						</div>
					</CountersWrapper>
					{mode === 'existing' ? (
						<RowValue
							label={data.modelNameFull}
							data={data?.nodeAdded?.value}
							decimals={0}
							unitLabel={data?.nodeAdded?.unit}
						/>
					) : null}
					{frontPic && (
						<>
							<img className="model-picture" src={frontPic} alt="Front view" />
							<div className="model-description">Front view</div>
						</>
					)}
					{backPic && (
						<>
							<img className="model-picture" src={backPic} alt="Back view" />
							<div className="model-description">Rear view</div>
						</>
					)}
					{mode === 'existing' ? (
						<>
							<RowValue
								label={properties?.usableCapacity?.formattedLabel}
								data={data?.usableCapacityAdded?.value}
								decimals={0}
								unitLabel={data?.usableCapacityAdded?.unit}
							/>
							{
								clusterDetails.length ?
								<>
							<div>Existing Nodes</div>
							{clusterDetails.map((existing) => (
								<div>
									<RowValue
										label={
											dataExisting?.clusters?.find(
												(cluster) => cluster.modelUid === existing.modelUid,
											)?.modelNameFull ?? ''
										}
										data={existing?.nodeCount}
										decimals={0}
										unitLabel={data?.nodeCount?.unit}
									/>
									<RowValue
										label={properties?.usableCapacity?.formattedLabel}
										data={existing?.usableCapacity}
										decimals={0}
										unitLabel={data?.usableCapacity?.unit}
									/>
								</div>
							))}</> : null
							}
						</>
					) : null}
				</div>
				<div
					className="details-data-wrapper"
					style={
						mode === 'existing'
							? {
									height: `calc(100vh - ${headerRef.current?.scrollHeight}px)`,
							  }
							: {
									height: `calc(100vh - ${headerRef.current?.scrollHeight}px - 200px)`,
							  }
					}
				>
					<RowValue
						label={properties.nodeCount.formattedLabel}
						data={data?.nodeCount.value}
						decimals={0}
						unitLabel={data?.nodeCount.unit}
					/>
					{/*<RowValue*/}
					{/*	label={properties.nodeCountScalesTo.formattedLabel}*/}
					{/*	data={data?.nodeCountScalesTo.value}*/}
					{/*	decimals={0}*/}
					{/*	unitLabel={data?.nodeCountScalesTo.unit}*/}
					{/*/>*/}
					{currencies && (
						<RowCustomValue label="Cost Per Raw TB">
							<select
								name="currency"
								value={currency}
								onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
									dispatch(setCurrency(e.target.value))
								}
								className="select-info-panel-currency"
							>
								{currenciesOptions}
							</select>
							<label htmlFor="" style={{ marginBottom: '0px' }}>
								<input
									style={{ maxWidth: '60px' }}
									type="number"
									value={costPerRawTB || ''}
									onChange={(e) =>
										dispatch(setCostPerRawTB(parseInt(e.target.value)))
									}
									min={0}
								/>
							</label>
						</RowCustomValue>
					)}
					<div className="divider"></div>

					<RowValue
						label={properties.cachedRead.formattedLabel}
						data={data?.cachedRead.value}
						decimals={0}
						unitLabel={data?.cachedRead.unit}
					/>
					<RowValue
						label={properties.sustainedWrite.formattedLabel}
						data={data?.sustainedWrite.value}
						decimals={0}
						unitLabel={data?.sustainedWrite.unit}
					/>
					<RowValue
						label={properties.uncachedRead.formattedLabel}
						data={data?.uncachedRead.value}
						decimals={0}
						unitLabel={data?.uncachedRead.unit}
					/>
					<RowValue
						label={properties.singleStreamUncachedRead.formattedLabel}
						data={data?.singleStreamUncachedRead.value}
						decimals={0}
						unitLabel={data?.singleStreamUncachedRead.unit}
					/>
					<RowValue
						label={properties.burstWrite.formattedLabel}
						data={data?.burstWrite.value}
						decimals={0}
						unitLabel={data?.burstWrite.unit}
					/>
					<RowValue
						label={properties.iops.formattedLabel}
						data={data?.iops.value}
						decimals={0}
						unitLabel={data?.iops.unit}
					/>
					<RowValue
						label={properties.singleStreamWrite.formattedLabel}
						data={data?.singleStreamWrite.value}
						decimals={0}
						unitLabel={data?.singleStreamWrite.unit}
					/>
					<RowValue
						label={properties.singleStreamCachedRead.formattedLabel}
						data={data?.singleStreamCachedRead.value}
						decimals={0}
						unitLabel={data?.singleStreamCachedRead.unit}
					/>
					<div className="divider"></div>
					<RowValue
						label={properties.driveOutageTolerance.formattedLabel}
						data={data?.driveOutageTolerance.value}
						decimals={0}
						unitLabel={data?.driveOutageTolerance.unit}
					/>
					<RowValue
						label={properties.nodeOutageTolerance.formattedLabel}
						data={data?.nodeOutageTolerance.value}
						decimals={0}
						unitLabel={data?.nodeOutageTolerance.unit}
					/>

					<div className="divider"></div>
					<RowValue
						label={properties.writeVolume.formattedLabel}
						data={data?.writeVolume.value}
						decimals={0}
						unitLabel={data?.writeVolume.unit}
					/>
					<RowValue
						label={properties.clusterOverwriteCadence.formattedLabel}
						data={data?.clusterOverwriteCadence.value}
						decimals={0}
						unitLabel={data?.clusterOverwriteCadence.unit}
					/>

					<div className="divider"></div>
					<RowValue
						label={properties.frontEndPorts.formattedLabel}
						data={data?.frontEndPorts.value}
						decimals={0}
						unitLabel={data?.frontEndPorts.unit}
					/>
					<RowValue
						label={properties.backEndPorts.formattedLabel}
						data={data?.backEndPorts.value}
						decimals={0}
						unitLabel={data?.backEndPorts.unit}
					/>
					<RowValue
						label={properties.frontEndNetworking.formattedLabel}
						data={data?.frontEndNetworking.value}
						decimals={0}
						unitLabel={data?.frontEndNetworking.unit}
					/>
					<RowValue
						label={properties.backEndNetworking.formattedLabel}
						data={data?.backEndNetworking.value}
						decimals={0}
						unitLabel={data?.backEndNetworking.unit}
					/>
				</div>
			</div>
		);
	} else {
		return <div className="details-node-info"></div>;
	}
};

export default InfoPanel;
