import { call } from './misc';
import { NodeDetail } from '../types';

export const resIsValid = (res: any): res is NodeDetail[] => {
	return !!res;
};

export const fetchDetails = (models: string[]) => {
	return call('/nodes_detail', 'POST', models);
};
