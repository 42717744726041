import React, { FunctionComponent, Fragment } from 'react';
import styled from 'styled-components';

// Types
import { PerformanceClassAttribute } from '../../types';

type CostTableAttributesProps = {
	showForm: boolean;
	label: string;
	name: string;
	value: string | null;
	onChange: (name: string, value: PerformanceClassAttribute) => void;
};

const Attribute = styled.div`
	display: flex;
	align-items: center;
	padding-left: 20px;
	justify-content: space-between;
`;

const Label = styled.span`
	flex-basis: 241px;
	flex-shrink: 0;
`;

const Input = styled.select`
	width: 194px;
	height: 30px;
	margin-right: 8px;
`;

const TableAttribute: FunctionComponent<CostTableAttributesProps> = (props) => {
	const { label, name, value, onChange, showForm } = props;

	const setValue = (newValue: string | null) => {
		onChange(name, {
			value: newValue === 'all' ? null : newValue,
			filter: 'none',
		});
	};

	const form = (
		<Fragment>
			<Input
				onChange={(e) => setValue(e.target.value || null)}
				value={value || undefined}
			>
				<option value="all">All</option>
				<option value="General Purpose">General Purpose</option>
				<option value="Active">Active</option>
				<option value="Active">Active</option>
			</Input>
		</Fragment>
	);

	return (
		<Attribute className="table-attribute">
			<Label className="table-attribute-label">
				{label}
				<i
					style={{ display: 'none' }}
					className="pl-2 hidden icon fas fa-magic"
				/>
			</Label>
			{showForm && form}
		</Attribute>
	);
};

export default TableAttribute;
