import { createSlice } from '@reduxjs/toolkit';
import { fetchDetails as fetchDetailsAPI } from '../../api';
import { resIsValid } from '../../api/details';

// Types
import {
	DetailsSliceState,
	NodeDetail,
	AppDispatch,
	RootState,
	FormattedNodeDetail,
} from '../../types';

// Hooks and utils
import {
	inToCm,
	FtoC,
	lbsToKg,
	TBtoGiB,
	TBtoTiB,
	TBtoGB,
	ftTom,
} from '../../utils/conversions';

export const initialState: DetailsSliceState = {
	loading: false,
	errors: false,
	details: [],
};

const detailsSlice = createSlice({
	name: 'data',
	initialState,
	reducers: {
		getDetails: (state) => {
			state.loading = true;
		},
		getDetailsSuccess: (state, { payload }: { payload: NodeDetail[] }) => {
			state.details = payload;
			state.loading = false;
			state.errors = false;
		},
		getDetailsFailure: (state) => {
			state.loading = false;
			state.errors = true;
		},
	},
});

export const {
	getDetails,
	getDetailsSuccess,
	getDetailsFailure,
} = detailsSlice.actions;
export const detailsSelector = (state: RootState) => state.calculator.details;
export const formattedDetailsSelector = (state: RootState) => {
	const res: FormattedNodeDetail[] = [];
	const dataUnits = state.global.dataUnits;
	const measurements = state.global.measurements;

	state?.calculator?.details?.details?.forEach((element) => {
		res.push({
			modelUid: element.modelUid,
			modelName: element.modelName,
			modelNameFull: element.modelNameFull,
			modelNameShort: element.modelNameShort,
			performanceClass: {
				unit: '',
				value: element.performanceClass,
			},
			rawCapacity: {
				unit: dataUnits === 'base10' ? 'TB' : 'TiB',
				value:
					dataUnits === 'base10'
						? element.rawCapacity
						: TBtoTiB(element.rawCapacity),
			},
			hddCount: {
				unit: '',
				value: element.hddCount,
			},
			hddSize: {
				unit:
					element.hddSize < 1
						? dataUnits === 'base10'
							? 'GB'
							: 'GiB'
						: dataUnits === 'base10'
						? 'TB'
						: 'TiB',
				value:
					element.hddSize < 1
						? dataUnits === 'base10'
							? TBtoGB(element.hddSize)
							: TBtoGiB(element.hddSize)
						: dataUnits === 'base10'
						? element.hddSize
						: TBtoTiB(element.hddSize),
			},
			ssdCount: {
				unit: '',
				value: element.ssdCount,
			},
			ssdSize: {
				unit:
					element.ssdSize < 1
						? dataUnits === 'base10'
							? 'GB'
							: 'GiB'
						: dataUnits === 'base10'
						? 'TB'
						: 'TiB',
				value:
					element.ssdSize < 1
						? dataUnits === 'base10'
							? TBtoGB(element.ssdSize)
							: TBtoGiB(element.ssdSize)
						: dataUnits === 'base10'
						? element.ssdSize
						: TBtoTiB(element.ssdSize),
			},
			cpu: {
				unit: '',
				value: element.cpu,
			},
			memory: {
				unit: '',
				value: element.memory,
			},
			rackUnits: {
				unit: '',
				value: element.rackUnits,
			},
			weight: {
				unit: measurements === 'standard' ? 'lbs' : 'kg',
				value:
					measurements === 'standard'
						? element.weight
						: lbsToKg(element.weight).toFixed(1),
			},
			height: {
				unit: measurements === 'standard' ? 'in' : 'cm',
				value:
					measurements === 'standard'
						? element.height
						: inToCm(element.height).toFixed(1),
			},
			width: {
				unit: measurements === 'standard' ? 'in' : 'cm',
				value:
					measurements === 'standard'
						? element.width
						: inToCm(element.width).toFixed(1),
			},
			depth: {
				unit: measurements === 'standard' ? 'in' : 'cm',
				value:
					measurements === 'standard'
						? element.depth
						: inToCm(element.depth).toFixed(1),
			},
			frontEndPorts: {
				unit: 'ports',
				value: element.frontEndPorts,
			},
			backEndPorts: {
				unit: 'ports',
				value: element.backEndPorts,
			},
			networkingSpeed: {
				unit: 'GbE',
				value: element.networkingSpeed,
			},
			networkConnector: {
				unit: '',
				value: element.networkConnector,
			},
			managementNetwork: {
				unit: '',
				value: element.managementNetwork,
			},
			powerSupply: {
				unit: '',
				value: element.powerSupply,
			},
			powerConnector: {
				unit: '',
				value: element.powerConnector,
			},
			powerRequirements: {
				unit: '',
				value: element.powerRequirements,
			},
			typicalPowerConsumption: {
				unit: '',
				value: `${(element.typicalPowerConsumption / 240).toFixed(
					2,
				)}A @ 240V, ${(element.typicalPowerConsumption / 110).toFixed(
					2,
				)}A @ 110V`,
			},
			typicalThermalRating: {
				unit: 'BTU/hr',
				value: element.typicalThermalRating,
			},
			maximumPowerConsumption: {
				unit: '',
				value: `${(element.maximumPowerConsumption / 240).toFixed(
					2,
				)}A @ 240V, ${(element.maximumPowerConsumption / 110).toFixed(
					2,
				)}A @ 110V`,
			},
			maximumThermalRating: {
				unit: 'BTU/hr',
				value: element.maximumThermalRating,
			},
			operatingTemperatureF: {
				min: {
					unit: measurements === 'standard' ? '°F' : '°C',
					value:
						measurements === 'standard'
							? element.operatingTemperatureF.min
							: FtoC(element.operatingTemperatureF.min).toFixed(1),
				},
				max: {
					unit: measurements === 'standard' ? '°F' : '°C',
					value:
						measurements === 'standard'
							? element.operatingTemperatureF.max
							: FtoC(element.operatingTemperatureF.max).toFixed(1),
				},
			},
			nonOperatingTemperatureF: {
				min: {
					unit: measurements === 'standard' ? '°F' : '°C',
					value:
						measurements === 'standard'
							? element.nonOperatingTemperatureF.min
							: FtoC(element.nonOperatingTemperatureF.min).toFixed(1),
				},
				max: {
					unit: measurements === 'standard' ? '°F' : '°C',
					value:
						measurements === 'standard'
							? element.nonOperatingTemperatureF.max
							: FtoC(element.nonOperatingTemperatureF.max).toFixed(1),
				},
			},

			operatingHumidity: {
				min: {
					unit: '%',
					value: element.operatingHumidity.min,
				},
				max: {
					unit: '%',
					value: element.operatingHumidity.max,
				},
			},
			nonOperatingHumidity: {
				min: {
					unit: '%',
					value: element.nonOperatingHumidity.min,
				},
				max: {
					unit: '%',
					value: element.nonOperatingHumidity.max,
				},
			},
			maximumOperatingAltitude: {
				unit: measurements === 'standard' ? 'ft' : 'm',
				value:
					measurements === 'standard'
						? element.maximumOperatingAltitude
						: ftTom(element.maximumOperatingAltitude).toFixed(1),
			},
		});
	});

	return res;
};
export default detailsSlice.reducer;

export function fetchDetails(models: string[]) {
	return async (dispatch: AppDispatch) => {
		dispatch(getDetails());
		try {
			const data: NodeDetail[] = await fetchDetailsAPI(models);
			if (resIsValid(data)) {
				dispatch(getDetailsSuccess(data));
			} else {
				throw new Error('Details Fetch API Response not valid');
			}
		} catch (error) {
			dispatch(getDetailsFailure());
		}
	};
}
