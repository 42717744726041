import React, { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';

import emptyRating from '../assets/rating-small-empty.png';
import fullRating from '../assets/rating-small-full.png';

type TableCellProps = {
	data: number;
};

const Cell = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const FullRating = styled.img`
	width: 10px;
	height: 10px;
	margin: 2px;
`;

const EmptyRating = styled.img`
	width: 10px;
	height: 10px;
	margin: 2px;
`;

const TableCell: FunctionComponent<TableCellProps> = (props) => {
	const { data } = props;

	const fullItems = ((data: number) => {
		const res = [];
		for (let i = 0; i < data; i++) {
			res.push(
				<FullRating
					src={fullRating}
					alt="Rating full"
					key={`rating-full-${i}`}
				/>,
			);
		}
		return res;
	})(data);

	const emptyItems = ((data: number) => {
		const res = [];
		for (let i = 0; i < data; i++) {
			res.push(
				<EmptyRating
					src={emptyRating}
					alt="Rating empty"
					key={`rating-empty-${i}`}
				/>,
			);
		}
		return res;
	})(5 - data);

	const memoCell = useMemo(() => {
		return (
			<Cell className="table-cell">
				{fullItems}
				{emptyItems}
			</Cell>
		);
	}, [emptyItems, fullItems]);

	return memoCell;
};

export default TableCell;
