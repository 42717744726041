import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useURLFilters } from '../hooks';

// Slices
import { viewSelector, toggleCategory } from '../slices/calculator/view';

// Types
type AttributesBlockProps = {
	title?: string;
	category: string;
	titleValue?: string;
	unfoldable?: boolean;
	titleTooltip?: string;
};

type BlockTitleWrapperProps = {
	unfoldable?: boolean;
};

// type TransitionStyles = {
//     [propName: string]:
//     | object,
//     entering: object,
//     entered: object,
//     exiting: object,
//     exited: object,
// }

const Block = styled.div``;

const BlockTitleWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 27px 20px 17px 20px;
	${(props: BlockTitleWrapperProps) => {
		if (!props.unfoldable)
			return css`
				cursor: pointer;
			`;
	}}
`;

const Title = styled.span`
	border: none;
	text-transform: uppercase;
	font-weight: bold;
	color: inherit;
`;

const ChildrenWrapper = styled.div`
	background-color: white;
`;

const AttributesBlock: FunctionComponent<AttributesBlockProps> = ({
	title,
	category,
	children,
	titleValue,
	unfoldable,
	titleTooltip,
}) => {
	const dispatch = useDispatch();
	const { showCategory } = useSelector(viewSelector);
	const { encodeCategory, pushHistory } = useURLFilters();

	const handleToggle = () => {
		pushHistory(encodeCategory(showCategory, category), 'cat');
		dispatch(toggleCategory(category));
	};

	// const [showElement, setShowElement] = useState(showCategory[category]);

	/*
	 ** This animation works as expected but there is a deprected warning showing up
	 ** from the official react-transtion-group package. It is a known issue and maintainers are working on it
	 ** It does not affect the animation working
	 ** https://github.com/reactjs/react-transition-group/issues/429
	 */

	// const duration = 0;

	// const defaultStyle = {
	//     transition: `opacity ${duration}ms ease-in-out`,
	//     opacity: 0,
	//   }

	// const transitionStyles: TransitionStyles = {
	//     entering: { opacity: 1 },
	//     entered:  { opacity: 1 },
	//     exiting:  { opacity: 0 },
	//     exited:  { opacity: 0 },
	// };

	return (
		<Block
			className={`attributes-block ${unfoldable ? 'unfoldable' : ''} ${
				showCategory[category] ? 'active' : ''
			}`}
		>
			<BlockTitleWrapper
				className="attributes-block-title"
				unfoldable={unfoldable}
				onClick={() => {
					handleToggle();
				}}
			>
				{title && (
					<OverlayTrigger
						placement={'right'}
						overlay={
							<Popover id="category-tooltip">
								<Popover.Content>{titleTooltip}</Popover.Content>
							</Popover>
						}
					>
						<Title>
							&nbsp;
							{title}
						</Title>
					</OverlayTrigger>
				)}
				{title && !unfoldable && (
					<i
						className={`category-caret fas fa-angle-${
							showCategory[category] ? 'down active' : 'up'
						}`}
					></i>
				)}
				{titleValue && (
					<div className="attributes-block-title-value">{titleValue}</div>
				)}
			</BlockTitleWrapper>

			{showCategory[category] && (
				<ChildrenWrapper className="attributes-block-content">
					{children}
				</ChildrenWrapper>
			)}

			{/* Keeping the following in case we want to re-enable animations */}
			{/* <Transition
                in={showCategory[category]}
                timeout={duration}
                onEnter={() => {setShowElement(true); dispatch(startAnimation());}}
                onEntered={() => dispatch(endAnimation())}
                onExit={() => dispatch(startAnimation())}
                onExited={() => {setShowElement(false); dispatch(endAnimation());}}
            >
                {state => (
                <div style={{
                    ...defaultStyle,
                    ...transitionStyles[state]
                }}>
                    {
                        showElement &&
                        <ChildrenWrapper className="attributes-block-content">
                            {children}
                        </ChildrenWrapper>
                    }
                </div>)}
            </Transition> */}
		</Block>
	);
};

export default AttributesBlock;
