import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { usePrevious, useURLFilters } from '../../hooks';

// Components
import { Checkbox } from '../../components/forms';

// Slices
import { modelsSelector } from '../../slices/calculator/models';
import { setMode } from '../../slices/calculator/view';
import {
	addExisting,
	editExisting,
	deleteExisting,
	filtersSelector,
	setStripeWidth,
	clearStripeWidth,
	setNodeOutageTolerance,
	clearNodeOutageTolerance,
	setShowRecommendedOnly,
	setAllowTranscoding,
} from '../../slices/calculator/filters';
import {
	fetchExisting,
	dataExistingSelector,
	clearError,
} from '../../slices/calculator/dataExisting';

// Types
import { DataExistingSliceState, ExistingItem, Model } from '../../types';

type DescribeExistingProps = {
	show?: boolean;
	handleClose: () => void;
};

type ModelFormProps = {
	newForm?: boolean;
	models: Model[];
	selected?: string;
	nodeCount?: number;
	replace?: boolean;
	id?: string;
	newFormModel?: string;
	setNewFormModel?: (value: string | undefined) => void;
};

const Title = styled.h5`
	margin: 0px;
	font-size: 16px;
`;

const ReplaceLabelWrapper = styled.div`
	display: flex;
	justify-content: flex-end;

	span {
		margin-right: 23px;
	}
`;

const InputsGroupWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin: 10px 0px;
	width: 100%;

	.custom-checkbox-wrapper {
		margin: 0 30px;
	}

	select {
		width: 330px;
		padding: 5px;
		flex-grow: 1;
	}

	input {
		width: 70px;
		margin: 0px 10px;
		padding: 3px;
	}

	button.btn {
		margin: 0px;
		padding: 0px;
	}
`;

const SelectFormWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 25px;
	align-items: center;

	label {
		margin: 0px;
	}

	select {
		width: 70px;
		height: 33px;
		margin: 0px 20px;
		padding-left: 10px;
	}

	button.btn {
		margin: 0px;
		padding: 0px;
	}
`;

const Alert = styled.div`
	margin-top: 20px;

	pre {
		display: inline;
		margin: 0;
		color: inherit;
		font-weight: bold;
	}
`;

const CheckboxWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
`;

const FooterWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;

	label {
		margin-bottom: 0px;
	}
`;

const ModelForm: FunctionComponent<ModelFormProps> = (props) => {
	const dispatch = useDispatch();
	const {
		models,
		selected,
		nodeCount,
		replace,
		newForm,
		id,
		newFormModel,
		setNewFormModel,
	} = props;
	const [newNodeCount, setNewNodeCount] = useState<string | undefined>('');
	const [debouncedNodeCount] = useDebounce(newNodeCount, 700);
	const [newReplace, setNewReplace] = useState<boolean | undefined>(false);

	useEffect(() => {
		if (newFormModel && debouncedNodeCount) {
			dispatch(
				addExisting({
					modelUid: newFormModel,
					nodeCount: parseInt(debouncedNodeCount),
					replace: newReplace,
				}),
			);
			setNewNodeCount('');
			setNewFormModel(undefined);
			setNewReplace(false);
		}
	}, [debouncedNodeCount, dispatch, newFormModel, setNewFormModel, newReplace]);

	// New form to add existing
	if (newForm) {
		return (
			<InputsGroupWrapper>
				<select
					value={newFormModel}
					onChange={(e) => {
						setNewFormModel(e.target.value);
					}}
				>
					<option value={undefined}>Choose Model</option>
					{models.map((item) => (
						<option key={item.modelUid} value={item.modelUid}>
							{item.modelName}
						</option>
					))}
				</select>
				<input
					type="number"
					value={newNodeCount}
					onChange={(e) => setNewNodeCount(e.target.value)}
					min={0}
				/>
				<OverlayTrigger
					placement={'top'}
					overlay={
						<Popover id="recommended-tooltip">
							<Popover.Content>
								Remove this node from the cluster as part of a refresh
							</Popover.Content>
						</Popover>
					}
				>
					<div>
						<Checkbox
							onChange={(e) => {
								setNewReplace(e.target.checked)
							}}
							checked={newReplace}
						/>
					</div>
				</OverlayTrigger>
				<button className="btn btn-link" disabled>
					<i className="fa fa-times-circle"></i>
				</button>
			</InputsGroupWrapper>
		);
	}

	// Form to edit/delete existing
	return (
		<InputsGroupWrapper>
			<select
				value={selected}
				onChange={(e) => {
					dispatch(clearError());
					dispatch(
						editExisting({
							modelUid: e.target.value,
							nodeCount: nodeCount || 0,
							id: id || '',
							replace
						}),
					);
				}}
			>
				{models.map((item) => (
					<option key={item.modelUid} value={item.modelUid}>
						{item.modelName}
					</option>
				))}
			</select>
			<input
				type="number"
				value={nodeCount}
				onChange={(e) => {
					dispatch(clearError());
					if (e.target.value)
						dispatch(
							editExisting({
								modelUid: selected || '',
								nodeCount: parseInt(e.target.value),
								id: id || '',
								replace
							}),
						);
					else
						dispatch(
							editExisting({
								modelUid: selected || '',
								nodeCount: undefined,
								id: id || '',
								replace
							}),
						);
				}}
				min={0}
			/>
			<OverlayTrigger
				placement={'top'}
				overlay={
					<Popover id="recommended-tooltip">
						<Popover.Content>
                            Remove this node from the cluster as part of a refresh
						</Popover.Content>
					</Popover>
				}
			>
				<div>
					<Checkbox
						onChange={(e) => {
								dispatch(
									editExisting({
										modelUid: selected || '',
										nodeCount: nodeCount,
										id: id || '',
										replace: e.target.checked
									}),
								);
						}}
						checked={replace}
					/>
				</div>
			</OverlayTrigger>

			<button
				className="btn btn-link"
				onClick={() => dispatch(deleteExisting({ id: id || '' }))}
			>
				<i className="fa fa-times-circle"></i>
			</button>
		</InputsGroupWrapper>
	);
};

const DescribeExisting: FunctionComponent<DescribeExistingProps> = (props) => {
	const { show, handleClose } = props;
	const models = useSelector(modelsSelector);
	const modelsList = models.models;
	const filters = useSelector(filtersSelector);
	const dataExisting = useSelector(dataExistingSelector);
	const previousDataExisting: DataExistingSliceState | undefined =
		usePrevious(dataExisting);
	const existings = filters.existings;
	const stripeWidth = filters.stripeWidth;
	const nodeOutageTolerance = filters.nodeOutageTolerance;
	const [notOptions, setNotOptions] = useState<JSX.Element[]>([]);
	const [newFormModel, setNewFormModel] = useState('');
	const dispatch = useDispatch();
	const { encodeFilters, encodeMode, pushHistory } = useURLFilters();

	const countExisting = (value: ExistingItem[]) => {
		let count = 0;
		for (let i = 0; i < value.length; i++) {
			const element = value[i];
			if (element.nodeCount) count += element.nodeCount;
		}
		return count;
	};
	const [existingCount, setExistingCount] = useState(countExisting(existings));
	useEffect(() => {
		setExistingCount(countExisting(existings));
	}, [existings]);

	let forms: JSX.Element[] | null = null;

	// Close modal in case of successful request
	useEffect(() => {
		if (
			previousDataExisting?.loading &&
			!dataExisting.loading &&
			!dataExisting.errors
		)
			handleClose();
	}, [previousDataExisting, dataExisting, handleClose]);

	// Update Node Outage Tolerance options on StripeWidth change
	useEffect(() => {
		if (stripeWidth?.length) {
			const n = parseInt(stripeWidth.split(',')[0]);
			const k = parseInt(stripeWidth.split(',')[1]);

			if (n && k) {
				const diff = n - k;
				let res: JSX.Element[] = [];

				switch (diff) {
					case 2:
						res = [
							<option key={1} value={1}>
								1
							</option>,
							<option key={2} value={2}>
								2
							</option>,
						];
						break;
					case 3:
						res = [
							<option key={1} value={1}>
								1
							</option>,
							<option key={3} value={3}>
								3
							</option>,
						];
						break;
					case 4:
						res = [
							<option key={2} value={2}>
								2
							</option>,
							<option key={4} value={4}>
								4
							</option>,
						];
						break;
					default:
						break;
				}

				setNotOptions(res);
			}
		}
	}, [stripeWidth]);

	if (!models.errors && !models.loading && modelsList.length) {
		forms = existings.map((item) => (
			<ModelForm
				key={item.id}
				id={item.id}
				models={modelsList}
				selected={item.modelUid}
				nodeCount={item.nodeCount}
				replace={item.replace}
			/>
		));
		forms.push(
			<ModelForm
				key={Date.now()}
				newForm={true}
				models={modelsList}
				newFormModel={newFormModel}
				setNewFormModel={setNewFormModel}
			/>,
		);
	}

	const handleSubmit = () => {
		const newAttributes = Object.assign({}, filters.attributes, {
			nodeOutageTolerance: {
				value: null,
				filter: 'none',
			},
		});
		// Clearing invalid existings
		const filtered = existings.filter((item) => item.nodeCount);

		// Enable HE or LA if any specified model has it
		let HE = false;
		for (let i = 0; i < filtered.length; i++) {
			const element = filtered[i];
			const found = models.models.find(
				(item) => item.modelUid === element.modelUid,
			);
			if (found) {
				if (found.highEndurance) HE = true;
			}
		}

		// Update existing in URL while clearing the unneeded filters for existing
		pushHistory(
			encodeFilters(
				Object.assign(
					{},
					filters,
					{ attributes: newAttributes },
					{ highEndurance: HE, limitedAvailability: false },
				),
			),
			'filters',
		);
		dispatch(setMode('existing'));
        pushHistory(
            encodeMode('existing'),
            'mode'
        );
        dispatch(
			fetchExisting(filters.showRecommendedOnly, filters.allowTranscoding),
		);
	};

	return (
		<Modal
			show={show}
			onHide={handleClose}
			dialogClassName="describe-existing-modal"
			enforceFocus
			size={'lg'}
		>
			<Modal.Header closeButton>
				<Title>Describe Existing Cluster</Title>
			</Modal.Header>
			<Modal.Body>
				<ReplaceLabelWrapper>
					<span>
					Replace
					</span>
				</ReplaceLabelWrapper>
				{forms}
				<SelectFormWrapper>
					<label>Stripe Width</label>
					<select
						value={stripeWidth}
						onChange={(e) => {
							const n = parseInt(e.target.value.split(',')[0]);
							const k = parseInt(e.target.value.split(',')[1]);
							if (n && k && n - k === 4) dispatch(setNodeOutageTolerance('2'));
							else dispatch(setNodeOutageTolerance('1'));

							dispatch(clearError());
							dispatch(setStripeWidth(e.target.value));
						}}
					>
						<option value=""></option>
						<option value="6,4">6,4</option>
						<option value="8,5">8,5</option>
						<option value="8,6">8,6</option>
						<option value="10,7">10,7</option>
						<option value="10,8">10,8</option>
						<option value="12,9">12,9</option>
						<option value="12,10">12,10</option>
						<option value="14,11">14,11</option>
						<option value="14,12">14,12</option>
						<option value="16,13">16,13</option>
						<option value="16,14">16,14</option>
						<option value="18,15">18,15</option>
						<option value="18,16">18,16</option>
						<option value="20,16">20,16</option>
						<option value="20,17">20,17</option>
						<option value="20,18">20,18</option>
					</select>
					<button
						className="btn btn-link"
						onClick={() => dispatch(clearStripeWidth())}
					>
						<i className="fa fa-times-circle"></i>
					</button>
				</SelectFormWrapper>

				<SelectFormWrapper>
					<label>Node Outage Tolerance</label>
					<select
						value={nodeOutageTolerance}
						onChange={(e) => {
							dispatch(setNodeOutageTolerance(e.target.value));
							dispatch(clearError());
						}}
						disabled={!stripeWidth.length}
					>
						{notOptions}
					</select>
					<button
						className="btn btn-link"
						onClick={() => dispatch(clearNodeOutageTolerance())}
						disabled
					>
						<i className="fa fa-times-circle"></i>
					</button>
				</SelectFormWrapper>

				{dataExisting.errors && (
					<Alert className="alert alert-warning">
						This configuration is not valid.
						<br />
						<br />
						Find Stripe Width and Node Outage Tolerance values in MissionQ, or
						by running <pre>qq protection_status_get</pre> on the existing
						cluster.
					</Alert>
				)}
				{existings.length && existingCount < 4 ? (
					<Alert className="alert alert-info">
						An existing cluster has at least 4 nodes. <br />
						Please check your entries and try again.
					</Alert>
				) : null}
			</Modal.Body>
			<Modal.Footer>
				<FooterWrapper>
					<CheckboxWrapper>
						<div style={{ width: '100%' }}>
							<Checkbox
								onChange={(e) => {
									dispatch(setAllowTranscoding(e.target.checked));
								}}
								checked={filters.allowTranscoding}
								label={'Allow protection changes'}
							/>
						</div>

						<OverlayTrigger
							placement={'top'}
							overlay={
								<Popover id="recommended-tooltip">
									<Popover.Content>
										Hides models that could reduce overall performance if added
										to existing cluster. Uncheck to show all models compatible
										with this cluster.
									</Popover.Content>
								</Popover>
							}
						>
							<div style={{ width: '100%' }}>
								<Checkbox
									onChange={(e) => {
										dispatch(setShowRecommendedOnly(e.target.checked));
									}}
									checked={filters.showRecommendedOnly}
									label={'Recommended Results Only'}
								/>
							</div>
						</OverlayTrigger>
					</CheckboxWrapper>
					<button
						className="btn btn-primary"
						disabled={
							!(
								existings.length &&
								filters.stripeWidth.length &&
								nodeOutageTolerance.length &&
								existingCount >= 4
							)
						}
						onClick={handleSubmit}
					>
						Accept
					</button>
				</FooterWrapper>
			</Modal.Footer>
		</Modal>
	);
};

export default DescribeExisting;
