// @ts-nocheck

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import 'whatwg-fetch';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import './styles/main.scss';

import { Auth0Provider } from './react-auth0-spa';
import history from './utils/history';

import rootReducer from './slices';

const onRedirectCallback = (appState) => {
	history.push(
		appState && appState.targetUrl
			? appState.targetUrl
			: window.location.pathname,
	);
};

const store = configureStore({ reducer: rootReducer });

// Exporting dispatch type
export type AppDispatchExport = typeof store.dispatch;

ReactDOM.render(
	<React.StrictMode>
		<Auth0Provider
			domain={process.env.REACT_APP_AUTH0_DOMAIN}
			client_id={process.env.REACT_APP_AUTH0_CLIENTID}
			redirect_uri={window.location.origin}
			onRedirectCallback={onRedirectCallback}
			audience={process.env.REACT_APP_AUTH0_AUDIENCE}
			cacheLocation="localstorage"
		>
			<Provider store={store}>
				<App />
			</Provider>
		</Auth0Provider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
