import React from 'react';
import styled from 'styled-components';

// img
import errorImg from '../../assets/qumulo_logo.svg';

const ErrorWrapper = styled.div`
	display: flex;
	justify-content: center;
	vertical-align: middle;
	height: 100vh;
	width: 100vw;
	background-color: #f4f3f2;
`;

const ElementsWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const ImageError = styled.img`
	width: 20vw;
	padding-bottom: 10%;
`;

const CenterWrapper = styled.div`
	display: flex;
	justify-content: center;
	vertical-align: middle;
`;

const TitleError = styled.span`
	font-size: 1.15vw;
	font-weight: 500;
	line-height: 6vh;
`;

const TextError = styled.span`
	font-size: 1vw;
`;

const ErrorAccess = () => {
	return (
		<ErrorWrapper>
			<ElementsWrapper>
				<CenterWrapper>
					<ImageError src={errorImg} />
				</CenterWrapper>
				<ElementsWrapper>
					<CenterWrapper>
						<TitleError>Please check your inbox and verify your email. </TitleError>
					</CenterWrapper>
					<CenterWrapper>
						<TextError>
							Once verified please sign out and log back in. Please see your Administrator or visit{' '}
							<a href="https://qumulo.com/partners/channel/">
								qumulo.com/partners/channel/
							</a>{' '}
							for assistance.
						</TextError>
					</CenterWrapper>
				</ElementsWrapper>
			</ElementsWrapper>
		</ErrorWrapper>
	);
};

export default ErrorAccess;
